import React, {useContext} from 'react';
import RealtyNameContext from "../others/RealtyNameContext";

function Footer() {
    const {realtyName} = useContext(RealtyNameContext);
    return (<footer className="bg-white sticky-footer">
            <div className="container my-auto">
                <div
                    className="text-center my-auto copyright"><span>Copyright&nbsp;© {new Date().getFullYear()} {realtyName} | Developer: Christian Knight Montero | For Inquiries Email: <u
                    className='text-primary'><strong>support@cleverkobweb.com</strong></u></span>
                </div>
            </div>
        </footer>);
}

export default Footer;