import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import '../../assets/css/printing.css'
import ReactToPrint from "react-to-print";
import PrintContent from "./printPages/PrintContent";
import {DownloadTableExcel} from "react-export-table-to-excel";


function DataExtraction() {
    const token = localStorage.getItem("token");
    const printContentRef = useRef()
    const tableRef = useRef(null);
    const [data, setData] = useState([])
    const [filter, setFilter] = useState('')
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermBuyer, setSearchTermBuyer] = useState('');
    // Define initial start and end dates
    const currentDate = new Date();
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    startOfMonth.setDate(startOfMonth.getDate() + 1);
    endOfMonth.setDate(endOfMonth.getDate() + 1);
    // Define state variables for start and end dates
    const [startDate, setStartDate] = useState(startOfMonth);
    const [endDate, setEndDate] = useState(endOfMonth);
    const [loading, setLoading] = useState(false);

    // Define a function to format the dates as strings
    const formatDateToString = (date) => {
        return date.toISOString().split('T')[0];
    };

    const [searchForBuyersName, setSearchForBuyersName] = useState(false);
    // Function to handle checkbox change event
    const handleCheckboxChange = (e) => {
        setSearchForBuyersName(e.target.checked);
        if (!e.target.checked) {
            setSearchTermBuyer(''); // Clear searchTermBuyer when unchecked
        }
    };

    useEffect(() => {
        // Format the start and end dates
        const formattedStartDate = formatDateToString(startDate);
        const formattedEndDate = formatDateToString(endDate);
        axios.get(`${process.env.REACT_APP_API_URL}/data-extraction?filter=${filter}&search=${searchTerm}&search_buyer=${searchTermBuyer}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)

            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [token, filter, startDate, endDate, searchTerm, searchTermBuyer])


    return (<>
        <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Data Extraction Tool</h3>
        <div className="mb-3 mx-4">
            <button className="btn btn-primary btn-sm mx-1" onClick={() => {
                setFilter('transaction');
                setData([]); // Set data to an empty array
            }} disabled={filter === 'transaction'}>Transaction
            </button>
            <button className="btn btn-primary btn-sm mx-1" onClick={() => {
                setFilter('payroll');
                setData([]); // Set data to an empty array
            }} disabled={filter === 'payroll'}>Commission
            </button>
            <hr/>
            <br/>
            <div className='row mb-2'>
                <div className='col'>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                               checked={searchForBuyersName}
                               onChange={handleCheckboxChange}
                               disabled={filter === 'staff' || filter === 'salesmanager' || filter === 'salesperson' || filter === 'property' || filter === 'payroll'}
                               hidden={filter === 'staff' || filter === 'salesmanager' || filter === 'salesperson' || filter === 'property' || filter === 'payroll'}/>
                        <label className="form-check-label" htmlFor="flexCheckDefault"
                               hidden={filter === 'staff' || filter === 'salesmanager' || filter === 'salesperson' || filter === 'property' || filter === 'payroll'}>
                            Search for Buyers Name
                        </label>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder={searchForBuyersName ? 'Search Buyers Name' : 'Search Realty Name'}
                        aria-label="Search"
                        aria-describedby="basic-addon2"
                        value={searchForBuyersName ? searchTermBuyer : searchTerm}
                        onChange={(e) => {
                            if (searchForBuyersName) {
                                setSearchTermBuyer(e.target.value);
                            } else {
                                setSearchTerm(e.target.value);
                            }
                        }}
                        // Disable the input when filter is "staff," "salesmanager," or "salesperson"
                        disabled={filter === 'staff' || filter === 'salesmanager' || filter === 'salesperson' || filter === 'property'}
                    />
                </div>
                <div className='col'>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                               checked={searchForBuyersName}
                               onChange={handleCheckboxChange}
                               hidden/>
                        <label className="form-check-label" htmlFor="flexCheckDefault"
                               hidden>
                            Search for Buyers Name
                        </label>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <input
                                type="date"
                                className="form-control"
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                                value={formatDateToString(startDate)} // Format the Date object as a string
                                onChange={e => setStartDate(new Date(e.target.value))} // Parse the input value as a Date object
                                // Disable the input when filter is "staff," "salesmanager," or "salesperson"
                                disabled={filter === "staff" || filter === "salesmanager" || filter === "salesperson" || filter === "property"}
                            />
                        </div>
                        -
                        <div className='col'>
                            <input
                                type="date"
                                className="form-control"
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                                value={formatDateToString(endDate)} // Format the Date object as a string
                                onChange={e => setEndDate(new Date(e.target.value))} // Parse the input value as a Date object
                                // Disable the input when filter is "staff," "salesmanager," or "salesperson"
                                disabled={filter === "staff" || filter === "salesmanager" || filter === "salesperson" || filter === "property"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ReactToPrint
                trigger={() => <button className="btn btn-warning btn-sm mx-1">Print</button>}
                content={() => printContentRef.current}
            />
            <DownloadTableExcel
                filename="exported_data"
                sheet="Exported_Data"
                currentTableRef={tableRef.current}
            >
                <button className="btn btn-warning btn-sm mx-1"> Export to Excel</button>
            </DownloadTableExcel>
            {loading ? (<div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>) : (<PrintContent ref={printContentRef}>
                {filter === 'staff' && (<table className="table my-0" id="dataTable" ref={tableRef}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Contact Number</th>
                        <th>Email</th>
                        <th>Role</th>
                    </tr>
                    </thead>
                    <tbody className='table-group-divider'>
                    {data.map((data) => (<tr key={data.id}>
                        <td>{data.user.last_name}, {data.user.first_name} {data.user.middle_name} {data.user.suffix}</td>
                        <td>{data.phone}</td>
                        <td>{data.user.email}</td>
                        <td>{data.role_name}</td>
                    </tr>))}
                    </tbody>
                </table>)}
                {filter === 'salesmanager' && (<table className="table my-0" id="dataTable" ref={tableRef}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Contact Number</th>
                        <th>Email</th>
                        <th>Commission Rate</th>
                        <th>Withholding Tax</th>
                        <th>Business Tax</th>
                        <th>Salesperson/s</th>
                        <th>Broker</th>
                    </tr>
                    </thead>
                    <tbody className='table-group-divider'>
                    {data.map((data) => (<tr key={data.id}>
                        <td>{data.user.last_name}, {data.user.first_name} {data.user.middle_name} {data.user.suffix}</td>
                        <td>{data.phone}</td>
                        <td>{data.user.email}</td>
                        <td>{data.com_rate}</td>
                        <td>{data.withholding_tax}</td>
                        <td>{data.business_tax}</td>
                        <td>{data.salesperson_count}</td>
                        <td>{data.broker.user.last_name}, {data.broker.user.first_name} {data.broker.user.middle_name} {data.broker.user.suffix}</td>
                    </tr>))}
                    </tbody>
                </table>)}
                {filter === 'salesperson' && (<table className="table my-0" id="dataTable" ref={tableRef}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Contact Number</th>
                        <th>Email</th>
                        <th>Commission Rate</th>
                        <th>Withholding Tax</th>
                        <th>Business Tax</th>
                        <th>Salesmanager</th>
                    </tr>
                    </thead>
                    <tbody className='table-group-divider'>
                    {data.map((data) => (<tr key={data.id}>
                        <td>{data.user.last_name}, {data.user.first_name} {data.user.middle_name} {data.user.suffix}</td>
                        <td>{data.phone}</td>
                        <td>{data.user.email}</td>
                        <td>{data.com_rate}</td>
                        <td>{data.withholding_tax}</td>
                        <td>{data.business_tax}</td>
                        <td>{data.salesmanager.user.last_name}, {data.salesmanager.user.first_name} {data.salesmanager.user.middle_name} {data.salesmanager.user.suffix}</td>
                    </tr>))}
                    </tbody>
                </table>)}
                {filter === 'property' && (<table className="table my-0" id="dataTable" ref={tableRef}>
                    <thead>
                    <tr>
                        <th>Property Name</th>
                        <th>Address</th>
                        <th>Price</th>
                        <th>Owner/Developer</th>
                        <th>Type</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody className='table-group-divider'>
                    {data.map((data) => (<tr key={data.id}>
                        <td>{data.name}</td>
                        <td>{data.address}</td>
                        <td>{data.price}</td>
                        <td>{data.propertyowner.name}</td>
                        <td>{data.propertytype.name}</td>
                        <td>{data.status_name}</td>
                    </tr>))}
                    </tbody>
                </table>)}
                {filter === 'transaction' && (<table className="table my-0" id="dataTable" ref={tableRef}>
                    <thead>
                    <tr>
                        <th>Project Name</th>
                        <th>Property Name</th>
                        <th>Block and Lot</th>
                        <th>TCP</th>
                        <th>Buyer's Name</th>
                        <th>Realty's Name</th>
                        <th>Status</th>
                        <th>Date</th>
                    </tr>
                    </thead>
                    <tbody className='table-group-divider'>
                    {data.map((data) => (<tr key={data.id}>
                        <td>{data.propertyowner.name}</td>
                        <td>{data.property.name}</td>
                        <td>{data.propertyblocklot.name}</td>
                        <td>{data.tcp}</td>
                        <td>{data.buyer.name}</td>
                        <td>{data.user.first_name}</td>
                        <td>{data.status_name}</td>
                        <td>{data.transaction_date}</td>
                    </tr>))}
                    </tbody>
                </table>)}
                {filter === 'payroll' && (<table className="table my-0" id="dataTable" ref={tableRef}>
                    <thead>
                    <tr>
                        <th>Realty's Name</th>
                        <th>Project Name</th>
                        <th>Property Name</th>
                        <th>Block and Lot</th>
                        <th>Total Commission</th>
                        <th>Disbursed Commission</th>
                        <th>Remaining Commission</th>
                        <th>Last date of Disbursement</th>
                        <th>Status</th>
                        <th>Date</th>
                    </tr>
                    </thead>
                    <tbody className='table-group-divider'>
                    {data.map((data) => (<tr key={data.id}>
                        <td>{data.transaction.user.first_name}</td>
                        <td>{data.transaction.property.name}</td>
                        <td>{data.transaction.propertyowner.name}</td>
                        <td>{data.transaction.property.name}</td>
                        <td>{data.transaction.propertyblocklot.name}</td>
                        <td>{data.total_commission}</td>
                        <td>{data.total_amount}</td>
                        <td>{data.total_disbursed}</td>
                        <td>{data.most_recent_history_created_date}</td>
                        <td>{data.status_name}</td>
                        <td>{data.created_date}</td>
                    </tr>))}
                    </tbody>
                </table>)}
            </PrintContent>)}
        </div>


    </>)
}

export default DataExtraction