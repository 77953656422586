import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import Loading from "../../others/Loading";
import ReactPaginate from "react-paginate";
import {Button, Modal} from "react-bootstrap";
import {NumericFormat} from "react-number-format";

function BlockLot() {
    const {id} = useParams();
    const {property_name} = useParams();
    const {price} = useParams();
    const token = localStorage.getItem("token");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [formData, setFormData] = useState({
        price: price,
    });
    const [showAddModal, setAddModal] = useState(false);
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);
    const [isModifying, setModifying] = useState(false);

    const [deleteDataId, setDeleteDataId] = useState(null);
    const [deleteDataName, setDeleteDataName] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [reserveDataId, setReserveDataId] = useState(null);
    const [reserveDataName, setReserveDataName] = useState(null);
    const [showReserveModal, setShowReserveModal] = useState(false);

    const pageCount = Math.ceil(total / pageSize);
    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/property-block-lot/${id}?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pageNumber, pageSize, searchTerm, token, id])


    function confirmAddData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            model: formData.get("model"),
            price: formData.get("price"),
            reservation_fee: formData.get("reservation_fee"),
            downpayment: formData.get("downpayment"),
            loanable_amount: formData.get("loanable_amount"),
            location: formData.get("location"),
            total_area: formData.get("total_area"),
            block_no: formData.get("block_no"),
            lot_no: formData.get("lot_no"),
            monthly_equity: formData.get("monthly_equity"),
            equity_term: formData.get("equity_term"),
        };
        setFormData(data);
        setAddConfirmModal(true);
    }

    function handleAddData() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/block-lot/${id}`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const newData = response.data.new_data;
                    setData(prevData => [newData, ...prevData]);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                console.log(error)
                toast.error('Block and Lot already exists!')
            })
            .finally(() => {
                setModifying(false)
                setAddModal(false)
                setFormData({price: price})
            })
    }

    function confirmDeleteData(id, name) {
        setDeleteDataId(id);
        setDeleteDataName(name);
        setShowDeleteModal(true);
    }

    function confirmReserveData(id, name) {
        setReserveDataId(id);
        setReserveDataName(name);
        setShowReserveModal(true);
    }

    function handleEditData(id) {
        axios.put(`${process.env.REACT_APP_API_URL}/update/block-lot/${id}`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const updatedData = response.data.updated_data;
                    const updatedIndex = data.findIndex(item => item.id === updatedData.id);
                    const updatedData2 = [...data];
                    updatedData2[updatedIndex] = updatedData;
                    setData(updatedData2);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
    }

    function handleDeleteData(id) {
        fetch(`${process.env.REACT_APP_API_URL}/delete/block-lot/${id}`, {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                const updatedData = data.filter(item => item.id !== id);
                setData(updatedData);
                toast.success('Block and Lot removed successfully.');
            })
            .catch(error => {
                console.error(error);
                toast.error('An error occurred while deleting data.');
            });
    }

    if (isLoading) {
        return (<Loading/>);
    }
    return (
        <>
            <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Manage Block and Lot
                of {property_name}</h3>
            <div className="card shadow border-primary mb-3 mx-4">
                <div className="card-header">
                    <p className="text-primary m-0 fw-bold d-inline">Block and Lot Information</p>
                    <button className="btn btn-primary text-end float-end btn-sm" onClick={() => {
                        setAddModal(true)
                    }}>Add New Block and Lot
                    </button>
                </div>
                <div className="card-body">
                    <div className="row g-3">
                        <div className='col-md-11'>
                            <input type="text" className="form-control" placeholder="Search Name!"
                                   aria-label="Search"
                                   aria-describedby="basic-addon2" value={searchTerm}
                                   onChange={e => setSearchTerm(e.target.value)}/>
                        </div>
                        <div className='col-md'>
                            <select className="form-control" value={pageSize} onChange={e => {
                                setPageSize(Number(e.target.value));
                                setPageNumber(0); // Reset the page number when the page size changes
                            }}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                    </div>

                    <div className="table-responsive table mt-2" id="dataTable" role="grid"
                         aria-describedby="dataTable_info">
                        <table className="table my-0" id="dataTable">
                            <thead>
                            <tr>
                                <th>Block No.</th>
                                <th>Lot No.</th>
                                <th>Model</th>
                                <th>TCP</th>
                                <th>Reservation Fee</th>
                                <th>Downpayment</th>
                                <th>Loanable Amount</th>
                                <th>Location</th>
                                <th>Total Area</th>
                                <th>Monthly Equity</th>
                                <th>Equity Term</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody className='table-group-divider'>
                            {data.length === 0 ? (<tr>
                                <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                            </tr>) : (data.map((data) => (<tr key={data.id}>
                                <td>{data.block_no}</td>
                                <td>{data.lot_no}</td>
                                <td>{data.model}</td>
                                <td>{data.price}</td>
                                <td>{data.reservation_fee}</td>
                                <td>{data.downpayment}</td>
                                <td>{data.loanable_amount}</td>
                                <td>{data.location}</td>
                                <td>{data.total_area}</td>
                                <td>{data.monthly_equity}</td>
                                <td>{data.equity_term}</td>
                                <td>{data.status_name}</td>
                                <td>
                                    <button className="btn btn-danger btn-sm mx-1"
                                            onClick={() => confirmDeleteData(data.id, data.name)}>
                                        <i
                                            className='fas fa-trash-alt'></i></button>
                                    <button className="btn btn-info btn-sm"
                                            onClick={() => confirmReserveData(data.id, data.name)}>
                                        <i
                                            className='fas fa-tag'></i></button>
                                </td>
                            </tr>)))}
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        containerClassName="pagination justify-content-center mt-3"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        breakClassName="page-item"
                        disabledClassName="disabled"
                    />
                </div>
            </div>

            <Modal
                size="lg"
                show={showAddModal}
                onHide={() => setAddModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add New Block and Lot
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddData}>
                        <label className="form-label">Model</label>
                        <input className="form-control" type="text" name="model" id="model"
                               placeholder="Enter Model"
                               required/>
                        <label className="form-label">Total Lot Contract Price</label>
                        <NumericFormat
                            className="form-control"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            name="price" id="price"
                            required
                        />
                        <label className="form-label">Reservation Fee</label>
                        <NumericFormat
                            className="form-control"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            name="reservation_fee" id="reservation_fee"
                            required
                        />
                        <label className="form-label">Downpayment</label>
                        <NumericFormat
                            className="form-control"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            name="downpayment" id="downpayment"
                            required
                        />
                        <label className="form-label">Loanable Amount</label>
                        <NumericFormat
                            className="form-control"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            name="loanable_amount" id="loanable_amount"
                            required
                        />
                        <div className='row'>
                            <div className='col'>
                                <label className="form-label">Location</label>
                                <input className="form-control" type="text" name="location" id="location"
                                       placeholder="Enter Location"
                                       required/>
                            </div>
                            <div className='col'>
                                <label className="form-label">Total Area</label>
                                <input className="form-control" type="number" name="total_area" id="total_area"
                                       placeholder="Enter Total Area"
                                       step='any'
                                       required/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <label className="form-label">Block Number</label>
                                <input className="form-control" type="number" name="block_no" id="block_no"
                                       placeholder="Enter Block Number"
                                       required/>
                            </div>
                            <div className='col'>
                                <label className="form-label">Lot Number</label>
                                <input className="form-control" type="number" name="lot_no" id="lot_no"
                                       placeholder="Enter Lot Number"
                                       required/>
                            </div>
                        </div>
                        <label className="form-label">Monthly Equity</label>
                        <NumericFormat
                            className="form-control"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            name="monthly_equity" id="monthly_equity"
                            required
                        />
                        <label className="form-label">Equity Term</label>
                        <input className="form-control" type="text" name="equity_term" id="equity_term"
                               placeholder="Enter Equity Term"
                               required/>
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Block and Lot Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Model:</strong> {formData.model}</p>
                    <p><strong>Total Contract Price:</strong> {formData.price}</p>
                    <p><strong>Reservation Fee:</strong> {formData.reservation_fee}</p>
                    <p><strong>Downpayment:</strong> {formData.downpayment}</p>
                    <p><strong>Loanable Amount:</strong> {formData.loanable_amount}</p>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Location:</strong> {formData.location}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Total Area:</strong> {formData.total_area}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Block Number:</strong> {formData.block_no}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Lot Number:</strong> {formData.lot_no}</p>
                        </div>
                    </div>
                    <p><strong>Monthly Equity:</strong> {formData.monthly_equity}</p>
                    <p><strong>Equity Term:</strong> {formData.equity_term}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setAddConfirmModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setAddConfirmModal(false);
                        handleAddData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Delete Block and Lot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete {deleteDataName}?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => {
                        handleDeleteData(deleteDataId);
                        setShowDeleteModal(false);
                    }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showReserveModal} onHide={() => setShowReserveModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Change Status of Block and Lot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to Change status of {reserveDataName}?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowReserveModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleEditData(reserveDataId);
                        setShowReserveModal(false);
                    }}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default BlockLot;