import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Loading from "../others/Loading";
import ReactPaginate from "react-paginate";
import {toast} from "react-toastify";
import {Button, Modal} from "react-bootstrap";
import Editor from "ckeditor5-custom-build";
import {NumericFormat} from "react-number-format";
import {Typeahead} from "react-bootstrap-typeahead";

function Buyers() {
    const token = localStorage.getItem("token");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true)

    const [data, setData] = useState([])
    const [formData, setFormData] = useState({});

    const [showAddModal, setAddModal] = useState(false);
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);
    const [isModifying, setModifying] = useState(false);
    
    const [update_id, setUpdateId] = useState('')
    const [showEditModal, setEditModal] = useState(false);
    const [showEditConfrimModal, setEditConfirmModal] = useState(false);


    const navigate = useNavigate();


    const pageCount = Math.ceil(total / pageSize);
    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };


    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.role;
    }

    const userRole = getUserRole(token);

    function getUserId(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.id;
    }

    const userId = getUserId(token);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/buyers?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pageNumber, pageSize, searchTerm, token])

     function confirmAddData(event) {
      event.preventDefault()
         setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            name: formData.get("name"),
            age: formData.get("age"),
            birth_date: formData.get("birth_date"),
            spouse_name: formData.get("spouse_name"),
            spouse_age: formData.get("spouse_age"),
            spouse_birth_date: formData.get("spouse_birth_date"),
            residential_address: formData.get("residential_address"),
            provincial_address: formData.get("provincial_address"),
            contact_number: formData.get("contact_number"),
            telephone_number: formData.get("telephone_number"),
            email: formData.get("email"),
            facebook: formData.get("facebook"),
            civil_status: formData.get("civil_status"),
            residence_cert_number: formData.get("residence_cert_number"),
            nationality: formData.get("nationality"),
            organization: formData.get("organization"),
            valid_id: formData.get("valid_id"),
            valid_id_number: formData.get("valid_id_number"),
            occupation: formData.get("occupation"),
            type_of_business: formData.get("type_of_business"),
            company_name: formData.get("company_name"),
            business_name: formData.get("business_name"),
            company_address: formData.get("company_address"),
            business_address: formData.get("business_address"),
            monthly_income: formData.get("monthly_income"),
            tin_number: formData.get("tin_number"),
        };
        setFormData(data);
        setAddConfirmModal(true);
    }

    function handleAddData() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/buyer`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
              if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const newData = response.data.new_data;
                    setData(prevData => [newData, ...prevData]);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setModifying(false)
                setAddModal(false)
            })
    }
    
    function confirmEditData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
           name: formData.get("name"),
            age: formData.get("age"),
            birth_date: formData.get("birth_date"),
            spouse_name: formData.get("spouse_name"),
            spouse_age: formData.get("spouse_age"),
            spouse_birth_date: formData.get("spouse_birth_date"),
            residential_address: formData.get("residential_address"),
            provincial_address: formData.get("provincial_address"),
            contact_number: formData.get("contact_number"),
            telephone_number: formData.get("telephone_number"),
            email: formData.get("email"),
            facebook: formData.get("facebook"),
            civil_status: formData.get("civil_status"),
            residence_cert_number: formData.get("residence_cert_number"),
            nationality: formData.get("nationality"),
            organization: formData.get("organization"),
            valid_id: formData.get("valid_id"),
            valid_id_number: formData.get("valid_id_number"),
            occupation: formData.get("occupation"),
            type_of_business: formData.get("type_of_business"),
            company_name: formData.get("company_name"),
            business_name: formData.get("business_name"),
            company_address: formData.get("company_address"),
            business_address: formData.get("business_address"),
            monthly_income: formData.get("monthly_income"),
            tin_number: formData.get("tin_number"),
        };
        setFormData(data);
        setEditConfirmModal(true);
    }

    function handleEditData() {
        axios.put(`${process.env.REACT_APP_API_URL}/update/buyer/${update_id}`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const updatedData = response.data.updated_data;
                    const updatedIndex = data.findIndex(item => item.id === updatedData.id);
                    const updatedData2 = [...data];
                    updatedData2[updatedIndex] = updatedData;
                    setData(updatedData2);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setEditModal(false)
                setUpdateId('')
            })
    }

        function handlePrintBuyer(id) {
        navigate(`/portal/print-buyer/${id}`);
    }

    if (isLoading) {
        return (<Loading/>);
    }
    return (
        <>
            <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Buyers Info.</h3>
            <div className="card shadow border-primary mb-3 mx-4">
                <div className="card-header">
                    <p className="text-primary m-0 fw-bold d-inline">Buyers Information</p>
                    {userRole === 1 || userRole === 2 ? (
                        <button className="btn btn-primary text-end float-end btn-sm" onClick={() => {
                            setAddModal(true)
                        }}>Add New Buyer
                        </button>) : null}
                </div>
                <div className="card-body">
                    <div className="row g-3">
                        <div className='col-md-11'>
                            <input type="text" className="form-control" placeholder="Search Name or Email!"
                                   aria-label="Search"
                                   aria-describedby="basic-addon2" value={searchTerm}
                                   onChange={e => setSearchTerm(e.target.value)}/>
                        </div>
                        <div className='col-md'>
                            <select className="form-control" value={pageSize} onChange={e => {
                                setPageSize(Number(e.target.value));
                                setPageNumber(0); // Reset the page number when the page size changes
                            }}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                    </div>

                    <div className="table-responsive table mt-2" id="dataTable" role="grid"
                         aria-describedby="dataTable_info">
                        <table className="table my-0" id="dataTable">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Contact No.</th>
                                <th>Residence Address</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody className='table-group-divider'>
                            {data.length === 0 ? (<tr>
                                <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                            </tr>) : (data.map((data) => (<tr key={data.id}>
                                <td>{data.name}</td>
                                <td>{data.email}</td>
                                <td>{data.contact_number}</td>
                                <td>{data.residence_address}</td>
                                <td>
                                    <button className="btn btn-warning btn-sm mx-1" onClick={() => {
                                        setUpdateId(data.id)
                                        setFormData({
                                            name: data.name,
                                            age: data.age,
                                            birth_date: data.birth_date,
                                            spouse_name: data.spouse_name,
                                            spouse_age: data.spouse_age,
                                            spouse_birth_date: data.spouse_birth_date,
                                            residence_address: data.residence_address,
                                            provincial_address: data.provincial_address,
                                            contact_number: data.contact_number,
                                            telephone_number: data.telephone_number,
                                            email: data.email,
                                            facebook: data.facebook,
                                            civil_status: data.civil_status,
                                            residence_cert_number: data.residence_cert_number,
                                            nationality: data.nationality,
                                            organization: data.organization,
                                            valid_id: data.valid_id,
                                            valid_id_number: data.valid_id_number,
                                            occupation: data.occupation,
                                            type_of_business: data.type_of_business,
                                            company_name: data.company_name,
                                            business_name: data.business_name,
                                            company_address: data.company_address,
                                            business_address: data.business_address,
                                            monthly_income: data.monthly_income,
                                            tin_number: data.tin_number,
                                        });
                                        setEditModal(true)
                                    }}><i className='fas fa-edit'></i></button>
                                    <button className="btn btn-warning btn-sm mx-1"
                                            onClick={() => handlePrintBuyer(data.id)}>
                                        <i className="fa fa-print" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>)))}
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        containerClassName="pagination justify-content-center mt-3"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        breakClassName="page-item"
                        disabledClassName="disabled"
                    />
                </div>
            </div>

            <Modal
                size="lg"
                show={showAddModal}
                onHide={() => setAddModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add New Buyer
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddData}>
                        <div className='row'>
                            <div className='col'>
                                <label className="form-label">Name</label>
                                <input className="form-control" type="text" name="name" id="name"
                                       placeholder="Enter Name"
                                       required/>
                            </div>
                            <div className='col'>
                                <label className="form-label">Age</label>
                                <input className="form-control" type="number" name="age" id="age"
                                       placeholder="Enter Age"
                                       required/>
                            </div>
                            <div className='col'>
                                <label className="form-label">Birth Date</label>
                                <input className="form-control" type="date" name="birth_date" id="birth_date"
                                       placeholder="Enter Birth Date"
                                       required/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <label className="form-label">Spouse Name</label>
                                <input className="form-control" type="text" name="spouse_name" id="spouse_name"
                                       placeholder="Enter Spouse Name"
                                       required/>
                            </div>
                            <div className='col'>
                                <label className="form-label">Age</label>
                                <input className="form-control" type="number" name="spouse_age" id="spouse_age"
                                       placeholder="Enter Spouse Age"
                                       required/>
                            </div>
                            <div className='col'>
                                <label className="form-label">Birth Date</label>
                                <input className="form-control" type="date" name="spouse_birth_date"
                                       id="spouse_birth_date"
                                       placeholder="Enter Spouse Birth Date"
                                       required/>
                            </div>
                        </div>
                        <label className="form-label">Residential Address</label>
                        <textarea className="form-control" name="residential_address" id="residential_address"
                                  placeholder="Enter Residential Address" required
                        />
                        <label className="form-label">Provincial Address</label>
                        <textarea className="form-control" name="provincial_address" id="provincial_address"
                                  placeholder="Enter Provincial Address" required
                        />
                        <div className='row'>
                            <div className='col'>
                                <label className="form-label">Contact No.</label>
                                <input className="form-control" type="text" name="contact_number" id="contact_number"
                                       placeholder="Enter Contact number"
                                       required/>
                            </div>
                            <div className='col'>
                                <label className="form-label">Telephone No.</label>
                                <input className="form-control" type="text" name="telephone_number"
                                       id="telephone_number"
                                       placeholder="Enter Telephone Number"
                                       required/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <label className="form-label">Email Address</label>
                                <input className="form-control" type="text" name="email" id="email"
                                       placeholder="Enter Email Address"
                                       required/>
                            </div>
                            <div className='col'>
                                <label className="form-label">Facebook Acct.</label>
                                <input className="form-control" type="text" name="facebook"
                                       id="facebook"
                                       placeholder="Enter Facebook Account"
                                       required/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <label className="form-label">Civil Status</label>
                                <input className="form-control" type="text" name="civil_status" id="civil_status"
                                       placeholder="Enter Civil Status"
                                       required/>
                            </div>
                            <div className='col'>
                                <label className="form-label">Residence Cert No.</label>
                                <input className="form-control" type="text" name="residence_cert_number"
                                       id="residence_cert_number"
                                       placeholder="Enter Residence Cert No."
                                       required/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <label className="form-label">Nationality</label>
                                <input className="form-control" type="text" name="nationality" id="nationality"
                                       placeholder="Enter Nationality"
                                       required/>
                            </div>
                            <div className='col'>
                                <label className="form-label">Assn./Org. Membership</label>
                                <input className="form-control" type="text" name="organization"
                                       id="organization"
                                       placeholder="Enter Assn./Org. Membership"
                                       required/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <label className="form-label">Valid ID</label>
                                <input className="form-control" type="text" name="valid_id" id="valid_id"
                                       placeholder="Enter Valid ID"
                                       required/>
                            </div>
                            <div className='col'>
                                <label className="form-label">Valid ID No.</label>
                                <input className="form-control" type="text" name="valid_id_number"
                                       id="valid_id_number"
                                       placeholder="Enter Valid ID Number"
                                       required/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <label className="form-label">Occupation</label>
                                <input className="form-control" type="text" name="occupation" id="occupation"
                                       placeholder="Enter Occupation"
                                       required/>
                            </div>
                            <div className='col'>
                                <label className="form-label">Type of Business</label>
                                <input className="form-control" type="text" name="type_of_business"
                                       id="type_of_business"
                                       placeholder="Enter Type of Business"
                                       required/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <label className="form-label">Company Name</label>
                                <input className="form-control" type="text" name="company_name" id="company_name"
                                       placeholder="Enter Company Name"
                                       required/>
                            </div>
                            <div className='col'>
                                <label className="form-label">Business Name</label>
                                <input className="form-control" type="text" name="business_name"
                                       id="business_name"
                                       placeholder="Enter Type of Business Name"
                                       required/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <label className="form-label">Company Address</label>
                                <textarea className="form-control" name="company_address" id="company_address"
                                          placeholder="Enter Company Address" required
                                />
                            </div>
                            <div className='col'>
                                <label className="form-label">Business Address</label>
                                <textarea className="form-control" name="business_address" id="business_address"
                                          placeholder="Enter Business Address" required
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <label className="form-label">Monthly Income</label>
                                <NumericFormat
                                    className="form-control"
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    decimalScale={2}
                                    name="monthly_income" id="monthly_income"
                                    required
                                />
                            </div>
                            <div className='col'>
                                <label className="form-label">T.I.N. No</label>
                                <input className="form-control" name="tin_number" id="tin_number"
                                       placeholder="Enter T.I.N. Number" required
                                />
                            </div>
                        </div>
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Buyers Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Name:</strong> {formData.name}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Age:</strong> {formData.age}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Birth Date:</strong> {formData.birth_date}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Spouse Name:</strong> {formData.spouse_name}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Spouse Age:</strong> {formData.spouse_age}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Spouse Birth Date:</strong> {formData.spouse_birth_date}</p>
                        </div>
                    </div>
                    <p><strong>Residential Address:</strong> {formData.residential_address}</p>
                    <p><strong>Provincial Address:</strong> {formData.provincial_address}</p>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Contact No.:</strong> {formData.contact_number}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Telephone No.:</strong> {formData.telephone_number}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Email Address:</strong> {formData.email}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Facebook Acct.:</strong> {formData.facebook}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Civil Status:</strong> {formData.civil_status}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Residence Cert No.:</strong> {formData.residence_cert_number}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Nationality:</strong> {formData.nationality}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Assn./Org. Membership:</strong> {formData.organization}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Valid ID:</strong> {formData.valid_id}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Valid ID No.:</strong> {formData.valid_id_number}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Occupation:</strong> {formData.occupation}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Type of Business:</strong> {formData.type_of_business}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Company Name:</strong> {formData.company_name}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Business Name:</strong> {formData.business_name}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Company Address:</strong> {formData.company_address}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Business Address:</strong> {formData.business_address}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Monthly Income:</strong> {formData.monthly_income}</p>
                        </div>
                        <div className='col'>
                            <p><strong>T.I.N. No.:</strong> {formData.tin_number}</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setAddConfirmModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setAddConfirmModal(false);
                        handleAddData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

             <Modal
            size="lg"
            show={showEditModal}
            onHide={() => setEditModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Edit Buyer Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={confirmEditData}>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Name</label>
                            <input className="form-control" type="text" name="name" id="name"
                                   placeholder="Enter Name"
                                   value={formData.name}
                                   onChange={(e) => setFormData({...formData, name: e.target.value})}
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Age</label>
                            <input className="form-control" type="number" name="age" id="age"
                                   placeholder="Enter Age"
                                   required
                                value={formData.age}
                               onChange={(e) => setFormData({...formData, age: e.target.value})}
                            />
                        </div>
                        <div className='col'>
                            <label className="form-label">Birth Date</label>
                            <input className="form-control" type="date" name="birth_date" id="birth_date"
                                   placeholder="Enter Birth Date"
                                   required
                            value={formData.birth_date}
                           onChange={(e) => setFormData({...formData, birth_date: e.target.value})}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Spouse Name</label>
                            <input className="form-control" type="text" name="spouse_name" id="spouse_name"
                                   placeholder="Enter Spouse Name"
                                   required
                            value={formData.spouse_name}
                           onChange={(e) => setFormData({...formData, spouse_name: e.target.value})}/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Age</label>
                            <input className="form-control" type="number" name="spouse_age" id="spouse_age"
                                   placeholder="Enter Spouse Age"
                                   value={formData.spouse_age}
                           onChange={(e) => setFormData({...formData, spouse_age: e.target.value})}
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Birth Date</label>
                            <input className="form-control" type="date" name="spouse_birth_date"
                                   id="spouse_birth_date"
                                   placeholder="Enter Spouse Birth Date"
                                   value={formData.spouse_birth_date}
                           onChange={(e) => setFormData({...formData, spouse_birth_date: e.target.value})}
                                   required/>
                        </div>
                    </div>
                    <label className="form-label">Residential Address</label>
                    <textarea className="form-control" name="residential_address" id="residential_address"
                              placeholder="Enter Residential Address" required
                              value={formData.residence_address}
                           onChange={(e) => setFormData({...formData, residence_address: e.target.value})}
                    />
                    <label className="form-label">Provincial Address</label>
                    <textarea className="form-control" name="provincial_address" id="provincial_address"
                              placeholder="Enter Provincial Address" required
                              value={formData.provincial_address}
                           onChange={(e) => setFormData({...formData, provincial_address: e.target.value})}
                    />
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Contact No.</label>
                            <input className="form-control" type="text" name="contact_number" id="contact_number"
                                   placeholder="Enter Contact number"
                                   value={formData.contact_number}
                                    onChange={(e) => setFormData({...formData, contact_number: e.target.value})}
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Telephone No.</label>
                            <input className="form-control" type="text" name="telephone_number"
                                   id="telephone_number"
                                   placeholder="Enter Telephone Number"
                                   value={formData.telephone_number}
                           onChange={(e) => setFormData({...formData, telephone_number: e.target.value})}
                                   required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Email Address</label>
                            <input className="form-control" type="text" name="email" id="email"
                                   placeholder="Enter Email Address"
                                   value={formData.email}
                           onChange={(e) => setFormData({...formData, email: e.target.value})}
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Facebook Acct.</label>
                            <input className="form-control" type="text" name="facebook"
                                   id="facebook"
                                   placeholder="Enter Facebook Account"
                                   value={formData.facebook}
                           onChange={(e) => setFormData({...formData, facebook: e.target.value})}
                                   required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Civil Status</label>
                            <input className="form-control" type="text" name="civil_status" id="civil_status"
                                   placeholder="Enter Civil Status"
                                   value={formData.civil_status}
                           onChange={(e) => setFormData({...formData, civil_status: e.target.value})}
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Residence Cert No.</label>
                            <input className="form-control" type="text" name="residence_cert_number"
                                   id="residence_cert_number"
                                   placeholder="Enter Residence Cert No."
                                   value={formData.residence_cert_number}
                           onChange={(e) => setFormData({...formData, residence_cert_number: e.target.value})}
                                   required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Nationality</label>
                            <input className="form-control" type="text" name="nationality" id="nationality"
                                   placeholder="Enter Nationality"
                                   value={formData.nationality}
                           onChange={(e) => setFormData({...formData, nationality: e.target.value})}
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Assn./Org. Membership</label>
                            <input className="form-control" type="text" name="organization"
                                   id="organization"
                                   placeholder="Enter Assn./Org. Membership"
                                   value={formData.organization}
                           onChange={(e) => setFormData({...formData, organization: e.target.value})}
                                   required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Valid ID</label>
                            <input className="form-control" type="text" name="valid_id" id="valid_id"
                                   placeholder="Enter Valid ID"
                                   value={formData.valid_id}
                           onChange={(e) => setFormData({...formData, valid_id: e.target.value})}
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Valid ID No.</label>
                            <input className="form-control" type="text" name="valid_id_number"
                                   id="valid_id_number"
                                   placeholder="Enter Valid ID Number"
                                   value={formData.valid_id_number}
                           onChange={(e) => setFormData({...formData, valid_id_number: e.target.value})}
                                   required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Occupation</label>
                            <input className="form-control" type="text" name="occupation" id="occupation"
                                   placeholder="Enter Occupation"
                                   value={formData.occupation}
                           onChange={(e) => setFormData({...formData, occupation: e.target.value})}
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Type of Business</label>
                            <input className="form-control" type="text" name="type_of_business"
                                   id="type_of_business"
                                   placeholder="Enter Type of Business"
                                   value={formData.type_of_business}
                           onChange={(e) => setFormData({...formData, type_of_business: e.target.value})}
                                   required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Company Name</label>
                            <input className="form-control" type="text" name="company_name" id="company_name"
                                   placeholder="Enter Company Name"
                                   value={formData.company_name}
                           onChange={(e) => setFormData({...formData, company_name: e.target.value})}
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Business Name</label>
                            <input className="form-control" type="text" name="business_name"
                                   id="business_name"
                                   value={formData.business_name}
                           onChange={(e) => setFormData({...formData, business_name: e.target.value})}
                                   placeholder="Enter Type of Business Name"
                                   required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Company Address</label>
                            <textarea className="form-control" name="company_address" id="company_address"
                                      placeholder="Enter Company Address" required
                                      value={formData.company_address}
                           onChange={(e) => setFormData({...formData, company_address: e.target.value})}
                            />
                        </div>
                        <div className='col'>
                            <label className="form-label">Business Address</label>
                            <textarea className="form-control" name="business_address" id="business_address"
                                      placeholder="Enter Business Address" required
                                      value={formData.business_address}
                           onChange={(e) => setFormData({...formData, business_address: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Monthly Income</label>
                            <NumericFormat
                                className="form-control"
                                thousandSeparator={true}
                                allowNegative={false}
                                decimalScale={2}
                                name="monthly_income" id="monthly_income"
                                required
                                 value={formData.monthly_income}
                           onChange={(e) => setFormData({...formData, monthly_income: e.target.value})}
                            />
                        </div>
                        <div className='col'>
                            <label className="form-label">T.I.N. No</label>
                            <input className="form-control" name="tin_number" id="tin_number"
                                   placeholder="Enter T.I.N. Number" required
                                    value={formData.tin_number}
                           onChange={(e) => setFormData({...formData, tin_number: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className="align-content-end">
                        <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                        >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Update"}
                        </button>
                    </div>
                </form>
            </Modal.Body>
             </Modal>

            <Modal show={showEditConfrimModal} onHide={() => setEditConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Edited Buyers Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Name:</strong> {formData.name}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Age:</strong> {formData.age}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Birth Date:</strong> {formData.birth_date}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Spouse Name:</strong> {formData.spouse_name}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Spouse Age:</strong> {formData.spouse_age}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Spouse Birth Date:</strong> {formData.spouse_birth_date}</p>
                        </div>
                    </div>
                    <p><strong>Residential Address:</strong> {formData.residential_address}</p>
                    <p><strong>Provincial Address:</strong> {formData.provincial_address}</p>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Contact No.:</strong> {formData.contact_number}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Telephone No.:</strong> {formData.telephone_number}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Email Address:</strong> {formData.email}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Facebook Acct.:</strong> {formData.facebook}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Civil Status:</strong> {formData.civil_status}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Residence Cert No.:</strong> {formData.residence_cert_number}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Nationality:</strong> {formData.nationality}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Assn./Org. Membership:</strong> {formData.organization}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Valid ID:</strong> {formData.valid_id}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Valid ID No.:</strong> {formData.valid_id_number}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Occupation:</strong> {formData.occupation}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Type of Business:</strong> {formData.type_of_business}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Company Name:</strong> {formData.company_name}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Business Name:</strong> {formData.business_name}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Company Address:</strong> {formData.company_address}</p>
                        </div>
                        <div className='col'>
                            <p><strong>Business Address:</strong> {formData.business_address}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>Monthly Income:</strong> {formData.monthly_income}</p>
                        </div>
                        <div className='col'>
                            <p><strong>T.I.N. No.:</strong> {formData.tin_number}</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setEditConfirmModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setEditConfirmModal(false);
                        handleEditData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Buyers;