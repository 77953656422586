import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../../others/Loading";
import {Button, Modal} from "react-bootstrap";
import {PatternFormat} from "react-number-format";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";

function Profile() {
    const token = localStorage.getItem("token");
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [profPic, setProfPic] = useState('')
    const [isLogo, setLogo] = useState('')
    const [formData, setFormData] = useState({});

    const [schedules, setSchedules] = useState([])


    const [showEditModal, setEditModal] = useState(false);
    const [showEditConfrimModal, setEditConfirmModal] = useState(false);
    const [isModifying, setModifying] = useState(false);

    const [showEditPasswordModal, setEditPasswordModal] = useState(false);
    const [showEditConfrimPasswordModal, setEditConfirmPasswordModal] = useState(false);

    const [previewImageUrl, setPreviewImageUrl] = useState(null);

    const [showEditProfPicModal, setEditProfPicModal] = useState(false);
    const [selectedProfPicFile, setSelectedProfPicFile] = useState(null);


    const [showEditLogoModal, setEditPLogoModal] = useState(false);
    const [selectedLogoFile, setSelectedLogoFile] = useState(null);

    const {id} = useParams();

    const [data2, setData2] = useState([])
    const [showEditRealtyModal, setEditRealtyModal] = useState(false);
    const [showEditConfrimRealtyModal, setEditConfirmRealtyModal] = useState(false);

    const [showAddModal, setAddModal] = useState(false);
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/user-profile/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
                setProfPic(`${process.env.REACT_APP_API_URL}/uploaded_img/${response.data.data.profile_picture}`)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [id, token])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/realty`)
            .then(response => {
                setLogo(`${process.env.REACT_APP_API_URL}/uploaded_img/${response.data.logo}`)
                setData2(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/sitetrippingdatetime_view`, {})
            .then(response => {
                setSchedules(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [token])

    function confirmEditData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            first_name: formData.get("first_name"),
            middle_name: formData.get("middle_name"),
            last_name: formData.get("last_name"),
            suffix: formData.get("suffix"),
            phone: formData.get("phone"),
            withholding_tax: formData.get("withholding_tax"),
            business_tax: formData.get("business_tax"),
        };
        setFormData(data);
        setEditConfirmModal(true);
    }

    function handleEditData() {
        axios.put(`${process.env.REACT_APP_API_URL}/update/profile/${data.id}`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const updatedUserData = response.data.updated_data;
                    setData(updatedUserData);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setEditModal(false)
            })
    }

    function confirmEditPasswordData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);
        const newPassword = formData.get("password");
        const newPassword2 = formData.get("password2");

        // Check if the passwords match
        if (newPassword !== newPassword2) {
            toast.error("Password does not match!");
            setModifying(false); // Reset the modifying state
            return; // Don't proceed with the API request
        }

        const data = {
            password: newPassword,
        };
        setFormData(data);
        setEditConfirmPasswordModal(true);
    }

    function handleEditPasswordData() {
        axios.put(`${process.env.REACT_APP_API_URL}/update/profile-password/${data.id}`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const updatedUserData = response.data.updated_data;
                    setData(updatedUserData);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setEditPasswordModal(false)
            })
    }


    const handleEditProfPic = async () => {
        if (!selectedProfPicFile) {
            return;
        }

        const formData = new FormData();
        formData.append("image", selectedProfPicFile);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/update/profile-picture/${data.id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data',
                }
            });

            const updatedUserData = response.data.updated_data;
            setProfPic(`${process.env.REACT_APP_API_URL}/uploaded_img/${updatedUserData.profile_picture}`);

            setSelectedProfPicFile(null);
            setEditProfPicModal(false)
            toast.success('Profile Picture updated successfully.');
        } catch (error) {
            console.error('Error adding image:', error);
            toast.error('An error occurred while adding profile picture.');
        }
    };


    function confirmEditRealtyData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            name: formData.get("name"),
            address: formData.get("address"),
            phone: formData.get("phone"),
            email: formData.get("email"),
        };
        setFormData(data);
        setEditConfirmRealtyModal(true);
    }

    function handleEditRealtyData() {
        axios.put(`${process.env.REACT_APP_API_URL}/update/realty/${data2.id}`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const updatedData = response.data.updated_data;
                    setData2(updatedData);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setEditRealtyModal(false)
            })
    }

    const handleEditLogo = async () => {
        if (!selectedLogoFile) {
            return;
        }

        const formData = new FormData();
        formData.append("image", selectedLogoFile);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/update/realty-logo`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data',
                }
            });

            const updatedLogo = response.data.updated_data;
            setLogo(`${process.env.REACT_APP_API_URL}/uploaded_img/${updatedLogo.logo}`);


            setSelectedLogoFile(null);
            setEditPLogoModal(false)
            toast.success('Realty Logo updated successfully.');
        } catch (error) {
            console.error('Error adding image:', error);
            toast.error('An error occurred while adding realty logo.');
        }
    };

    function confirmAddData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            day: formData.get("day"), stime: formData.get("stime"), etime: formData.get("etime"),
        };
        setFormData(data);
        setAddConfirmModal(true);
    }

    function handleAddData() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/sitetrippingdatetime`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const newData = response.data.new_data;
                    setSchedules(prevData => [newData, ...prevData]);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setModifying(false)
                setAddModal(false)
            })
    }


    if (isLoading) {
        return (<Loading/>);
    }

    return (<>
            {data.role === 1 || data.staff_role === 1 ? (
                    <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">User Profile & Developer
                        Information</h3>) :
                <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">User Profile</h3>}
            <div className="row mb-3 mx-4">
                <div className="col-lg-4">
                    <div className="card mb-3">
                        <div className="card-header py-3">
                            <p className="text-primary m-0 fw-bold">Profile Picture</p>
                        </div>
                        <div className="card-body text-center shadow">
                            <img
                                className="rounded-circle mb-3 mt-4"
                                src={profPic}
                                alt="Upload profile picture"
                                style={{
                                    width: "160px",   // Set the desired width
                                    height: "160px",  // Set the desired height
                                    objectFit: "cover",  // Maintain aspect ratio and cover the container
                                    borderRadius: "50%", // Make it circular
                                }}
                            />
                            <div className="mb-3">
                                <button className="btn btn-primary btn-sm" onClick={() => {
                                    setEditProfPicModal(true)
                                }}>Change Photo
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="row">
                        <div className="col">
                            <div className="card shadow mb-3">
                                <div className="card-header py-3">
                                    <p className="text-primary m-0 fw-bold">User Information</p>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3"><label className="form-label"
                                            ><strong>Email
                                                Address</strong></label><input className="form-control" type="email"

                                                                               value={data.email}

                                                                               readOnly
                                                                               disabled
                                            /></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3"><label className="form-label"
                                            ><strong>First
                                                Name</strong></label><input className="form-control" type="text"

                                                                            value={data.first_name} readOnly
                                                                            disabled/>
                                            </div>
                                            <div className="mb-3"><label className="form-label"
                                            ><strong>Middle Name</strong></label><input className="form-control"
                                                                                        type="text"

                                                                                        value={data.middle_name}
                                                                                        readOnly
                                                                                        disabled/>
                                            </div>
                                            <div className="mb-3"><label className="form-label"
                                            ><strong>Last
                                                Name</strong></label><input className="form-control" type="text"
                                                                            value={data.last_name} readOnly
                                                                            disabled/>
                                            </div>
                                            <div className="mb-3"><label className="form-label"
                                            ><strong>Suffix</strong></label><input
                                                className="form-control" type="text"
                                                value={data.suffix} readOnly
                                                disabled/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3"><label className="form-label"
                                            ><strong>Password</strong></label>
                                                <button
                                                    className="btn btn-primary btn-sm float-end"
                                                    onClick={() => {
                                                        setEditPasswordModal(true)
                                                    }}
                                                >Change Password
                                                </button>
                                                <input
                                                    className="form-control" type="password"
                                                    value={data.password}
                                                    readOnly
                                                    disabled/></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3"><label className="form-label"
                                            ><strong>Phone
                                                Number</strong></label><input className="form-control" type="text"

                                                                              value={data.phone}
                                                                              readOnly
                                                                              disabled/></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="mb-3"><label className="form-label"
                                            ><strong>Withholding
                                                Tax (%)</strong></label><input className="form-control"
                                                                               type="text"
                                                                               value={data.withholding_tax}
                                                                               readOnly
                                                                               disabled/></div>
                                        </div>
                                        <div className="col">
                                            <div className="mb-3"><label className="form-label"
                                                                         h><strong>Business
                                                Tax (%)</strong></label><input className="form-control"
                                                                               type="text"
                                                                               value={data.business_tax}
                                                                               readOnly
                                                                               disabled/></div>
                                        </div>
                                        {data.role === 3 || data.role === 4 ? (<div className="col">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="email">
                                                        <strong>Commission Rate (%)</strong>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={data.com_rate}
                                                        readOnly
                                                        disabled
                                                    />
                                                </div>
                                            </div>) : null}
                                    </div>
                                    <div className="mb-3">
                                        <button className="btn btn-primary btn-sm" onClick={() => {
                                            setFormData({
                                                first_name: data.first_name,
                                                middle_name: data.middle_name,
                                                last_name: data.last_name,
                                                suffix: data.suffix,
                                                phone: data.phone,
                                                withholding_tax: data.role === 1 ? data.withholding_tax : null,
                                                business_tax: data.role === 1 ? data.business_tax : null,
                                            });
                                            setEditModal(true)
                                        }}>Update Information
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {data.role === 1 || data.staff_role === 1 ? (<>
                    <div className="row mb-3 mx-4">
                        <div className="col-lg-4">
                            {data.role === 1 || data.staff_role === 1 ? (<div className="card mb-3">
                                    <div className="card-header py-3">
                                        <p className="text-primary m-0 fw-bold">Realty Logo</p>
                                    </div>
                                    <div className="card-body text-center shadow"><img
                                        className="rounded-circle mb-3 mt-4"
                                        src={isLogo}
                                        alt='Profile Picture'
                                        width="160" height="160"
                                        style={{
                                            width: "160px",   // Set the desired width
                                            height: "160px",  // Set the desired height
                                            objectFit: "cover",  // Maintain aspect ratio and cover the container
                                            borderRadius: "50%", // Make it circular
                                        }}/>
                                        <div className="mb-3">
                                            <button className="btn btn-primary btn-sm" onClick={() => {
                                                setEditPLogoModal(true)
                                            }}>Change Photo
                                            </button>
                                        </div>
                                    </div>
                                </div>) : null}
                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col">
                                    <div className="card shadow mb-3">
                                        <div className="card-header py-3">
                                            <p className="text-primary m-0 fw-bold">Realty Information</p>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="mb-3"><label className="form-label"
                                                    ><strong>Realty Name
                                                    </strong></label><input className="form-control" type="text"

                                                                            value={data2.name}

                                                                            readOnly
                                                                            disabled
                                                    /></div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="mb-3"><label className="form-label"
                                                    ><strong>Address
                                                    </strong></label><input className="form-control" type="text"

                                                                            value={data2.address} readOnly
                                                                            disabled/>
                                                    </div>
                                                    <div className="mb-3"><label className="form-label"
                                                    ><strong>Phone
                                                    </strong></label><input className="form-control" type="text"

                                                                            value={data2.phone} readOnly
                                                                            disabled/>
                                                    </div>
                                                    <div className="mb-3"><label className="form-label"
                                                    ><strong>Email
                                                    </strong></label><input className="form-control" type="text"
                                                                            value={data2.email} readOnly
                                                                            disabled/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <button className="btn btn-primary btn-sm" onClick={() => {
                                                    setFormData({
                                                        name: data2.name,
                                                        address: data2.address,
                                                        phone: data2.phone,
                                                        email: data2.email,
                                                    });
                                                    setEditRealtyModal(true)
                                                }}>Update Information
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow border-primary mb-3 mx-4">
                        <div className="card-header">
                            <p className="text-primary m-0 fw-bold d-inline">Availability Office Visit Schedule</p>
                            <button className="btn btn-primary text-end float-end btn-sm" onClick={() => {
                                setAddModal(true)
                            }}>Add Schedule
                            </button>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive table mt-2" id="dataTable" role="grid"
                                 aria-describedby="dataTable_info">
                                <table className="table my-0" id="dataTable">
                                    <thead>
                                    <tr>
                                        <th>Day Name</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody className='table-group-divider'>
                                    {data.length === 0 ? (<tr>
                                            <td colSpan="9" className="text-center"><strong>No results found.</strong>
                                            </td>
                                        </tr>) : (schedules.map((data) => (<tr key={data.id}>
                                            <td>{data.day_of_week}</td>
                                            <td>{data.start_time}</td>
                                            <td>{data.end_time}</td>
                                        </tr>)))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>) : null}

            <Modal
                size="lg"
                show={showEditModal}
                onHide={() => setEditModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Edit User Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmEditData}>
                        <label className="form-label">First Name</label>
                        <input className="form-control" type="text" name="first_name" id="first_name"
                               value={formData.first_name}
                               onChange={(e) => setFormData({...formData, first_name: e.target.value})}
                               required/>
                        <label className="form-label">Middle Name</label>
                        <input className="form-control" type="text" name="middle_name" id="middle_name"
                               value={formData.middle_name}
                               required
                               onChange={(e) => setFormData({...formData, middle_name: e.target.value})}
                        />
                        <label className="form-label">Last Name</label>
                        <input className="form-control" type="text" name="last_name" id="last_name"
                               value={formData.last_name}
                               onChange={(e) => setFormData({...formData, last_name: e.target.value})}
                               required/>
                        <label className="form-label">Suffix (optional)</label>
                        <input className="form-control" type="text" name="suffix" id="suffix"
                               value={formData.suffix}
                               onChange={(e) => setFormData({...formData, suffix: e.target.value})}
                        />
                        <label className="form-label">Phone Number</label>
                        <PatternFormat className="form-control" name="phone" id="phone"
                                       value={formData.phone}
                                       onChange={(e) => setFormData({...formData, phone: e.target.value})}
                                       format="#### ### ####"
                                       required
                                       allowEmptyFormatting mask="_"/>
                        {data.role === 1 ? (<>
                                <label className="form-label">Withholding Tax (%)</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    name="withholding_tax"
                                    id="withholding_tax"
                                    value={formData.withholding_tax}
                                    onChange={(e) => setFormData({...formData, withholding_tax: e.target.value})}
                                    required
                                    step="0.01"
                                    lang="en"
                                />
                                <label className="form-label">Business Tax (%)</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    name="business_tax"
                                    id="business_tax"
                                    value={formData.business_tax}
                                    onChange={(e) => setFormData({...formData, business_tax: e.target.value})}
                                    required
                                    step="0.01"
                                    lang="en"
                                />
                            </>) : null}
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Update"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showEditConfrimModal} onHide={() => setEditConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm User Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>First Name:</strong> {formData.first_name}</p>
                    <p><strong>Middle Name:</strong> {formData.middle_name}</p>
                    <p><strong>Last Name:</strong> {formData.last_name}</p>
                    <p><strong>Suffix:</strong> {formData.suffix}</p>
                    <p><strong>Phone Number:</strong> {formData.phone}</p>
                    {data.role === 1 ? (<>
                            <p><strong>Withholding Tax (%):</strong> {formData.withholding_tax}</p>
                            <p><strong>Business Tax (%):</strong> {formData.business_tax}</p>
                        </>) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setEditConfirmModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setEditConfirmModal(false);
                        handleEditData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="lg"
                show={showEditPasswordModal}
                onHide={() => setEditPasswordModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Update User Password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmEditPasswordData}>
                        <label className="form-label">New Password</label>
                        <input className="form-control" type="password" name="password" id="password"
                               required/>
                        <label className="form-label">Re-type Password</label>
                        <input className="form-control" type="password" name="password2" id="password2"
                               required
                        />
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Update"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showEditConfrimPasswordModal} onHide={() => setEditConfirmPasswordModal(false)}
                   backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm User Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Password:</strong> {formData.password}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setEditConfirmPasswordModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setEditConfirmPasswordModal(false);
                        handleEditPasswordData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditProfPicModal} onHide={() => {
                setEditProfPicModal(false);
                setSelectedProfPicFile(null);
                setPreviewImageUrl(null); // Clear the preview URL when closing the modal
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Profile Picture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input className="form-control" type="file" accept=".jpeg, .jpg, .png" required onChange={(e) => {
                        setSelectedProfPicFile(e.target.files[0]);
                        setPreviewImageUrl(URL.createObjectURL(e.target.files[0])); // Set the preview URL
                    }}/>
                    {selectedProfPicFile && (<div className='mt-3'>
                            <img src={previewImageUrl} alt='Profile Picture' style={{maxWidth: '100%'}}/>
                        </div>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleEditProfPic} disabled={!selectedProfPicFile}>
                        Upload
                    </Button>
                    <Button variant="secondary" onClick={() => {
                        setEditProfPicModal(false);
                        setSelectedProfPicFile(null);
                        setPreviewImageUrl(null);
                    }}>
                        Cancel
                    </Button>

                </Modal.Footer>
            </Modal>

            <Modal
                size="lg"
                show={showEditRealtyModal}
                onHide={() => setEditRealtyModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Edit Realty Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmEditRealtyData}>
                        <label className="form-label">Realty Name</label>
                        <input className="form-control" type="text" name="name" id="name"
                               value={formData.name}
                               onChange={(e) => setFormData({...formData, name: e.target.value})}
                               required/>
                        <label className="form-label">Address</label>
                        <input className="form-control" type="text" name="address" id="address"
                               value={formData.address}
                               required
                               onChange={(e) => setFormData({...formData, address: e.target.value})}
                        />
                        <label className="form-label">Phone Number</label>
                        <PatternFormat className="form-control" name="phone" id="phone"
                                       value={formData.phone}
                                       onChange={(e) => setFormData({...formData, phone: e.target.value})}
                                       format="#### ### ####"
                                       required
                                       allowEmptyFormatting mask="_"/>
                        <label className="form-label">Email</label>
                        <input className="form-control" type="email" name="email" id="email"
                               value={formData.email}
                               onChange={(e) => setFormData({...formData, email: e.target.value})}
                        />
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Update"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showEditConfrimRealtyModal} onHide={() => setEditConfirmRealtyModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Realty Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Realty Name:</strong> {formData.name}</p>
                    <p><strong>Address:</strong> {formData.address}</p>
                    <p><strong>Phone Number:</strong> {formData.phone}</p>
                    <p><strong>Email:</strong> {formData.email}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setEditConfirmRealtyModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setEditConfirmRealtyModal(false);
                        handleEditRealtyData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditLogoModal} onHide={() => {
                setEditPLogoModal(false);
                setSelectedLogoFile(null);
                setPreviewImageUrl(null);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Realty Logo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input className="form-control" type="file" accept=".jpeg, .jpg, .png" required onChange={(e) => {
                        setSelectedLogoFile(e.target.files[0]);
                        setPreviewImageUrl(URL.createObjectURL(e.target.files[0])); // Set the preview URL
                    }}/>
                    {selectedLogoFile && (<div className='mt-3'>
                            <img src={previewImageUrl} alt='Profile Picture' style={{maxWidth: '100%'}}/>
                        </div>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleEditLogo} disabled={!selectedLogoFile}>
                        Upload
                    </Button>
                    <Button variant="secondary" onClick={() => {
                        setEditPLogoModal(false);
                        selectedLogoFile(null);
                        setPreviewImageUrl(null);
                    }}>
                        Cancel
                    </Button>

                </Modal.Footer>
            </Modal>

            <Modal
                size="lg"
                show={showAddModal}
                onHide={() => setAddModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add New Availability Schedule
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddData}>
                        <label className="form-label">Day Name</label>
                        <select className="form-select" name="day" id='day' defaultValue="">
                            <option value="">Select A day</option>
                            <option value="Monday">Monday</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thursday">Thursday</option>
                            <option value="Friday">Friday</option>
                            <option value="Saturday">Saturday</option>
                            <option value="Sunday">Sunday</option>
                        </select>
                        <label className="form-label">Start Time</label>
                        <input type="time" className="form-control" id="stime" name='stime'/>
                        <label className="form-label">End Time</label>
                        <input type="time" className="form-control" id="etime" name='etime'/>
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Schedule Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Day Name:</strong> {formData.day}</p>
                    <p><strong>Start Time:</strong> {formData.stime}</p>
                    <p><strong>End Time:</strong> {formData.etime}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setAddConfirmModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setAddConfirmModal(false);
                        handleAddData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>)
}

export default Profile