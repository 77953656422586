import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../../others/Loading";
import ReactPaginate from "react-paginate";
import {Button, Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";

function PropertyDocs() {
    const token = localStorage.getItem("token");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [formData, setFormData] = useState({});
    const [showAddModal, setAddModal] = useState(false);
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);
    const [isModifying, setModifying] = useState(false);

    const {id} = useParams();
    const {property_name} = useParams();

    const pageCount = Math.ceil(total / pageSize);

    const [fileUrl, setFileUrl] = useState('');
    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/property-docs/${id}?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pageNumber, pageSize, searchTerm, token, id])

    function confirmAddData(event) {
        event.preventDefault();
        setModifying(true);

        const docFile = event.target.elements.document_file.files[0];
        const docFileName = docFile.name;

        // Check if the file extension is valid (PDF or image)
        const fileExtension = docFileName.toLowerCase().split('.').pop();
        if (['pdf', 'jpg', 'jpeg', 'png'].includes(fileExtension)) {
            setFormData({
                document_name: event.target.elements.document_name.value,
                document_file_name: docFileName,
                document_file: docFile,
            });

            const fileUrl = URL.createObjectURL(docFile);
            setFileUrl(fileUrl);

            setAddConfirmModal(true);
        } else {
            // Display an error toast for invalid file type
            toast.error('Invalid file type. Only PDF and image files are allowed.');
            setModifying(false);
        }
    }

    function handleAddData() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/document/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const newData = response.data.new_data;
                    setData(prevData => [newData, ...prevData]);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setModifying(false)
                setAddModal(false)
            })
    }

    function renderFileLink(fileName) {
        const fileExtension = fileName.split('.').pop().toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
            return <a href={getFilePath(fileName)} target="_blank" rel="noopener noreferrer">View Image</a>;
        } else if (['pdf'].includes(fileExtension)) {
            return <a href={getFilePath(fileName)} target="_blank" rel="noopener noreferrer">View PDF</a>;
        } else if (['doc', 'docx'].includes(fileExtension)) {
            return <a href={getFilePath(fileName)} target="_blank" rel="noopener noreferrer">View Word Document</a>;
        } else {
            return <a href={getFilePath(fileName)} target="_blank" rel="noopener noreferrer">View File</a>;
        }
    }

    function getFilePath(fileName) {
        return `${process.env.REACT_APP_API_URL}/uploaded_img/${fileName}`;
    }

    function handleDeleteFile(id) {
        fetch(`${process.env.REACT_APP_API_URL}/delete/property-docs/${id}`, {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    const deletedData = data.filter(item => item.id !== id);
                    setData(deletedData);
                    toast.success('File Removed successfully.');
                } else {
                    throw new Error('Failed to delete file');
                }
            })
            .catch(error => {
                console.error(error);
                toast.error('An error occurred while deleting data.');
            });
    }

    if (isLoading) {
        return (<Loading/>);
    }

    return (<>
            <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Manage Property Documents
                of {property_name}</h3>
            <div className="card shadow border-primary mb-3 mx-4">
                <div className="card-header">
                    <p className="text-primary m-0 fw-bold d-inline">Property Documents Information</p>
                    <button className="btn btn-primary text-end float-end btn-sm" onClick={() => {
                        setAddModal(true)
                    }}>Add New Document
                    </button>
                </div>
                <div className="card-body">
                    <div className="row g-3">
                        <div className='col-md-11'>
                            <input type="text" className="form-control" placeholder="Search Document Name!"
                                   aria-label="Search"
                                   aria-describedby="basic-addon2" value={searchTerm}
                                   onChange={e => setSearchTerm(e.target.value)}/>
                        </div>
                        <div className='col-md'>
                            <select className="form-control" value={pageSize} onChange={e => {
                                setPageSize(Number(e.target.value));
                                setPageNumber(0); // Reset the page number when the page size changes
                            }}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                    </div>

                    <div className="table-responsive table mt-2" id="dataTable" role="grid"
                         aria-describedby="dataTable_info">
                        <table className="table my-0" id="dataTable">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>File</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody className='table-group-divider'>
                            {data.length === 0 ? (<tr>
                                    <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                                </tr>) : (data.map((data) => (<tr key={data.id}>
                                    <td>{data.name}</td>
                                    <td> {renderFileLink(data.file)}</td>
                                    <td>
                                        <button className="btn btn-danger btn-sm mx-1"
                                                onClick={() => handleDeleteFile(data.id)}>
                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                        </button>
                                    </td>
                                </tr>)))}
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        containerClassName="pagination justify-content-center mt-3"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        breakClassName="page-item"
                        disabledClassName="disabled"
                    />
                </div>
            </div>

            <Modal
                size="lg"
                show={showAddModal}
                onHide={() => setAddModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add New Document
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddData} encType="multipart/form-data">
                        <label className="form-label">Document Name</label>
                        <input className="form-control" type="text" name="document_name" id="document_name"
                               placeholder="Enter Document Name"
                               required/>
                        <label className="form-label">File (If PDF File Compress it here: <a
                            href='https://www.ilovepdf.com/compress_pdf' target='_blank'>Compressor</a>)</label>
                        <input className="form-control" type="file" name="document_file" id="document_file" required/>
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Document Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Name:</strong> {formData.document_name}</p>
                    {formData.document_file_name && (<>
                            <p><strong>File:</strong> {formData.document_file_name}</p>
                            {formData.document_file_name.toLowerCase().endsWith('.pdf') || formData.document_file_name.toLowerCase().endsWith('.doc') || formData.document_file_name.toLowerCase().endsWith('.docx') ? (
                                <iframe src={fileUrl} title="Document Preview" width="100%"
                                        height="500px"></iframe>) : (
                                <img src={fileUrl} alt="Document Preview" style={{maxWidth: '100%', height: 'auto'}}/>)}
                        </>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setAddConfirmModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setAddConfirmModal(false);
                        handleAddData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

        </>)
}

export default PropertyDocs