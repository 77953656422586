import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../../others/Loading";
import ReactPaginate from "react-paginate";
import {Button, Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";

function PayrollHistory() {
    const token = localStorage.getItem("token");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true)

    const [data, setData] = useState([])

    const navigate = useNavigate();

    const {id} = useParams();
    const {agent_name} = useParams();

    const [showAddImageModal, setShowAddImageModal] = useState(false);
    const [previewImageUrl, setPreviewImageUrl] = useState(null);
    const [history_id, setHistoryId] = useState([])


    const [selectedImageFile, setSelectedImageFile] = useState(null);

    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.role;
    }

    const userRole = getUserRole(token);

    function getUserId(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.id;
    }

    const userId = getUserId(token);


    const pageCount = Math.ceil(total / pageSize);
    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/payroll-history/${id}?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pageNumber, pageSize, searchTerm, token, id])

    const handleAddImage = async () => {
        if (!selectedImageFile) {
            return;
        }

        const formData = new FormData();
        formData.append("image", selectedImageFile);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/add/payroll-history-voucher-signature/${history_id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data',
                }
            });

            setSelectedImageFile(null);
            setShowAddImageModal(false)
            toast.success('Voucher with signature added successfully.');
        } catch (error) {
            console.error('Error adding image:', error);
            toast.error('An error occurred while adding the image.');
        }
    };

    function handlePayrollHistoryVoucher(id) {
        navigate(`/portal/print-voucher-history/${id}`);
    }

    function handleAddPayrollHistoryVoucher(id) {
        setHistoryId(id)
        setShowAddImageModal(true)
    }

    function openVoucher(fileName) {
        // Construct the URL of the image based on its filename
        const imageUrl = `${process.env.REACT_APP_API_URL}/uploaded_img/${fileName}`;

        // Open the image in a new tab
        window.open(imageUrl, '_blank');
    }


    if (isLoading) {
        return (<Loading/>);
    }

    return (<>
            <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Commission History
                of {agent_name}</h3>
            <div className="card shadow border-primary mb-3 mx-4">
                <div className="card-header">
                    <p className="text-primary m-0 fw-bold d-inline">Commission History Information</p>
                </div>
                <div className="card-body">
                    <div className="row g-3">

                    </div>

                    <div className="table-responsive table mt-2" id="dataTable" role="grid"
                         aria-describedby="dataTable_info">
                        <table className="table my-0" id="dataTable">
                            <thead>
                            <tr>
                                <th>Total Amount</th>
                                <th>Net Amount</th>
                                <th>Method Of Payment</th>
                                <th>Cheque No.</th>
                                <th>Pay To</th>
                                <th>Remarks</th>
                                <th>Date</th>
                                {userRole === 1 || userRole === 2 ? (<th>Action</th>) : null}
                            </tr>
                            </thead>
                            <tbody className='table-group-divider'>
                            {data.length === 0 ? (<tr>
                                    <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                                </tr>) : (data.map((data) => (<tr key={data.id}>
                                <td>{data.total_amount}</td>
                                <td>{data.amount}</td>
                                <td>{data.method_of_payment}</td>
                                <td>{data.cheque_no}</td>
                                <td>{data.pay_to}</td>
                                <td>{data.remarks}</td>
                                <td>{data.created_date}</td>
                                {userRole === 1 || userRole === 2 ? (<td>
                                    <button className="btn btn-danger btn-sm mx-1"
                                            onClick={() => handleAddPayrollHistoryVoucher(data.id)}>
                                        <i className="fa fa-camera" aria-hidden="true"></i>
                                    </button>
                                    {data.voucher ? (<button
                                        className="btn btn-primary btn-sm mx-1"
                                        onClick={() => openVoucher(data.voucher)}
                                    >
                                        <i className="fa fa-file" aria-hidden="true"></i>
                                    </button>) : (<button className="btn btn-primary btn-sm mx-1" disabled>
                                        <i className="fa fa-file" aria-hidden="true"></i>
                                    </button>)}

                                    <button className="btn btn-warning btn-sm mx-1"
                                            onClick={() => handlePayrollHistoryVoucher(data.id)}>
                                        <i className="fa fa-print" aria-hidden="true"></i>
                                    </button>
                                </td>) : null}
                            </tr>)))}
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        containerClassName="pagination justify-content-center mt-3"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        breakClassName="page-item"
                        disabledClassName="disabled"
                    />
                </div>
            </div>

            <Modal show={showAddImageModal} onHide={() => {
                setShowAddImageModal(false);
                setSelectedImageFile(null);
                setPreviewImageUrl(null); // Clear the preview URL when closing the modal
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Payroll Voucher with Signature</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input className="form-control" type="file" accept=".jpeg, .jpg, .png" required onChange={(e) => {
                        setSelectedImageFile(e.target.files[0]);
                        setPreviewImageUrl(URL.createObjectURL(e.target.files[0])); // Set the preview URL
                    }}/>
                    {selectedImageFile && (<div className='mt-3'>
                            <img src={previewImageUrl} style={{maxWidth: '100%'}}/>
                        </div>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleAddImage} disabled={!selectedImageFile}>
                        Add Image
                    </Button>
                    <Button variant="secondary" onClick={() => {
                        setShowAddImageModal(false);
                        setSelectedImageFile(null);
                        setPreviewImageUrl(null);
                    }}>
                        Cancel
                    </Button>

                </Modal.Footer>
            </Modal>
        </>)
}

export default PayrollHistory