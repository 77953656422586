import {useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import Loading from "../../others/Loading";
import ReactToPrint from "react-to-print";

function PrintIncentivesVoucherHistory() {
    const token = localStorage.getItem("token");
    const {id} = useParams();
    const printContentRef = useRef()
    const [isLogo, setLogo] = useState('')
    const [agent, setAgent] = useState([])
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/incentives-voucher-history/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setAgent(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [id, token])

    console.log(agent)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/realty`)
            .then(response => {
                setLogo(`${process.env.REACT_APP_API_URL}/uploaded_img/${response.data.logo}`)
            })
            .catch(error => {
                console.log(error)
            })
    },)


    const PrintContent = React.forwardRef((_, ref) => (<div ref={ref}>
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '10px'}}>
            <img
                src={isLogo}
                alt="Loading..."
                style={{
                    width: '200px', height: '200px', // You can add other styles here as needed
                }}
            />
        </div>
        <div className="container">
            <div className="row"
                 style={{
                     borderWidth: 1, borderStyle: 'solid'
                 }}>
                <div className="col-md-6 col-xl-3">
                    <h4>DATE</h4>
                    <h4>REALTY'S NAME</h4>
                </div>
                <div className="col-md-6 col-xl-6">
                    <h4>: {agent.created_date}</h4>
                    <h4>: {agent.incentivesdetails.user.first_name}</h4>
                </div>
                <div className='col-md-6 col-xl-3 text-end'>
                    <h5>IV NO. {agent.id}</h5>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row"
                 style={{
                     borderRightWidth: 1,
                     borderRightStyle: 'solid',
                     borderBottomWidth: 1,
                     borderBottomStyle: 'solid',
                     borderLeftWidth: 1,
                     borderLeftStyle: 'solid'
                 }}>
                <div className="col-md-3 col-xl-5">
                    <h6 className="text-center">BUYER'S NAME</h6>
                </div>
                <div className="col-md-3 col-xl-2">
                    <h6 className="text-center">PROJECT - BLK & LT</h6>
                </div>
                <div className="col-xl-2">
                    <h6 className="text-center">TOTAL INCENTIVES AMOUNT</h6>
                </div>
                <div className="col-xl">
                    <h6 className="text-center">NET TOTAL INCENTIVES</h6>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row"
                 style={{
                     borderRightWidth: 1,
                     borderRightStyle: 'solid',
                     borderBottomWidth: 1,
                     borderBottomStyle: 'solid',
                     borderLeftWidth: 1,
                     borderLeftStyle: 'solid'
                 }}>
                <div className="col-md-3 col-xl-5" style={{borderRightWidth: 1, borderRightStyle: 'solid'}}>
                    <h6 className="text-center">{agent.incentivesdetails.incentives.transaction.buyer.name}</h6>
                </div>
                <div className="col-md-3 col-xl-2" style={{borderRightWidth: 1, borderRightStyle: 'solid'}}>
                    <h6 className="text-center">{agent.incentivesdetails.incentives.transaction.propertyowner.name} - {agent.incentivesdetails.incentives.transaction.property.name} - {agent.incentivesdetails.incentives.transaction.propertyblocklot.name}</h6>
                </div>
                <div className="col-xl-2" style={{borderRightWidth: 1, borderRightStyle: 'solid'}}>
                    <h6 className="text-end">{agent.total_amount}</h6>
                </div>
                <div className="col-xl">
                    <h6 className="text-end">{agent.amount}</h6>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row"
                 style={{
                     borderRightWidth: 1,
                     borderRightStyle: 'solid',
                     borderBottomWidth: 1,
                     borderBottomStyle: 'solid',
                     borderLeftWidth: 1,
                     borderLeftStyle: 'solid'
                 }}>
                <div className="col-md-3 col-xl-5" style={{borderRightWidth: 1, borderRightStyle: 'solid'}}>
                    <h6 className="text-end">TOTAL</h6>
                </div>
                <div className="col-xl-2" style={{borderRightWidth: 1, borderRightStyle: 'solid'}}>
                    <h6 className="text-end"></h6>
                </div>
                <div className="col-xl-2" style={{borderRightWidth: 1, borderRightStyle: 'solid'}}>
                    <h6 className="text-end">{agent.total_amount}</h6>
                </div>
                <div className="col">
                    <h6 className="text-end">{agent.amount}</h6>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row"
                 style={{
                     borderRightWidth: 1,
                     borderRightStyle: 'solid',
                     borderBottomWidth: 1,
                     borderBottomStyle: 'solid',
                     borderLeftWidth: 1,
                     borderLeftStyle: 'solid'
                 }}>
                <div className="col-md-3 col-xl-5" style={{borderRightWidth: 1, borderRightStyle: 'solid'}}>
                    <h6 className="text-start">METHOD OF PAYMENT: {agent.method_of_payment}</h6>
                    <h6 className="text-start">Cheque No.: {agent.cheque_no}</h6>
                    <h6 className="text-start">Pay To: {agent.pay_to}</h6>
                </div>
                <div className="col-md-3 col-xl">
                    <h6 className="text-start">BEING:</h6>
                    <h6 className="text-start">{agent.remarks}</h6>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row"
                 style={{
                     borderRightWidth: 1,
                     borderRightStyle: 'solid',
                     borderBottomWidth: 1,
                     borderBottomStyle: 'solid',
                     borderLeftWidth: 1,
                     borderLeftStyle: 'solid'
                 }}>
                <div className="col-md-6 col-xl">
                    <h6>PREPARED BY:</h6>
                    <h6 style={{marginTop: 20}}>{agent.prepared_by}</h6>
                </div>
                <div className="col-md-6 col-xl">
                    <h6>PAID BY:</h6>
                    <h6 style={{marginTop: 20}}>ROHE PROPERTY DEVELOPMENT</h6>
                </div>
                <div className="col-md-6 col-xl ">
                    <h6>RECEIVED BY:</h6>
                    <h6>WITH ATTACHED BIR FORM 2307</h6>
                    <h6 style={{marginTop: 20}}>_____________________________________</h6>
                </div>
                <div className="col-md-6 col-xl ">
                    <img
                        src={isLogo}
                        alt="Loading..."
                        style={{
                            width: '100px', height: '100px', marginBottom: '5px', marginTop: '5px'
                        }}
                    />
                </div>
            </div>
        </div>
    </div>));

    if (isLoading) {
        return (<Loading/>);
    }
    return (
        <>
            <div className='m-4 text-center'>
                <ReactToPrint
                    trigger={() => <button className='btn btn-primary'>Print</button>}
                    content={() => printContentRef.current}
                />
            </div>


            <PrintContent ref={printContentRef}/>
        </>
    )
}

export default PrintIncentivesVoucherHistory;