import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../others/Loading";
import ReactPaginate from "react-paginate";
import {useNavigate} from "react-router-dom";

function Payroll() {
    const token = localStorage.getItem("token");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true)

    const [data, setData] = useState([])

    const navigate = useNavigate();

    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.role;
    }

    const userRole = getUserRole(token);

    function getUserId(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.id;
    }

    const userId = getUserId(token);


    const pageCount = Math.ceil(total / pageSize);
    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/payroll?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}&userRole=${userRole}&userId=${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pageNumber, pageSize, searchTerm, token, userId, userRole])

    function handlePayrollDetails(id, property_name) {
        const encodedPropertyName = encodeURIComponent(property_name);
        navigate(`/portal/payroll-details/${id}/${encodedPropertyName}`);
    }

    function handlePayrollHistory(id, agent_last_name, agent_first_name, agent_suffix) {
        const agent_name = encodeURIComponent(`${agent_first_name}`);
        navigate(`/portal/payroll-history/${id}/${agent_name}`);
    }


    if (isLoading) {
        return (<Loading/>);
    }

    return (<>
            <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Commission</h3>
            <div className="card shadow border-primary mb-3 mx-4">
                <div className="card-header">
                    <p className="text-primary m-0 fw-bold d-inline">Commission Information</p>
                </div>
                <div className="card-body">
                    <div className="row g-3">
                        <div className='col-md-11'>
                            <input type="text" className="form-control"
                                   placeholder="Search Property Name or Realty Name!"
                                   aria-label="Search"
                                   aria-describedby="basic-addon2" value={searchTerm}
                                   onChange={e => setSearchTerm(e.target.value)}/>
                        </div>
                        <div className='col-md'>
                            <select className="form-control" value={pageSize} onChange={e => {
                                setPageSize(Number(e.target.value));
                                setPageNumber(0); // Reset the page number when the page size changes
                            }}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                    </div>
                    {userRole === 1 || userRole === 2 ? (
                        <div className="table-responsive table mt-2" id="dataTable" role="grid"
                             aria-describedby="dataTable_info">
                            <table className="table my-0" id="dataTable">
                                <thead>
                                <tr>
                                    <th>Project Name</th>
                                    <th>Property Name</th>
                                    <th>Block and Lot</th>
                                    <th>Realty's Name</th>
                                    <th>Total Commission</th>
                                    <th>Disbursed Commission</th>
                                    <th>Remaining Commission</th>
                                    <th>Last date of Disbursement</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody className='table-group-divider'>
                                {data.length === 0 ? (<tr>
                                        <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                                    </tr>) : (data.map((data) => (<tr key={data.id}>
                                    <td>{data.transaction.propertyowner.name}</td>
                                    <td>{data.transaction.property.name}</td>
                                    <td>{data.transaction.propertyblocklot.name}</td>
                                    <td>{data.transaction.user.first_name}</td>
                                    <td>{data.total_commission}</td>
                                    <td>{data.total_amount}</td>
                                    <td>{data.total_disbursed}</td>
                                    <td>{data.most_recent_history_created_date}</td>
                                    <td>{data.status_name}</td>
                                    <td>
                                        <button className="btn btn-primary btn-sm mx-1"
                                                onClick={() => handlePayrollDetails(data.id, data.transaction.property.name)}>
                                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                                        </button>
                                    </td>
                                </tr>)))}
                                </tbody>
                            </table>
                        </div>) : <div className="table-responsive table mt-2" id="dataTable" role="grid"
                                       aria-describedby="dataTable_info">
                        <table className="table my-0" id="dataTable">
                            <thead>
                            <tr>
                                <th>Realty's Name</th>
                                <th>Project Name</th>
                                <th>Property Name</th>
                                <th>Block and Lot</th>
                                <th>Commission</th>
                                <th>Disbursed Commission</th>
                                <th>Remaining Commission</th>
                                <th>Last date of Disbursement</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody className='table-group-divider'>
                            {data.length === 0 ? (<tr>
                                    <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                                </tr>) : (data.map((data) => (<tr key={data.id}>
                                <td>{data.user.first_name}</td>
                                <td>{data.payroll.transaction.propertyowner.name}</td>
                                <td>{data.payroll.transaction.property.name}</td>
                                <td>{data.payroll.transaction.propertyblocklot.name}</td>
                                <td>{data.commission}</td>
                                <td>{data.total_amount}</td>
                                <td>{data.total_disbursed}</td>
                                <td>{data.most_recent_history_created_date}</td>
                                <td>
                                    <button className="btn btn-primary btn-sm mx-1"
                                            onClick={() => handlePayrollHistory(data.id, data.user.last_name, data.user.first_name, data.user.suffix)}>
                                        <i className="fa fa-history" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>)))}
                            </tbody>
                        </table>
                    </div>}
                    <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        containerClassName="pagination justify-content-center mt-3"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        breakClassName="page-item"
                        disabledClassName="disabled"
                    />
                </div>
            </div>

        </>)
}

export default Payroll