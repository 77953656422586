import React from "react";
import "../../assets/css/lightbox.css";

const Lightbox = ({ imageUrl, onClose }) => {
  return (
    <div className="lightbox-overlay" onClick={onClose}>
      <div className="lightbox-content">
        <span className="lightbox-close" onClick={onClose}>
          &times;
        </span>
        <img src={imageUrl} alt="Enlarged Image" className="lightbox-image" />
      </div>
    </div>
  );
};

export default Lightbox;