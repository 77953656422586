import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../others/Loading";
import ReactPaginate from "react-paginate";
import {Button, Modal} from "react-bootstrap";
import {PatternFormat} from "react-number-format";
import {toast} from "react-toastify";
import {AsyncTypeahead} from "react-bootstrap-typeahead";

function ManageSalesPerson() {
    const token = localStorage.getItem("token");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [formData, setFormData] = useState({});
    const [showAddModal, setAddModal] = useState(false);
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);
    const [isModifying, setModifying] = useState(false);

    const [selectedSalesmanager, setSelectedSalesmanager] = useState([]);
    const [salesmanagers, setSalesmanagers] = useState([]);
    const [isAsyncLoading, setAsyncLoading] = useState(false);

    const [update_id, setUpdateId] = useState('')
    const [showEditModal, setEditModal] = useState(false);
    const [showEditConfrimModal, setEditConfirmModal] = useState(false);

    const [deleteDataId, setDeleteDataId] = useState(null);
    const [deleteDataName, setDeleteDataName] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.role;
    }

    const userRole = getUserRole(token);

    function getUserId(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.id;
    }

    const userId = getUserId(token);

    const pageCount = Math.ceil(total / pageSize);
    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/salespersons?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}&userRole=${userRole}&userId=${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pageNumber, pageSize, searchTerm, token, userRole, userId])

    const searchSalesmanager = async (query) => {
        setAsyncLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/salesmanagers/typeahead?search=${query}&limit=10`, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            const data = response.data;
            setSalesmanagers(data.map((data) => ({
                labelKey: `${data.user.last_name}, ${data.user.first_name} ${data.user.middle_name} ${data.user.suffix}`,
                id: data.id
            })));
        } catch (error) {
            console.error('Error fetching Salesmanager:', error);
        }
        setAsyncLoading(false);
    };

    function confirmAddData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            first_name: formData.get("first_name"),
            middle_name: formData.get("middle_name"),
            last_name: formData.get("last_name"),
            suffix: formData.get("suffix"),
            cnum: formData.get("cnum"),
            email: formData.get("email"),
            com_rate: formData.get("com_rate"),
            with_tax: formData.get("with_tax"),
            bus_tax: formData.get("bus_tax"),
            salesmanager_name: selectedSalesmanager[0].labelKey,
            salesmanager_id: selectedSalesmanager[0].id,
        };

        setFormData(data);
        setAddConfirmModal(true);
    }

    function handleAddData() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/salesperson`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const newData = response.data.new_data;
                    setData(prevData => [newData, ...prevData]);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setModifying(false)
                setAddModal(false)
                setSelectedSalesmanager([])
            })
    }

    function confirmEditData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            first_name: formData.get("first_name"),
            middle_name: formData.get("middle_name"),
            last_name: formData.get("last_name"),
            suffix: formData.get("suffix"),
            phone: formData.get("phone"),
            com_rate: formData.get("com_rate"),
            withholding_tax: formData.get("withholding_tax"),
            business_tax: formData.get("business_tax"),
            salesmanager_id: selectedSalesmanager[0].id,
            salesmanager_name: selectedSalesmanager[0].labelKey,
        };

        setFormData(data);
        setEditConfirmModal(true);
    }

    function handleEditData() {
        axios.put(`${process.env.REACT_APP_API_URL}/update/salesperson/${update_id}`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const updatedData = response.data.updated_data;
                    const updatedIndex = data.findIndex(item => item.id === updatedData.id);
                    const updatedData2 = [...data];
                    updatedData2[updatedIndex] = updatedData;
                    setData(updatedData2);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setEditModal(false)
                setUpdateId('')
                setSelectedSalesmanager([])
            })
    }

    function confirmDeleteData(id, first_name, middle_name, last_name, suffix) {
        setDeleteDataId(id);
        setDeleteDataName(`${last_name}, ${first_name} ${middle_name} ${suffix}`);
        setShowDeleteModal(true);
    }

    function handleDeleteData(id) {
        fetch(`${process.env.REACT_APP_API_URL}/delete/salesperson/${id}`, {
            method: 'PUT', headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                const deletedData = data.filter(item => item.id !== id);
                setData(deletedData);
                toast.success('Salesperson deactivated successfully.');
            })
            .catch(error => {
                console.error(error);
                toast.error('An error occurred while deleting data.');
            });
    }

    if (isLoading) {
        return (<Loading/>);
    }

    return (<>
            <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Manage Salesperson</h3>
            <div className="card shadow border-primary mb-3 mx-4">
                <div className="card-header">
                    <p className="text-primary m-0 fw-bold d-inline">Salesperson Information</p>
                    {userRole === 1 || userRole === 2 ? (
                        <button className="btn btn-primary text-end float-end btn-sm" onClick={() => {
                            setAddModal(true)
                        }}>Add New Salesperson
                        </button>) : null}
                </div>
                <div className="card-body">
                    <div className="row g-3">
                        <div className='col-md-11'>
                            <input type="text" className="form-control"
                                   placeholder="Search Last Name or Email or Phone number!"
                                   aria-label="Search"
                                   aria-describedby="basic-addon2" value={searchTerm}
                                   onChange={e => setSearchTerm(e.target.value)}/>
                        </div>
                        <div className='col-md'>
                            <select className="form-control" value={pageSize} onChange={e => {
                                setPageSize(Number(e.target.value));
                                setPageNumber(0); // Reset the page number when the page size changes
                            }}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                    </div>

                    <div className="table-responsive table mt-2" id="dataTable" role="grid"
                         aria-describedby="dataTable_info">
                        <table className="table my-0" id="dataTable">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Contact Number</th>
                                <th>Email</th>
                                <th>Commission Rate</th>
                                <th>Withholding Tax</th>
                                <th>Business Tax</th>
                                <th>Salesmanager</th>
                                {userRole === 1 || userRole === 2 ? (<th>Action</th>) : null}
                            </tr>
                            </thead>
                            <tbody className='table-group-divider'>
                            {data.length === 0 ? (<tr>
                                    <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                                </tr>) : (data.map((data) => (<tr key={data.id}>
                                    <td>{data.user.last_name}, {data.user.first_name} {data.user.middle_name} {data.user.suffix}</td>
                                    <td>{data.phone}</td>
                                    <td>{data.user.email}</td>
                                    <td>{data.com_rate}</td>
                                    <td>{data.withholding_tax}</td>
                                    <td>{data.business_tax}</td>
                                    <td>{data.salesmanager.user.last_name}, {data.salesmanager.user.first_name} {data.salesmanager.user.middle_name} {data.salesmanager.user.suffix}</td>
                                    {userRole === 1 || userRole === 2 ? (<td>
                                            <button className="btn btn-warning btn-sm mx-1" onClick={() => {
                                                setUpdateId(data.id)
                                                const selectedSalesmanager = [{
                                                    id: data.salesmanager.id,
                                                    labelKey: `${data.salesmanager.user.last_name}, ${data.salesmanager.user.first_name} ${data.salesmanager.user.middle_name} ${data.salesmanager.user.suffix}`,
                                                }];
                                                setFormData({
                                                    first_name: data.user.first_name,
                                                    middle_name: data.user.middle_name,
                                                    last_name: data.user.last_name,
                                                    suffix: data.user.suffix,
                                                    phone: data.phone,
                                                    com_rate: data.com_rate,
                                                    withholding_tax: data.withholding_tax,
                                                    business_tax: data.business_tax,
                                                });
                                                setSelectedSalesmanager(selectedSalesmanager);
                                                setEditModal(true)
                                            }}><i className='fas fa-edit'></i></button>
                                            <button className="btn btn-danger btn-sm mx-1"
                                                    onClick={() => confirmDeleteData(data.id, data.user.first_name, data.user.middle_name, data.user.last_name, data.user.suffix)}>
                                                <i
                                                    className='fas fa-trash-alt'></i></button>
                                        </td>) : null}
                                </tr>)))}
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        containerClassName="pagination justify-content-center mt-3"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        breakClassName="page-item"
                        disabledClassName="disabled"
                    />
                </div>
            </div>

            <Modal
                size="lg"
                show={showAddModal}
                onHide={() => setAddModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add New Salesperson
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddData}>
                        <label className="form-label">First Name</label>
                        <input className="form-control" type="text" name="first_name" id="first_name"
                               placeholder="Enter First Name"
                               required/>
                        <label className="form-label">Middle Name</label>
                        <input className="form-control" type="text" name="middle_name" id="middle_name"
                               placeholder="Enter Middle Name"
                               required/>
                        <label className="form-label">Last Name</label>
                        <input className="form-control" type="text" name="last_name" id="last_name"
                               placeholder="Enter Last Name"
                               required/>
                        <label className="form-label">Suffix (optional)</label>
                        <input className="form-control" type="text" name="suffix" id="suffix"
                               placeholder="Enter Suffix (eg. Sr / Jr)"
                        />
                        <label className="form-label">Contact Number</label>
                        <PatternFormat className="form-control" name="cnum" id="cnum"
                                       format="#### ### ####"
                                       allowEmptyFormatting mask="_"
                                       required/>
                        <label className="form-label">Email</label>
                        <input className="form-control" type="email" name="email" id="email"
                               required/>
                        <label className="form-label">Commission Rate (%)</label>
                        <input className="form-control" type="number" name="com_rate" id="com_rate"
                               required step="0.01" lang="en"/>
                        <label className="form-label">Withholding Tax (%)</label>
                        <input className="form-control" type="number" name="with_tax" id="with_tax"
                               required step="0.01" lang="en"/>
                        <label className="form-label">Business Tax (%)</label>
                        <input className="form-control" type="number" name="bus_tax" id="bus_tax"
                               required step="0.01" lang="en"/>
                        <label className="form-label">Salesmanager</label>
                        <AsyncTypeahead
                            id="employee_id"
                            labelKey="labelKey"
                            isLoading={isAsyncLoading}
                            onSearch={searchSalesmanager}
                            options={salesmanagers}
                            onChange={setSelectedSalesmanager}
                            placeholder="Type Salesmanager Name"
                            selected={selectedSalesmanager}
                            inputProps={{ required: true }}
                        />
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Salesperson Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Name:</strong> {formData.last_name}, {formData.first_name} {formData.middle_name} {formData.suffix}
                    </p>
                    <p><strong>Contact Number:</strong> {formData.cnum}</p>
                    <p><strong>Email:</strong> {formData.email}</p>
                    <p><strong>Commission Rate:</strong> {formData.com_rate}</p>
                    <p><strong>Withholding Tax:</strong> {formData.with_tax}</p>
                    <p><strong>Business Tax:</strong> {formData.bus_tax}</p>
                    <p><strong>Salesmanager:</strong> {formData.salesmanager_name}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setAddConfirmModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setAddConfirmModal(false);
                        handleAddData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="lg"
                show={showEditModal}
                onHide={() => setEditModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Edit Salesperson Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmEditData}>
                        <label className="form-label">First Name</label>
                        <input className="form-control" type="text" name="first_name" id="first_name"
                               value={formData.first_name}
                               onChange={(e) => setFormData({...formData, first_name: e.target.value})}
                               required/>
                        <label className="form-label">Middle Name</label>
                        <input className="form-control" type="text" name="middle_name" id="middle_name"
                               value={formData.middle_name}
                               required
                               onChange={(e) => setFormData({...formData, middle_name: e.target.value})}
                        />
                        <label className="form-label">Last Name</label>
                        <input className="form-control" type="text" name="last_name" id="last_name"
                               value={formData.last_name}
                               onChange={(e) => setFormData({...formData, last_name: e.target.value})}
                               required/>
                        <label className="form-label">Suffix (optional)</label>
                        <input className="form-control" type="text" name="suffix" id="suffix"
                               value={formData.suffix}
                               onChange={(e) => setFormData({...formData, suffix: e.target.value})}
                        />
                        <label className="form-label">Phone Number</label>
                        <PatternFormat className="form-control" name="phone" id="phone"
                                       value={formData.phone}
                                       onChange={(e) => setFormData({...formData, phone: e.target.value})}
                                       format="#### ### ####"
                                       required
                                       allowEmptyFormatting mask="_"/>
                        <label className="form-label">Commission Rate (%)</label>
                        <input className="form-control" type="number" name="com_rate" id="com_rate"
                               value={formData.com_rate}
                               onChange={(e) => setFormData({...formData, com_rate: e.target.value})}
                               required step="0.01" lang="en"/>
                        <label className="form-label">Withholding Tax (%)</label>
                        <input className="form-control" type="number" name="withholding_tax" id="withholding_tax"
                               value={formData.withholding_tax}
                               onChange={(e) => setFormData({...formData, withholding_tax: e.target.value})}
                               required step="0.01" lang="en"/>
                        <label className="form-label">Business Tax (%)</label>
                        <input className="form-control" type="number" name="business_tax" id="business_tax"
                               value={formData.business_tax}
                               onChange={(e) => setFormData({...formData, business_tax: e.target.value})}
                               required step="0.01" lang="en"/>
                        <label className="form-label">Salesmanager</label>
                        <AsyncTypeahead
                            id="id"
                            labelKey="labelKey"
                            isLoading={isAsyncLoading}
                            onSearch={searchSalesmanager}
                            options={salesmanagers}
                            onChange={setSelectedSalesmanager}
                            placeholder="Type Salesmanager Name"
                            selected={selectedSalesmanager}
                            required
                        />
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Update"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showEditConfrimModal} onHide={() => setEditConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Salesperson Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>First Name:</strong> {formData.first_name}</p>
                    <p><strong>Middle Name:</strong> {formData.middle_name}</p>
                    <p><strong>Last Name:</strong> {formData.last_name}</p>
                    <p><strong>Suffix:</strong> {formData.suffix}</p>
                    <p><strong>Phone Number:</strong> {formData.phone}</p>
                    <p><strong>Commission Rate:</strong> {formData.com_rate}</p>
                    <p><strong>Withholding Tax:</strong> {formData.withholding_tax}</p>
                    <p><strong>Business Tax:</strong> {formData.business_tax}</p>
                    <p><strong>Salesmanager:</strong> {formData.salesmanager_name}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setEditConfirmModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setEditConfirmModal(false);
                        handleEditData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Deactivate Salesperson</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to deactivate {deleteDataName}?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => {
                        handleDeleteData(deleteDataId);
                        setShowDeleteModal(false);
                    }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

        </>)
}

export default ManageSalesPerson