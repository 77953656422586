import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import '../../assets/css/printing.css'
import ReactToPrint from "react-to-print";
import {DownloadTableExcel} from "react-export-table-to-excel";
import PrintContent from "./printPages/PrintContent";
import {Button, Modal} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import {NumericFormat} from "react-number-format";
import {toast} from "react-toastify";

function ExpenseLedger() {
    const token = localStorage.getItem("token");
    const [total, setTotal] = useState(0);

    const printContentRef = useRef()
    const tableRef = useRef(null);

    const [data, setData] = useState([])
    const [formData, setFormData] = useState({});
    const [showAddModal, setAddModal] = useState(false);
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);
    const [isModifying, setModifying] = useState(false);

    const [formData3, setFormData3] = useState({});
    const [showAddPropertyTypeModal, setAddPropertyTypeModal] = useState(false);
    const [showAddConfrimPropertyTypeModal, setAddConfirmPropertyTypeModal] = useState(false);
    const [isModifying3, setModifying3] = useState(false);

    const [selectedPropertyType, setSelectedPropertyType] = useState([]);
    const [propertyType, setPropertyType] = useState([]);

    const [loading, setLoading] = useState(false);

    // Define initial start and end dates
    const currentDate = new Date();
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    startOfMonth.setDate(startOfMonth.getDate() + 1);
    endOfMonth.setDate(endOfMonth.getDate() + 1);
    // Define state variables for start and end dates
    const [startDate, setStartDate] = useState(startOfMonth);
    const [endDate, setEndDate] = useState(endOfMonth);


    const navigate = useNavigate();

    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.role;
    }

    const userRole = getUserRole(token);

    function getUserId(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.id;
    }

    const userId = getUserId(token);

    const formatDateToString = (date) => {
        return date.toISOString().split('T')[0];
    };

    useEffect(() => {
        // Format the start and end dates
        const formattedStartDate = formatDateToString(startDate);
        const formattedEndDate = formatDateToString(endDate);
        axios.get(`${process.env.REACT_APP_API_URL}/expense-ledger?startDate=${formattedStartDate}&endDate=${formattedEndDate}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                setData(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [startDate, endDate, token])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/expense-category/typeahead`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                const data = response.data;
                setPropertyType(data.map((data) => ({
                    labelKey: `${data.name}`,
                    id: data.id
                })))
            })
            .catch(error => {
                console.log(error)
            })
    }, [token])

    function confirmAddData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            category_name: selectedPropertyType[0].labelKey,
            category_id: selectedPropertyType[0].id,
            date: formData.get("date"),
            description: formData.get("description"),
            amount: formData.get("amount"),
        };
        setFormData(data);
        setAddConfirmModal(true);
    }

    function handleAddData() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/expense-ledger`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const newData = response.data.new_data;
                    setData(prevData => [newData, ...prevData]);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setModifying(false)
                setAddModal(false)
            })
    }

    function confirmAddDataPropertyType(event) {
        event.preventDefault()
        setModifying3(true)

        const formData = new FormData(event.target);

        const data = {
            name: formData.get("name"),
            description: formData.get("description"),
        };
        setFormData3(data);
        setAddConfirmPropertyTypeModal(true);
    }

    function handleAddDataPropertyType() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/expense-category`, formData3, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const newData = response.data.new_data;
                    setPropertyType(prevData => [newData, ...prevData]);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setModifying3(false)
                setAddPropertyTypeModal(false)
                setAddModal(true)
            })
    }

    return (
        <>
            <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Expense Ledger</h3>
            <div className="mb-3 mx-4">
                <div className='row mb-2'>
                    <div className='row'>
                        <div className='col'>
                            <input
                                type="date"
                                className="form-control"
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                                value={formatDateToString(startDate)} // Format the Date object as a string
                                onChange={e => setStartDate(new Date(e.target.value))}
                            />
                        </div>
                        -
                        <div className='col'>
                            <input
                                type="date"
                                className="form-control"
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                                value={formatDateToString(endDate)} // Format the Date object as a string
                                onChange={e => setEndDate(new Date(e.target.value))}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-3 mx-4">
                <ReactToPrint
                    trigger={() => <button className="btn btn-warning btn-sm mx-1">Print</button>}
                    content={() => printContentRef.current}
                />
                <DownloadTableExcel
                    filename="exported_data"
                    sheet="Exported_Data"
                    currentTableRef={tableRef.current}
                >
                    <button className="btn btn-warning btn-sm mx-1"> Export to Excel</button>
                </DownloadTableExcel>
                <button className="btn btn-primary text-end float-end btn-sm" onClick={() => {
                    setAddModal(true)
                }}>Add New Expense
                </button>
                {loading ? (
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : (
                    <PrintContent ref={printContentRef}>
                        <table className="table my-0" id="dataTable" ref={tableRef}>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Category</th>
                                <th>Description</th>
                                <th>Amount</th>
                            </tr>
                            </thead>
                            <tbody className='table-group-divider'>
                            {data.map((data) => (
                                <tr key={data.id}>
                                    <td>{data.date}</td>
                                    <td>{data.expensecategory.name}</td>
                                    <td>{data.description}</td>
                                    <td>{data.amount}</td>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td></td>
                                <td></td>
                                <td>Total:</td>
                                <td>{data.reduce((total, data) => total + parseFloat(data.amount.replace(/,/g, '')), 0).toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}</td>
                            </tr>
                            </tfoot>
                        </table>
                    </PrintContent>
                )}
            </div>

            <Modal
                size="lg"
                show={showAddModal}
                onHide={() => setAddModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add New Expense
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <button className="btn btn-primary btn-sm" onClick={() => {
                        setAddPropertyTypeModal(true)
                        setAddModal(false)
                    }}>Add New Expense Category
                    </button>
                    <hr></hr>
                    <form onSubmit={confirmAddData}>
                        <label className="form-label">Category</label>
                        <Typeahead
                            id="id"
                            labelKey="labelKey"
                            options={propertyType}
                            onChange={setSelectedPropertyType}
                            placeholder="Select Category Type"
                            selected={selectedPropertyType}
                            inputProps={{required: true}}
                        />
                        <label className="form-label">Date</label>
                        <input className="form-control" type="date" name="date" id="date"
                               placeholder="Enter Date"
                               required/>
                        <label className="form-label">Amount</label>
                        <NumericFormat
                            className="form-control"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            name="amount" id="amount"
                            required
                        />
                        <label className="form-label">Description</label>
                        <textarea className="form-control" name="description" id="description"
                                  placeholder="Enter Description" required
                        />
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Expense Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Category Name:</strong> {formData.category_name}</p>
                    <p><strong>Date:</strong> {formData.date}</p>
                    <p><strong>Amount:</strong> {formData.amount}</p>
                    <p><strong>Description:</strong> {formData.description}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setAddConfirmModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setAddConfirmModal(false);
                        handleAddData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="lg"
                show={showAddPropertyTypeModal}
                onHide={() => {
                    setAddPropertyTypeModal(false)
                    setAddModal(true)
                }}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add New Expense Category
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddDataPropertyType}>
                        <label className="form-label">Category Name</label>
                        <input className="form-control" type="text" name="name" id="name"
                               placeholder="Enter Category Name"
                               required/>
                        <label className="form-label">Category Description</label>
                        <textarea className="form-control" name="description"
                                  id="description"
                                  placeholder="Enter Category Description" required
                        />
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying3}
                            >{isModifying3 ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddConfrimPropertyTypeModal} onHide={() => setAddConfirmPropertyTypeModal(false)}
                   backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Expense Category Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Category Name:</strong> {formData3.name}
                    </p>
                    <p><strong>Category Description:</strong> {formData3.description}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setAddConfirmPropertyTypeModal(false);
                        setModifying3(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setAddConfirmPropertyTypeModal(false);
                        handleAddDataPropertyType();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ExpenseLedger;