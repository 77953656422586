import React, {useEffect, useState} from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import axios from 'axios';
import Loading from "../others/Loading";
import {Button, Modal} from "react-bootstrap";

function ScheduleCalendar() {
    const token = localStorage.getItem("token");
    const [eventData, setEventData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [selectedEvent, setSelectedEvent] = useState(null);

    // Define a function to convert 12-hour time to 24-hour time
    const convertTo24HourFormat = (timeString) => {
        const [time, period] = timeString.split(' ');
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours, 10);
        if (period === 'PM' && hours !== 12) {
            hours += 12;
        }
        if (period === 'AM' && hours === 12) {
            hours = 0;
        }
        return `${hours.toString().padStart(2, '0')}:${minutes}`;
    };

    // Define a function to convert 24-hour time to 12-hour time
    const convertTo12HourFormat = (timeString) => {
        const [hours, minutes] = timeString.split(':');
        let period = 'AM';
        let hours12 = parseInt(hours, 10);
        if (hours12 >= 12) {
            period = 'PM';
            if (hours12 > 12) {
                hours12 -= 12;
            }
        }
        return `${hours12.toString().padStart(2, '0')}:${minutes} ${period}`;
    };

    // Define the calculateEndTime function
    const calculateEndTime = (startTime, durationInMinutes) => {
        const startTime24Hour = convertTo24HourFormat(startTime);
        const [startHours, startMinutes] = startTime24Hour.split(':');
        const startDateTime = new Date();
        startDateTime.setHours(parseInt(startHours, 10));
        startDateTime.setMinutes(parseInt(startMinutes, 10));

        const endDateTime = new Date(startDateTime.getTime() + durationInMinutes * 60000);
        const endTime24Hour = `${endDateTime.getHours().toString().padStart(2, '0')}:${endDateTime.getMinutes().toString().padStart(2, '0')}`;
        return convertTo12HourFormat(endTime24Hour);
    };

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/schedules/calendar`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                const events = response.data.data.map(schedule => ({
                    title: schedule.client_name,
                    start: `${schedule.date}T${convertTo24HourFormat(schedule.time)}`,
                    client_name: schedule.client_name,
                    agent_name: schedule.agent_name,
                    property_code: schedule.property_code, // You can add more custom properties here
                }));
                setEventData(events);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [token]);

    const handleEventClick = (clickInfo) => {
        const event = clickInfo.event;
        setSelectedEvent(event);
    };

    const handleClose = () => {
        setSelectedEvent(null);
    };

    const formattedStartTime = selectedEvent?.start.toLocaleString(undefined, {
        hour: 'numeric', minute: '2-digit', hour12: true,
    });

    if (isLoading) {
        return (<Loading/>);
    }

    return (<>
        <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Site Tripping Schedule Calendar
            View</h3>
        <div className='mb-3 mx-4'>
            <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                events={eventData}
                eventClick={handleEventClick}
            />
        </div>
        <Modal show={selectedEvent !== null} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Site Tripping Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><strong>Client Name:</strong> {selectedEvent?.extendedProps.client_name}</p>
                <p><strong>Agent Name:</strong> {selectedEvent?.extendedProps.agent_name}</p>
                <p><strong>Property Code:</strong> {selectedEvent?.extendedProps.property_code}</p>
                <p><strong>Time:</strong> {formattedStartTime}</p>
                <p><strong>Date:</strong> {selectedEvent?.start.toDateString()}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>);
}

export default ScheduleCalendar;
