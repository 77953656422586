import {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import axios from "axios";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import Loading from "./Loading";
import {PatternFormat} from "react-number-format";

function Initializer() {

    const [formData, setFormData] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const navigate = useNavigate();
    const [isInitialized, setInitialized] = useState(false)
    const [isCheckingAuth, setIsCheckingAuth] = useState(true);
    const [isCheckingInitialization, setIsCheckingInitialization] = useState(true);

    function confirmRealty(event) {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData(event.target);
        const logoFile = formData.get("logo");
        const profilePicture = formData.get("profile_picture");
        const logoReader = new FileReader();
        const profilePictureReader = new FileReader();
        const baseData = {
            realty_name: formData.get("realty_name"),
            address: formData.get("address"),
            realty_phone: formData.get("realty_phone"),
            realty_email: formData.get("realty_email"),
            logo: logoFile,
            logo_name: logoFile.name,
            first_name: formData.get("first_name"),
            middle_name: formData.get("middle_name"),
            last_name: formData.get("last_name"),
            suffix: formData.get("suffix"),
            user_phone: formData.get("user_phone"),
            user_email: formData.get("user_email"),
            profile_picture: profilePicture,
            profile_picture_name: profilePicture.name,
        };

        const handleFilesLoad = (event, fileType) => {
            setFormData(prevData => ({...prevData, [`${fileType}_img`]: event.target.result}));
        };

        const logoPromise = new Promise((resolve) => {
            logoReader.onload = (event) => {
                handleFilesLoad(event, "logo");
                resolve();
            };
            logoReader.readAsDataURL(logoFile);
        });

        const profilePicturePromise = new Promise((resolve) => {
            profilePictureReader.onload = (event) => {
                handleFilesLoad(event, "profile_picture");
                resolve();
            };
            profilePictureReader.readAsDataURL(profilePicture);
        });

        setFormData(baseData);
        Promise.all([logoPromise, profilePicturePromise]).then(() => {
            setShowConfirmationModal(true);
        });
    }


    function addRealty() {
        axios.post(`${process.env.REACT_APP_API_URL}/initializer/add`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                toast.success(response.data.message)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
                navigate('/');
            })
    }

    useEffect(() => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/initializer`)
            .then(response => {
                setInitialized(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
                setIsCheckingInitialization(false);
            })
    }, [])

    useEffect(() => {
        if (!isCheckingInitialization) {
            if (isInitialized === true) {
                navigate("/");
                toast.error("You are already done with setup!");
            } else {
                setIsCheckingAuth(false);
            }
        }
    }, [isInitialized, navigate, isCheckingInitialization]);

    if (isCheckingAuth) {
        return (<Loading/>);
    }

    return (<>
        <section className="position-relative py-4 py-xl-5">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-md-8 col-xl-6 text-center mx-auto">
                        <h2>Real Estate Management System Setup</h2>
                        <p className="w-lg-50">To continue with the Real Estate Management System we need to
                            know
                            some information that will initialize the system</p>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6 col-xl-12">
                        <div className="card mb-5 border-primary">
                            <div className="card-body d-flex flex-column">
                                <form onSubmit={confirmRealty} encType="multipart/form-data">
                                    <div className='row'>
                                        <div className='col'>
                                            <div
                                                className="bs-icon-xl bs-icon-circle bs-icon-primary bs-icon my-4 text-center">
                                                <i className="fas fa-tools"></i>
                                            </div>

                                            <div className="mb-3"><label className="form-label fw-bold">Realty
                                                Name</label><input className="form-control" type="text"
                                                                   name="realty_name"
                                                                   id="realty_name" placeholder="Enter Realty Name"
                                                                   required/>
                                            </div>
                                            <div className="mb-3"><label className="form-label fw-bold">Realty
                                                Address</label><input className="form-control" type="text"
                                                                      name="address"
                                                                      id="address"
                                                                      placeholder="Enter Realty Address"
                                                                      required/></div>
                                            <div className="mb-3"><label className="form-label fw-bold">Realty
                                                Email</label><input className="form-control" type="text"
                                                                    name="realty_email"
                                                                    id="realty_email"
                                                                    placeholder="Enter Realty Email"
                                                                    required/>
                                            </div>
                                            <div className="mb-3"><label className="form-label fw-bold">Realty
                                                Phone</label><input className="form-control" type="text"
                                                                    name="realty_phone"
                                                                    id="realty_phone"
                                                                    placeholder="Enter Realty Phone"
                                                                    required/>
                                            </div>
                                            <div className="mb-3"><label className="form-label fw-bold">Realty
                                                Logo</label><input className="form-control" type="file" name="logo"
                                                                   id="logo" required/></div>
                                        </div>
                                        <div className='col'>
                                            <div
                                                className="bs-icon-xl bs-icon-circle bs-icon-primary bs-icon my-4 text-center">
                                                <i className="fas fa-user"></i>
                                            </div>
                                            <div className="mb-3"><label className="form-label fw-bold">First
                                                Name</label><input className="form-control" type="text"
                                                                   name="first_name"
                                                                   id="first_name"
                                                                   placeholder="Enter First Name"
                                                                   required/>
                                            </div>
                                            <div className="mb-3"><label className="form-label fw-bold">Middle
                                                Name</label><input className="form-control" type="text"
                                                                   name="middle_name"
                                                                   id="middle_name"
                                                                   placeholder="Enter Middle Name"
                                                                   required/>
                                            </div>
                                            <div className="mb-3"><label className="form-label fw-bold">Last
                                                Name</label><input className="form-control" type="text"
                                                                   name="last_name"
                                                                   id="last_name"
                                                                   placeholder="Enter Last Name"
                                                                   required/>
                                            </div>
                                            <div className="mb-3"><label className="form-label fw-bold">
                                                Suffix (optional)</label><input className="form-control" type="text"
                                                                                name="suffix"
                                                                                id="suffix"
                                                                                placeholder="Enter Suffix Name (eg. Sr, Jr)"
                                            />
                                            </div>
                                            <div className="mb-3"><label className="form-label fw-bold">Email
                                                Address
                                            </label><input className="form-control" type="text"
                                                           name="user_email"
                                                           id="user_email"
                                                           placeholder="Enter Email Address"
                                                           required/></div>
                                            <div className="mb-3"><label className="form-label fw-bold">Phone
                                                Number</label>
                                                <PatternFormat className="form-control" name="user_phone"
                                                               id="user_phone"
                                                               format="#### ### ####"
                                                               allowEmptyFormatting mask="_"
                                                               required/>
                                            </div>
                                            <div className="mb-3"><label className="form-label fw-bold">Profile
                                                Picture</label><input className="form-control" type="file"
                                                                      name="profile_picture"
                                                                      id="profile_picture" required/></div>
                                        </div>
                                        <div className="mb-3">
                                            <button className="btn btn-primary d-block w-100"
                                                    disabled={isLoading}
                                            >{isLoading ? <i className="fa fa-spinner fa-spin"></i> : "Done"}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)} backdrop='static'>
            <Modal.Header>
                <Modal.Title>Confirm Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col'>
                        <h5>Realty Details:</h5>
                        <p><strong>Realty Name:</strong> {formData.realty_name}</p>
                        <p><strong>Address:</strong> {formData.address}</p>
                        <p><strong>Email:</strong> {formData.realty_email}</p>
                        <p><strong>Phone:</strong> {formData.realty_phone}</p>
                        <p><strong>Logo:</strong> {formData.logo_name}</p>
                        <img src={formData.logo_img} alt="Uploaded logo" className="img-fluid"
                             style={{maxHeight: "200px"}}/>
                    </div>
                    <div className='col'>
                        <h5>Broker Details:</h5>
                        <p><strong>Broker
                            Name:</strong> {formData.last_name}, {formData.first_name} {formData.middle_name} {formData.suffix}
                        </p>
                        <p><strong>Email:</strong> {formData.user_email}</p>
                        <p><strong>Phone:</strong> {formData.user_phone}</p>
                        <p><strong>Profile Picture:</strong> {formData.profile_picture_name}</p>
                        <img src={formData.profile_picture_img} alt="Uploaded logo" className="img-fluid"
                             style={{maxHeight: "200px"}}/>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setShowConfirmationModal(false);
                    setLoading(false);
                }}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    setShowConfirmationModal(false);
                    addRealty();
                }}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    </>);
}

export default Initializer;
