import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import Loading from "../../others/Loading";
import {Button, Modal} from "react-bootstrap";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build";
import BlogViewData from "../../others/BlogViewData";


function BlogView() {
    const token = localStorage.getItem("token");
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [formData, setFormData] = useState({});
    const [isModifying, setModifying] = useState(false);

    const [fileUrl, setFileUrl] = useState('');

    const [update_id, setUpdateId] = useState('')
    const [showEditModal, setEditModal] = useState(false);
    const [showEditConfrimModal, setEditConfirmModal] = useState(false);

    const [ckEditorContent, setCkEditorContent] = useState('');

    const [deleteDataId, setDeleteDataId] = useState(null);
    const [deleteDataName, setDeleteDataName] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const {id} = useParams();

    const navigate = useNavigate();

    const statusNames = {
        0: "Not Published", 1: "Publish",
    };

    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.role;
    }

    const userRole = getUserRole(token);

    function getUserId(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.id;
    }

    const userId = getUserId(token);

    const handleEditorChange = (event, editor) => {
        const newContent = editor.getData();
        setCkEditorContent(newContent);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/blog/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [id, token])

    function confirmEditData(event) {
        event.preventDefault()
        setModifying(true)

        const imageFileInput = event.target.elements.image;

        if (imageFileInput && imageFileInput.files && imageFileInput.files.length > 0) {
            const image = imageFileInput.files[0];
            const imageFileName = image.name;

            const fileExtension = imageFileName.toLowerCase().split('.').pop();
            if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
                setFormData({
                    title: event.target.elements.title.value,
                    content: ckEditorContent,
                    image: image,
                    image_name: imageFileName,
                    status: event.target.elements.status.value,
                });
                const fileUrl = URL.createObjectURL(image);
                setFileUrl(fileUrl);
                setEditConfirmModal(true);
            } else {
                toast.error('Invalid file type. Only image files are allowed.');
                setModifying(false);
            }
        } else {
            setFormData({
                title: event.target.elements.title.value,
                content: ckEditorContent,
                status: event.target.elements.status.value,
            });
            setEditConfirmModal(true);
        }
    }

    function handleEditData() {
        axios.put(`${process.env.REACT_APP_API_URL}/update/blog-view/${update_id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const updatedData = response.data.updated_data;
                    setData(updatedData);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setEditModal(false)
                setUpdateId('')
            })
    }

     function confirmDeleteData(id, title) {
        setDeleteDataId(id);
        setDeleteDataName(`${title}`);
        setShowDeleteModal(true);
    }

    function handleDeleteData(id) {
        fetch(`${process.env.REACT_APP_API_URL}/delete/blog/${id}`, {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                navigate(`/portal/blogs`);
                toast.success('Blog removed successfully.');
            })
            .catch(error => {
                console.error(error);
                toast.error('An error occurred while deleting data.');
            });
    }

    if (isLoading) {
        return (<Loading/>);
    }
    return (
        <>
            <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Blog Settings
                of {data.title}</h3>
            {userRole === 1 || userRole === 2 ? (<div className="card shadow border-primary mb-5 mx-4">
                <div className="card-header">
                    <p className="text-primary m-0 fw-bold d-inline">Blog Information</p>
                </div>
                <div className="card-body">
                    <div className="table-responsive table mt-2" id="dataTable" role="grid"
                         aria-describedby="dataTable_info">
                        <table className="table my-0" id="dataTable">
                            <thead>
                            <tr>
                                <th>Featured Image</th>
                                <th>Title</th>
                                <th>Summary</th>
                                <th>Author</th>
                                <th>Status</th>
                                <th>Published Date</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody className='table-group-divider'>
                            <tr>
                                <td><img src={`${process.env.REACT_APP_API_URL}/uploaded_img/${data.featured_image}`}
                                         className='rounded-1 img-fluid img-thumbnail'
                                         alt="Thumbnail" style={{
                                    width: '50px',
                                    height: '50px',
                                }}/></td>
                                <td>{data.title}</td>
                                <td>{data.summary}</td>
                                <td>{data.user.first_name} {data.user.last_name}</td>
                                <td>{data.status_name}</td>
                                <td>{data.created_date}</td>
                                <td>
                                    <button className="btn btn-warning btn-sm mx-1" onClick={() => {
                                        setUpdateId(data.id)
                                        setFormData({
                                            title: data.title,
                                            status: data.status,
                                            image: data.featured_image,
                                        });
                                        setCkEditorContent(`${data.content}`)
                                        setEditModal(true)
                                    }}><i className='fas fa-edit'></i></button>
                                    <button className="btn btn-danger btn-sm"
                                            onClick={() => confirmDeleteData(data.id, data.title)}>
                                        <i
                                            className='fas fa-trash-alt'></i></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>) : null}

            <BlogViewData data={data}/>

            <Modal
                size="lg"
                show={showEditModal}
                onHide={() => setEditModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Edit Blog Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmEditData} encType="multipart/form-data">
                        <label className="form-label">Title</label>
                        <input className="form-control" type="text" name="title" id="title"
                               value={formData.title}
                               onChange={(e) => setFormData({...formData, title: e.target.value})}
                               required/>
                        <label className="form-label">Content</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={ckEditorContent} // Use the content from the state
                            config={{
                                mediaEmbed: {previewsInData: true}
                            }}
                            onReady={editor => {
                                // You can store the "editor" and use it when needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={handleEditorChange} // Use your custom function to handle content change
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                        />
                        <label className="form-label">Image</label>
                        <input className="form-control" type="file" name="image" id="image"/>
                        <label className="form-label">Publish Status</label>
                        <select className="form-select" aria-label="Default select example" name="status"
                                id="status"
                                value={formData.status}
                                onChange={(e) => setFormData({...formData, status: e.target.value})}
                                required>
                            <option value="0">Not Published</option>
                            <option value="1">Publish</option>
                        </select>
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Update"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showEditConfrimModal} onHide={() => setEditConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Blog Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Title:</strong> {formData.title}</p>
                    <p><strong>Status:</strong> {statusNames[formData.status]}</p>
                    <p><strong>Featured Image:</strong></p>
                    <img src={fileUrl} alt="featured_image" style={{maxWidth: '100%', height: 'auto'}}/>
                    {fileUrl ? (
                        <>
                            <img src={fileUrl} alt="brand_logo" style={{maxWidth: '100%', height: 'auto'}}/>
                        </>
                    ) : null}
                    <p><strong>Content:</strong></p>
                    <div className="col" style={{fontFamily: 'system-ui'}}
                         dangerouslySetInnerHTML={{__html: formData.content}}></div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setEditConfirmModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setEditConfirmModal(false);
                        handleEditData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

                 <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Delete Blog</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete {deleteDataName}?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => {
                        handleDeleteData(deleteDataId);
                        setShowDeleteModal(false);
                    }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default BlogView;