import {useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import Loading from "../../others/Loading";
import ReactToPrint from "react-to-print";

function PrintBuyer() {
    const {id} = useParams();
    const token = localStorage.getItem("token");
    const printContentRef = useRef()
    const [data, setData] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [isLogo, setLogo] = useState('')

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/print-buyer/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [id, token])

        useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/realty`)
            .then(response => {
                setLogo(`${process.env.REACT_APP_API_URL}/uploaded_img/${response.data.logo}`)
            })
            .catch(error => {
                console.log(error)
            })
    })

    const PrintContent = React.forwardRef((_, ref) => (<div ref={ref}>
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '10px'}}
             className="page-break">
            <img
                src={isLogo}
                alt="Loading..."
                style={{
                    width: '250px', height: '100px', // You can add other styles here as needed
                }}
            />
        </div>
        <br/>

        <div className='container' style={{fontFamily: 'Times New Roman'}}>
            <h1 className='h1 text-center'>Buyers Information</h1>
            <br/>
            <div className='row'>
                <div className='col'>
                    <p><strong>Name:</strong> {data.name}</p>
                </div>
                <div className='col'>
                    <p><strong>Age:</strong> {data.age}</p>
                </div>
                <div className='col'>
                    <p><strong>Birth Date:</strong> {data.birth_date}</p>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <p><strong>Spouse Name:</strong> {data.spouse_name}</p>
                </div>
                <div className='col'>
                    <p><strong>Spouse Age:</strong> {data.spouse_age}</p>
                </div>
                <div className='col'>
                    <p><strong>Spouse Birth Date:</strong> {data.spouse_birth_date}</p>
                </div>
            </div>
            <p><strong>Residential Address:</strong> {data.residence_address}</p>
            <p><strong>Provincial Address:</strong> {data.provincial_address}</p>
            <div className='row'>
                <div className='col'>
                    <p><strong>Contact No.:</strong> {data.contact_number}</p>
                </div>
                <div className='col'>
                    <p><strong>Telephone No.:</strong> {data.telephone_number}</p>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <p><strong>Email Address:</strong> {data.email}</p>
                </div>
                <div className='col'>
                    <p><strong>Facebook Acct.:</strong> {data.facebook}</p>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <p><strong>Civil Status:</strong> {data.civil_status}</p>
                </div>
                <div className='col'>
                    <p><strong>Residence Cert No.:</strong> {data.residence_cert_number}</p>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <p><strong>Nationality:</strong> {data.nationality}</p>
                </div>
                <div className='col'>
                    <p><strong>Assn./Org. Membership:</strong> {data.organization}</p>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <p><strong>Valid ID:</strong> {data.valid_id}</p>
                </div>
                <div className='col'>
                    <p><strong>Valid ID No.:</strong> {data.valid_id_number}</p>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <p><strong>Occupation:</strong> {data.occupation}</p>
                </div>
                <div className='col'>
                    <p><strong>Type of Business:</strong> {data.type_of_business}</p>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <p><strong>Company Name:</strong> {data.company_name}</p>
                </div>
                <div className='col'>
                    <p><strong>Business Name:</strong> {data.business_name}</p>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <p><strong>Company Address:</strong> {data.company_address}</p>
                </div>
                <div className='col'>
                    <p><strong>Business Address:</strong> {data.business_address}</p>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <p><strong>Monthly Income:</strong> {data.monthly_income}</p>
                </div>
                <div className='col'>
                    <p><strong>T.I.N. No.:</strong> {data.tin_number}</p>
                </div>
            </div>
        </div>
    </div>));

    if (isLoading) {
        return (<Loading/>);
    }

    return (
        <>
            <div className='m-4 text-center'>
                <ReactToPrint
                    trigger={() => <button className='btn btn-primary'>Print</button>}
                    content={() => printContentRef.current}
                />
            </div>


            <PrintContent ref={printContentRef}/>
        </>
    )
}

export default PrintBuyer;