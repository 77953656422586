import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import Loading from "../others/Loading";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {Helmet} from "react-helmet";

function AdminLogin() {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setLoading] = useState(true)
    const [isLogo, setLogo] = useState('')
    const [isInitialized, setInitialized] = useState(false)

    useEffect(() => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/initializer`)
            .then(response => {
                setInitialized(response.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])


    useEffect(() => {
        if (isInitialized.data === false) {
            navigate('/setup');
        }
    }, [isInitialized, navigate]);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/realty`)
            .then(response => {
                setLogo(`${process.env.REACT_APP_API_URL}/uploaded_img/${response.data.logo}`)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    function handleLogin() {
        axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {email, password}, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem('token', response.data.access_token);
                    toast.success('Logged in Successfully');
                    navigate('/portal/dashboard');
                } else {
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            });
    }


    useEffect(() => {
        const authenticated = Boolean(token);
        if (authenticated) {
            navigate('/portal/dashboard');
        } else {
            setLoading(false)
        }
    }, [navigate, token]);

    if (isLoading) {
        return (<Loading/>);
    }

    return (<>
        <Helmet>
            <meta name="robots" content="noindex, nofollow"/>
        </Helmet>
        <div className="row justify-content-center">
            <div className="col-md-9 col-lg-12 col-xl-10">
                <div className="card shadow-lg o-hidden border-dark my-5 ">
                    <div className="card-body p-0">
                        <div className="row">
                            <div className="col-lg-6 d-none d-lg-flex">
                                <div className="flex-grow-1 bg-login-image"
                                     style={{
                                         backgroundImage: `url("${isLogo}")`,
                                         backgroundSize: 'cover',
                                         backgroundPosition: 'center',
                                         maxWidth: '100vh',
                                     }}></div>
                            </div>
                            <div className="col-lg-6">
                                <div className="p-5">
                                    <div className="text-center">
                                        <h4 className="text-dark mb-4">Agent Portal Login</h4>
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            className='form-control'
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            className='form-control'
                                            type="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <button onClick={handleLogin}
                                            className='btn btn-warning d-block w-100'>Login
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <footer className="footer">
                        <p className="text-center my-3">
                            &copy; {new Date().getFullYear()} Realty Management System. All Rights Reserved. |
                            Developed by:
                            Clever Kobweb
                        </p>
                    </footer>
                </div>
            </div>
        </div>
    </>)
}

export default AdminLogin