import {GoogleMap, InfoWindow, MarkerF, useLoadScript} from "@react-google-maps/api";
import {useEffect, useState} from "react";
import mapPin from '../../assets/img/mapPin.png'
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import {Carousel, Table} from "react-bootstrap";

const containerStyle = {
    width: '100%', height: '400px',
};

function PropertyViewData({data}) {
    useEffect(() => {
        if (document.querySelectorAll('[data-bss-baguettebox]').length > 0) {
            window.baguetteBox.run('[data-bss-baguettebox]', {animation: 'slideIn'});
        }
    }, [data]);

    const filteredAmenitiesInterior = data.property_amenities.filter(
        amenity => amenity.propertyamenitiestype.id === 1
    );
    const filteredAmenitiesArea = data.property_amenities.filter(
        amenity => amenity.propertyamenitiestype.id === 2
    );
    const filteredAmenitiesExterior = data.property_amenities.filter(
        amenity => amenity.propertyamenitiestype.id === 3
    );
    const filteredAmenitiesFinance = data.property_amenities.filter(
        amenity => amenity.propertyamenitiestype.id === 4
    );


    const {isLoaded} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    });
    const [activeMarker, setActiveMarker] = useState(null);
    const closeInfoWindow = () => {
        setActiveMarker(null);
    };
    const handleMarkerClick = (marker) => {
        setActiveMarker(marker);
    };

    const [lat, lng] = data.g_map_location ? data.g_map_location.split(',').map(parseFloat) : [null, null];
    const center = lat && lng ? {lat, lng} : {lat: 7.110548, lng: 125.5127269};

    const images = data.property_image.map((image, index) => ({
        original: `${process.env.REACT_APP_API_URL}/uploaded_img/${image.image}`,
        thumbnail: `${process.env.REACT_APP_API_URL}/uploaded_img/${image.image}`,
    }));

    const columnStyles = {
        oddColumn: {
            backgroundColor: '#f5f5f5',
        },
        evenColumn: {
            backgroundColor: '#ffffff',
        },
    };

    let embeddedUrl = '';
    if (data.yt_video && data.yt_video.trim() !== '') {
        const videoId = data.yt_video.split('v=')[1];
        const ampersandPosition = videoId.indexOf('&');
        if (ampersandPosition !== -1) {
            embeddedUrl = `https://www.youtube.com/embed/${videoId.substring(0, ampersandPosition)}`;
        } else {
            embeddedUrl = `https://www.youtube.com/embed/${videoId}`;
        }
    }


    return (
        <section data-aos="fade-up" style={{background: '#ffffff', paddingTop: '50px', paddingBottom: '100px'}}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12" style={{textAlign: 'center'}}>
                        <Carousel>
                            {/* Map through your images and create Carousel items */}
                            {data.property_image.map((image, index) => (
                                <Carousel.Item key={image.id}>
                                    <img
                                        src={`${process.env.REACT_APP_API_URL}/uploaded_img/${image.image}`}
                                        alt='Loading..'
                                        className="d-block w-100 carousel-image"
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                    <div className="col-xl-12" style={{textAlign: 'center'}}>
                        <h2 style={{paddingTop: '20px', color: '#805b43'}}>₱{data.price}</h2>
                        <h1 style={{paddingTop: '0px', color: '#805b43'}}>{data.published_title
                            ? `${data.published_title}`
                            : 'NOT SET'}</h1>
                        <h3 style={{paddingTop: '0px', color: '#805b43'}}>{data.propertytype.name}</h3>
                        <h6 style={{paddingTop: '0px'}}>PROPERTY CODE: {data.name}</h6>
                    </div>
                    <div className="col-xl-12">
                        <hr/>
                    </div>
                    <div className="col text-start" style={{textAlign: 'center'}}>
                        <h2 className="text-start" style={{color: '#805b43'}}>Description</h2>
                        <div className="col" style={{fontFamily: 'system-ui'}}
                             dangerouslySetInnerHTML={{__html: data.description}}></div>
                    </div>
                    <div className="col-xl-12">
                        <hr/>
                    </div>
                    <div className="col text-start" style={{textAlign: 'center'}}>
                        <h2 className="text-center" style={{color: '#805b43'}}>Amenities</h2>
                        <div className="row">
                            <div className="col-xl-6 text-start" style={{textAlign: 'left'}}>
                                <div className="table-responsive">
                                    <table className="table table-striped table-sm">
                                        <caption style={{textAlign: 'center', textDecoration: 'underline'}}
                                                 className="caption-top">INTERIOR
                                        </caption>
                                        <tbody>
                                        {filteredAmenitiesInterior.map((amenity, index) => (
                                            <tr key={amenity.id}>
                                                <td>{amenity.name}</td>
                                                <td style={{fontFamily: 'system-ui'}}
                                                    className='text-end'>{amenity.description}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-xl-6" style={{textAlign: 'left'}}>
                                <div className="table-responsive">
                                    <table className="table table-striped table-sm">
                                        <caption style={{textAlign: 'center', textDecoration: 'underline'}}
                                                 className="caption-top">EXTERIOR
                                        </caption>
                                        <tbody>
                                        {filteredAmenitiesExterior.map((amenity, index) => (
                                            <tr key={amenity.id}>
                                                <td>{amenity.name}</td>
                                                <td style={{fontFamily: 'system-ui'}}
                                                    className='text-end'>{amenity.description}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="table-responsive">
                                    <table className="table table-striped table-sm">
                                        <caption style={{
                                            textAlign: 'center',
                                            textDecoration: 'underline',
                                        }} className="caption-top">AREA &amp; LOT
                                        </caption>
                                        <tbody>
                                        {filteredAmenitiesArea.map((amenity, index) => (
                                            <tr key={amenity.id}>
                                                <td> {amenity.name}</td>
                                                <td style={{fontFamily: 'system-ui'}}
                                                    className='text-end'>{amenity.description}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="table-responsive">
                                    <table className="table table-striped table-sm">
                                        <caption style={{textAlign: 'center', textDecoration: 'underline'}}
                                                 className="caption-top">FINANCE
                                        </caption>
                                        <tbody>
                                        {filteredAmenitiesFinance.map((amenity, index) => (
                                            <tr key={amenity.id}>
                                                <td>{amenity.name}</td>
                                                <td style={{fontFamily: 'system-ui'}}
                                                    className='text-end'>{amenity.description}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <hr/>
                    </div>
                    <div className="col text-start" style={{textAlign: 'center'}}>
                        <h2 className="text-start" style={{color: '#805b43'}}>Location</h2>
                        <p style={{fontFamily: 'system-ui'}}>{data.published_location
                            ? `${data.published_location}`
                            : 'NOT SET'}</p>
                    </div>
                    <div className="col-xl-12">
                        <hr/>
                    </div>
                    <div className="col text-start" style={{textAlign: 'center'}}>
                        <h2 className="text-start" style={{color: '#805b43'}}>Available Lots</h2>
                        <Table bordered>
                            <tbody>
                            {data.property_block_lot.reduce((rows, data, index) => {
                                const rowIndex = Math.floor(index / 6);
                                if (!rows[rowIndex]) {
                                    rows[rowIndex] = [];
                                }
                                rows[rowIndex].push(
                                    <td key={index}
                                        style={index % 2 === 0 ? columnStyles.evenColumn : columnStyles.oddColumn}>
                                        <p className={`d-inline ${data.status !== 1 ? 'text-decoration-line-through text-danger' : 'fw-bold'}`}>
                                            {data.name}
                                        </p>
                                    </td>
                                );
                                return rows;
                            }, []).map((row, rowIndex) => (
                                <tr key={rowIndex}>{row}</tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                    <div className="col-xl-12">
                        <hr/>
                    </div>
                    <div className="col text-start" style={{textAlign: 'center'}}>
                        <h2 className="text-center" style={{color: '#805b43'}}>Gallery image</h2>
                        <section className="photo-gallery py-4 py-xl-5">
                            <div className="container">
                                <div className="row gx-2 gy-2 row-cols-1 row-cols-md-2 row-cols-xl-3 photos"
                                     data-bss-baguettebox="">
                                    {data.property_image && data.property_image.length > 0 ? (
                                        <>
                                            {data.property_image.map((image) => (
                                                <div className="col item" key={image.id}><a
                                                    href={`${process.env.REACT_APP_API_URL}/uploaded_img/${image.image}`}><img
                                                    className="img-fluid"
                                                    src={`${process.env.REACT_APP_API_URL}/uploaded_img/${image.image}`}/></a>
                                                </div>
                                            ))}
                                        </>

                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </section>
                    </div>

                    <hr/>
                    {embeddedUrl ? (
                        <>
                            <div className="col-xxl-12 offset-xxl-0 text-start" style={{textAlign: 'center'}}>
                                <h2 className="text-center" style={{color: '#805b43'}}>Video Preview</h2>

                                <figure className="media">
                                    <div data-oembed-url={embeddedUrl}>
                                        <div style={{position: 'relative', paddingBottom: '56.2493%', height: 0}}>
                                            <iframe
                                                src={embeddedUrl}
                                                style={{
                                                    position: 'absolute',
                                                    width: '100%',
                                                    height: '100%',
                                                    top: 0,
                                                    left: 0
                                                }}
                                                frameBorder="0"
                                                allow="autoplay; encrypted-media"
                                                allowFullScreen=""
                                            ></iframe>
                                        </div>
                                    </div>
                                </figure>
                            </div>
                            <hr/>
                        </>
                    ) : <></>}
                    <div className="col text-start" style={{textAlign: 'center'}}>
                        <h2 className="text-center" style={{color: '#805b43'}}>Location</h2>
                        {!isLoaded ? (<h1>Loading...</h1>) : (
                            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={13}>
                                <MarkerF position={{lat: lat, lng: lng}}
                                         onClick={() => handleMarkerClick(data)}
                                         icon={{
                                             url: mapPin,
                                         }}>
                                    {activeMarker === data && (
                                        <InfoWindow position={{lat, lng}} onCloseClick={closeInfoWindow}>
                                            <div>
                                                <p style={{fontFamily: 'system-ui'}}>{data.published_title}</p>
                                                <p style={{fontFamily: 'system-ui'}}>{data.propertytype.name}</p>
                                                <p style={{fontFamily: 'system-ui'}}>{data.published_location}</p>
                                                <p style={{fontFamily: 'system-ui'}}>₱{data.price}</p>
                                            </div>
                                        </InfoWindow>)}
                                </MarkerF>
                            </GoogleMap>)}
                    </div>
                    <div className="col-xl-12">
                        <hr/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PropertyViewData;