import ReactPaginate from "react-paginate";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import Loading from "../others/Loading";
import {Button, Modal} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";

function Projects() {
    const token = localStorage.getItem("token");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true)

    const [data, setData] = useState([])
    const [formData, setFormData] = useState({});

    const [isModifying, setModifying] = useState(false);


    const [selectedPropertyType, setSelectedPropertyType] = useState([]);
    const [propertyType, setPropertyType] = useState([]);

    const [selectedPropertyOwnerType, setSelectedPropertyOwnerType] = useState([]);
    const [propertyOwnerType, setPropertyOwnerType] = useState([]);

    const [formData2, setFormData2] = useState({});
    const [showAddOwnerModal, setAddOwnerModal] = useState(false);
    const [showAddConfrimOwnerModal, setAddConfirmOwnerModal] = useState(false);
    const [isModifying2, setModifying2] = useState(false);

    const [update_id, setUpdateId] = useState('')
    const [showEditModal, setEditModal] = useState(false);
    const [showEditConfrimModal, setEditConfirmModal] = useState(false);

    const [fileUrl, setFileUrl] = useState('');


    const navigate = useNavigate();


    const pageCount = Math.ceil(total / pageSize);
    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.role;
    }

    const userRole = getUserRole(token);

    function getUserId(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.id;
    }

    const userId = getUserId(token);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/projects?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pageNumber, pageSize, searchTerm, token])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/property-owner-type/typeahead`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                const data = response.data;
                setPropertyOwnerType(data.map((data) => ({
                    labelKey: `${data.name}`, id: data.id
                })))
            })
            .catch(error => {
                console.log(error)
            })
    }, [token])


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/property-owner-type/typeahead`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                const data = response.data;
                setPropertyType(data.map((data) => ({
                    labelKey: `${data.name}`, id: data.id
                })))
            })
            .catch(error => {
                console.log(error)
            })
    }, [token])

    function confirmAddDataPropertyOwner(event) {
        event.preventDefault()
        setModifying2(true)

        const image = event.target.elements.image.files[0];
        const imageFileName = image.name;

        const fileExtension = imageFileName.toLowerCase().split('.').pop();
        if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
            setFormData2({
                project_name: event.target.elements.project_name.value,
                location: event.target.elements.location.value,
                yt_video: event.target.elements.yt_video.value,
                property_owner_type_name: selectedPropertyOwnerType[0].labelKey,
                property_owner_type_id: selectedPropertyOwnerType[0].id,
                image: image,
                image_name: imageFileName,
            });
            const fileUrl = URL.createObjectURL(image);
            setFileUrl(fileUrl);
            setAddConfirmOwnerModal(true);
        } else {
            // Display an error toast for invalid file type
            toast.error('Invalid file type. Only image files are allowed.');
            setModifying(false);
        }
    }

    function handleAddDataPropertyOwner() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/propertyowner`, formData2, {
            headers: {
                'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const newData = response.data.new_data;
                    setData(prevData => [newData, ...prevData]);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setModifying2(false)
                setAddOwnerModal(false)
                setSelectedPropertyOwnerType([])
            })
    }

    function confirmEditData(event) {
        event.preventDefault()
        setModifying(true)

        const imageFileInput = event.target.elements.image;

        if (imageFileInput && imageFileInput.files && imageFileInput.files.length > 0) {
            const image = imageFileInput.files[0];
            const imageFileName = image.name;

            const fileExtension = imageFileName.toLowerCase().split('.').pop();
            if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
                setFormData2({
                    project_name: event.target.elements.project_name.value,
                    location: event.target.elements.location.value,
                    yt_video: event.target.elements.yt_video.value,
                    property_owner_type_name: selectedPropertyOwnerType[0].labelKey,
                    property_owner_type_id: selectedPropertyOwnerType[0].id,
                    image: image,
                    image_name: imageFileName,
                });
                const fileUrl = URL.createObjectURL(image);
                setFileUrl(fileUrl);
                setEditConfirmModal(true);
            } else {
                // Display an error toast for invalid file type
                toast.error('Invalid file type. Only image files are allowed.');
                setModifying(false);
            }
        } else {
            setFormData2({
                project_name: event.target.elements.project_name.value,
                location: event.target.elements.location.value,
                yt_video: event.target.elements.yt_video.value,
                property_owner_type_name: selectedPropertyOwnerType[0].labelKey,
                property_owner_type_id: selectedPropertyOwnerType[0].id,
            });
            setEditConfirmModal(true);
        }
    }

    function handleEditData() {
        axios.put(`${process.env.REACT_APP_API_URL}/update/project/${update_id}`, formData2, {
            headers: {
                'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const updatedData = response.data.updated_data;
                    const updatedIndex = data.findIndex(item => item.id === updatedData.id);
                    const updatedData2 = [...data];
                    updatedData2[updatedIndex] = updatedData;
                    setData(updatedData2);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setEditModal(false)
                setUpdateId('')
            })
    }

    function handleViewProperties(id, project_name) {
        const encodedProjectName = encodeURIComponent(project_name);
        navigate(`/portal/property-listings/${id}/${encodedProjectName}`);
    }

    if (isLoading) {
        return (<Loading/>);
    }
    return (
        <>
            <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Projects</h3>
            <div className="card shadow border-primary mb-3 mx-4">
                <div className="card-header">
                    <p className="text-primary m-0 fw-bold d-inline">Projects Information</p>
                    {userRole === 1 || userRole === 2 ? (
                        <button className="btn btn-primary text-end float-end btn-sm" onClick={() => {
                            setAddOwnerModal(true)
                        }}>Add New Project
                        </button>) : null}
                </div>
                <div className="card-body">
                    <div className="row g-3">
                        <div className='col-md-11'>
                            <input type="text" className="form-control" placeholder="Search Project Name!"
                                   aria-label="Search"
                                   aria-describedby="basic-addon2" value={searchTerm}
                                   onChange={e => setSearchTerm(e.target.value)}/>
                        </div>
                        <div className='col-md'>
                            <select className="form-control" value={pageSize} onChange={e => {
                                setPageSize(Number(e.target.value));
                                setPageNumber(0); // Reset the page number when the page size changes
                            }}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                    </div>

                    <div className="table-responsive table mt-2" id="dataTable" role="grid"
                         aria-describedby="dataTable_info">
                        <table className="table my-0" id="dataTable">
                            <thead>
                            <tr>
                                <th>Image</th>
                                <th>Project Name</th>
                                <th>Project type</th>
                                <th>Description</th>
                                <th>Youtube Link</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody className='table-group-divider'>
                            {data.length === 0 ? (<tr>
                                <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                            </tr>) : (data.map((data) => (<tr key={data.id}>
                                <td>
                                    <img src={`${process.env.REACT_APP_API_URL}/uploaded_img/${data.image}`}
                                         className='rounded-1 img-fluid img-thumbnail'
                                         alt="Thumbnail" style={{
                                        width: '50px',
                                        height: '50px',
                                    }}/>
                                </td>
                                <td>{data.name}</td>
                                <td>{data.propertyownertype.name}</td>
                                <td>{data.location}</td>
                                <td>{data.yt_video}</td>
                                <td>
                                    <button className="btn btn-primary btn-sm mx-1"
                                            onClick={() => handleViewProperties(data.id, data.name)}>
                                        <i className="fa fa-home" aria-hidden="true"></i>
                                    </button>
                                    {userRole === 1 || userRole === 2 ? (<>
                                        <button className="btn btn-warning btn-sm mx-1" onClick={() => {
                                            setUpdateId(data.id)
                                            const selectedOwnerType = [{
                                                labelKey: `${data.propertyownertype.name}`,
                                                id: data.propertyownertype.id
                                            }];
                                            setFormData({
                                                project_name: data.name,
                                                location: data.location,
                                                yt_video: data.yt_video,
                                                image: data.image,
                                            });
                                            setSelectedPropertyOwnerType(selectedOwnerType)
                                            setEditModal(true)
                                        }}><i className='fas fa-edit'></i></button>
                                    </>) : null}
                                </td>

                            </tr>)))}
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        containerClassName="pagination justify-content-center mt-3"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        breakClassName="page-item"
                        disabledClassName="disabled"
                    />
                </div>
            </div>

            <Modal
                size="lg"
                show={showAddOwnerModal}
                onHide={() => {
                    setAddOwnerModal(false);
                }}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add New Project
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddDataPropertyOwner} encType="multipart/form-data">
                        <label className="form-label">Project Name</label>
                        <input className="form-control" type="text" name="project_name" id="project_name"
                               placeholder="Enter Project Name"
                               required/>
                        <label className="form-label">Description</label>
                        <textarea className="form-control" name="location" id="location"
                                  placeholder="Enter Project Description" required
                        />
                        <label className="form-label">Youtube Video</label>
                        <input className="form-control" type="text" name="yt_video"
                               id="yt_video"
                               placeholder="Enter Youtube Link Video"
                        />
                        <label className="form-label">Project Type</label>
                        <Typeahead
                            id="id"
                            labelKey="labelKey"
                            options={propertyOwnerType}
                            onChange={setSelectedPropertyOwnerType}
                            placeholder="Select Project Type"
                            selected={selectedPropertyOwnerType}
                            inputProps={{required: true}}
                        />
                        <label className="form-label">Image</label>
                        <input className="form-control" type="file" name="image" id="image"/>
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying2}
                            >{isModifying2 ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddConfrimOwnerModal} onHide={() => setAddConfirmOwnerModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Project Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Project Name:</strong> {formData2.project_name}
                    </p>
                    <p><strong>Project Description:</strong> {formData2.location}</p>
                    <p><strong>Project Type:</strong> {formData2.property_owner_type_name}</p>
                    <p><strong>Youtube Video Link:</strong> {formData2.yt_video}</p>
                    <img src={fileUrl} alt="brand_logo" style={{maxWidth: '100%', height: 'auto'}}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setAddConfirmOwnerModal(false);
                        setModifying2(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setAddConfirmOwnerModal(false);
                        handleAddDataPropertyOwner();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="lg"
                show={showEditModal}
                onHide={() => setEditModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Edit Project Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmEditData} encType="multipart/form-data">
                        <label className="form-label">Project Name</label>
                        <input className="form-control" type="text" name="project_name" id="project_name"
                               placeholder="Enter Property Name"
                               value={formData.project_name}
                               onChange={(e) => setFormData({...formData, project_name: e.target.value})}
                               required/>
                        <label className="form-label">Project Description</label>
                        <textarea className="form-control" name="location" id="location"
                                  placeholder="Enter Project Description" required
                                  value={formData.location}
                                  onChange={(e) => setFormData({...formData, location: e.target.value})}
                        />
                        <label className="form-label">Youtube Link</label>
                        <input className="form-control" type="text" name="yt_video"
                               id="yt_video"
                               value={formData.yt_video}
                               onChange={(e) => setFormData({...formData, yt_video: e.target.value})}
                               placeholder="Enter Youtube Link Video"
                        />
                        <label className="form-label">Project Type</label>
                        <Typeahead
                            id="id"
                            labelKey="labelKey"
                            options={propertyOwnerType}
                            onChange={setSelectedPropertyOwnerType}
                            placeholder="Select Property Type"
                            selected={selectedPropertyOwnerType}
                            required
                        />
                        <label className="form-label">Image</label>
                        <input className="form-control" type="file" name="image" id="image"/>
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Update"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showEditConfrimModal} onHide={() => setEditConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Edited Project Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Project Name:</strong> {formData2.project_name}
                    </p>
                    <p><strong>Project Description:</strong> {formData2.location}</p>
                    <p><strong>Project Type:</strong> {formData2.property_owner_type_name}</p>
                    <p><strong>Youtube Video Link:</strong> {formData2.yt_video}</p>
                      {fileUrl ? (
                        <>
                            <img src={fileUrl} alt="brand_logo" style={{maxWidth: '100%', height: 'auto'}}/>
                        </>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setEditConfirmModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setEditConfirmModal(false);
                        handleEditData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Projects;