import React, {useContext} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {toast} from "react-toastify";
import '../../assets/css/header.css'
import RealtyNameContext from "../others/RealtyNameContext";
import {NavDropdown} from "react-bootstrap";


function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const {realtyName} = useContext(RealtyNameContext);
    const token = localStorage.getItem("token");

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate("/");
        toast.success('Logged out Successfully')
    };

    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.role;
    }

    const userRole = getUserRole(token);

    function getStaffRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.staff_role;
    }

    const staffRole = getStaffRole(token);

    function getUserId(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.id;
    }

    const userId = getUserId(token);

    const isActiveRoute = (route) => {
        return location.pathname === route;
    };


    return (
        <nav className="navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-gradient-primary p-0">
            <div className="container-fluid d-flex flex-column p-0"><Link
                className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0"
                to='/'
            >
                <div className="sidebar-brand-text mx-2"><span>ROHE AIO RE SYS</span></div>
            </Link>
                <hr className="sidebar-divider my-0"/>
                <ul className="navbar-nav text-light" id="accordionSidebar">
                    <li className='nav-item'><Link to='/portal/dashboard'
                                                   className={`nav-link${isActiveRoute('/portal/dashboard') ? ' active' : ''}`}>
                        <i className="fas fa-tachometer-alt"></i><span>Dashboard</span></Link></li>
                    {userRole === 1 || staffRole === 1 || staffRole === 3 || staffRole === 2 ? (<>
                        <hr></hr>
                        <li className='nav-item'>
                            <Link
                                to={`/portal/user-profile/${userId}`}
                                className={`nav-link ${isActiveRoute(`/portal/user-profile/${userId}`) ? 'active' : ''}`}
                            >
                                <i className="fas fa-user"></i><span>Profile</span>
                            </Link>
                        </li>
                    </>) : null}
                    {userRole === 1 || staffRole === 1 ? (<>
                        <hr></hr>

                        <NavDropdown
                            title={<><i className="fas fa-users"></i><span>Team</span></>}
                            menuVariant="dark"
                            drop='down-centered'
                        >
                            <NavDropdown.Item>
                                <Link to='/portal/manage-team/staff'
                                      className={`nav-link${isActiveRoute('/portal/manage-team/staff') ? ' active' : ''}`}>
                                    <i className="fas fa-user"></i><span>Staff</span></Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Link to='/portal/manage-team/salesmanager'
                                      className={`nav-link${isActiveRoute('/portal/manage-team/salesmanager') ? ' active' : ''}`}>
                                    <i className="fas fa-user-tie"></i><span>Realty</span></Link>
                            </NavDropdown.Item>
                        </NavDropdown>
                    </>) : null}
                    {userRole === 1 || userRole === 3 || userRole === 4 || staffRole === 1 || staffRole === 3 ? (<>
                        <hr></hr>
                        <li className='nav-item'><Link to='/portal/projects'
                                                       className={`nav-link${isActiveRoute('/portal/projects') ? ' active' : ''}`}>
                            <i className="fas fa-home"></i><span>Projects</span></Link></li>
                    </>) : null}
                    {userRole === 1 || staffRole === 1 || staffRole === 1 || staffRole === 3 ? (
                        <>
                            <hr></hr>
                            <li className='nav-item'><Link to='/portal/designs'
                                                           className={`nav-link${isActiveRoute('/portal/designs') ? ' active' : ''}`}>
                                <i className="fa fa-archive"></i><span>Designs</span></Link></li>
                            <hr></hr>
                            <li className='nav-item'><Link to='/portal/blogs'
                                                           className={`nav-link${isActiveRoute('/portal/blogs') ? ' active' : ''}`}>
                                <i className="fa fa-rss"></i><span>Blogs</span></Link></li>
                        </>
                    ) : null}
                    {userRole === 1 || userRole === 3 || userRole === 4 || staffRole === 1 || staffRole === 2 ? (<>
                        <hr></hr>
                        <li className='nav-item'><Link to='/portal/transactions'
                                                       className={`nav-link${isActiveRoute('/portal/transactions') ? ' active' : ''}`}>
                            <i className="fas fa-database"></i><span>Transactions</span></Link></li>
                        <hr></hr>
                        <li className='nav-item'><Link to='/portal/payroll'
                                                       className={`nav-link${isActiveRoute('/portal/payroll') ? ' active' : ''}`}>
                            <i className="fas fa-tags"></i><span>Commission</span></Link></li>
                        <hr></hr>
                        <li className='nav-item'><Link to='/portal/incentives'
                                                       className={`nav-link${isActiveRoute('/portal/incentives') ? ' active' : ''}`}>
                            <i className="fas fa-hand-holding-usd"></i><span>Incentives</span></Link></li>
                    </>) : null}
                    <hr></hr>
                    <li className='nav-item'><Link to='/portal/buyers'
                                                   className={`nav-link${isActiveRoute('/portal/buyers') ? ' active' : ''}`}>
                        <i className="fas fa-users"></i><span>Buyers Information</span></Link></li>
                    <hr></hr>
                    <br/>
                    <li className="nav-item"><Link className="nav-link" to='/' onClick={handleLogout}><i
                        className="fas fa-sign-out-alt"></i><span>Logout</span></Link>
                    </li>
                    <hr></hr>
                </ul>
            </div>
        </nav>);
}

export default Header;
