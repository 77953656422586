import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import ReactPaginate from "react-paginate";
import {Button, Modal} from "react-bootstrap";
import {NumericFormat} from "react-number-format";

function TransactionHistory() {
    const token = localStorage.getItem("token");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true)

    const [data, setData] = useState([])

    const {id} = useParams();
    const {property_name} = useParams();

    const [showAddModal, setAddModal] = useState(false);
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);
    const [isModifying, setModifying] = useState(false);

    const pageCount = Math.ceil(total / pageSize);

    const [reloadData, setReloadData] = useState(false);

    const [fileUrl, setFileUrl] = useState('');
    const [formData, setFormData] = useState({});

    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.role;
    }

    const userRole = getUserRole(token);

    function getUserId(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.id;
    }

    const userId = getUserId(token);
    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/transaction-history/${id}?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
                setReloadData(false);
            })
    }, [pageNumber, pageSize, searchTerm, token, reloadData, id])

    function confirmAddData(event) {
        event.preventDefault()
        setModifying(true)

        const image = event.target.elements.image.files[0];
        const imageFileName = image.name;

        const fileExtension = imageFileName.toLowerCase().split('.').pop();
        if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
            setFormData({
                transaction_id: event.target.elements.transaction_id.value,
                remarks: event.target.elements.remarks.value,
                total_amount: event.target.elements.total_amount.value,
                amount: event.target.elements.amount.value,
                image: image,
                image_name: imageFileName,
            });
            const fileUrl = URL.createObjectURL(image);
            setFileUrl(fileUrl);
            setAddConfirmModal(true);
        } else {
            // Display an error toast for invalid file type
            toast.error('Invalid file type. Only image files are allowed.');
            setModifying(false);
        }
    }

    function handleAddData() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/transaction-history`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const newData = response.data.new_data;
                    setData(prevData => [newData, ...prevData]);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setModifying(false)
                setAddModal(false)
            })
    }

    return (
        <>
            <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Transaction History
                of {property_name}</h3>
            <div className="card shadow border-primary mb-3 mx-4">
                <div className="card-header">
                    <p className="text-primary m-0 fw-bold d-inline">Transaction History Information</p>
                    <button className="btn btn-primary text-end float-end btn-sm" onClick={() => {
                        setAddModal(true)
                    }}>Add New Payment
                    </button>
                </div>
                <div className="card-body">
                    <div className="row g-3">

                    </div>

                    <div className="table-responsive table mt-2" id="dataTable" role="grid"
                         aria-describedby="dataTable_info">
                        <table className="table my-0" id="dataTable">
                            <thead>
                            <tr>
                                <th>Receipt</th>
                                <th>Total Amount</th>
                                <th>Net Amount</th>
                                <th>Remarks</th>
                                <th>Date</th>
                            </tr>
                            </thead>
                            <tbody className='table-group-divider'>
                            {data.length === 0 ? (<tr>
                                <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                            </tr>) : (data.map((data) => (<tr key={data.id}>
                                <td><img src={`${process.env.REACT_APP_API_URL}/uploaded_img/${data.receipt}`}
                                         className='rounded-1 img-fluid img-thumbnail'
                                         alt="Thumbnail" style={{
                                    width: '50px',
                                    height: '50px',
                                }}/>
                                </td>
                                <td>{data.total_amount}</td>
                                <td>{data.amount}</td>
                                <td>{data.remarks}</td>
                                <td>{data.created_date}</td>
                            </tr>)))}
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        containerClassName="pagination justify-content-center mt-3"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        breakClassName="page-item"
                        disabledClassName="disabled"
                    />
                </div>
            </div>

            <Modal
                size="lg"
                show={showAddModal}
                onHide={() => {
                    setAddModal(false);
                }}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add New Payment
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddData} encType="multipart/form-data">
                        <input className="form-control" name='transaction_id'
                               id='transaction_id' value={id} required hidden/>
                        <label className="form-label">Remarks (Do not use the '%' symbol)</label>
                        <textarea className="form-control" name='remarks'
                               id='remarks'
                               required/>
                        <label className="form-label">Total Amount</label>
                        <NumericFormat
                            className="form-control"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            name="total_amount" id="total_amount"
                            required
                        />
                        <label className="form-label">Net Total Amount</label>
                        <NumericFormat
                            className="form-control"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            name="amount" id="amount"
                            required
                        />
                        <label className="form-label">Receipt</label>
                        <input className="form-control" type="file" name="image" id="image"/>
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Project Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Remarks:</strong> {formData.remarks}
                    </p>
                    <p><strong>Total Amount:</strong> {formData.total_amount}</p>
                    <p><strong>Net total Amount:</strong> {formData.amount}</p>
                    <img src={fileUrl} alt="brand_logo" style={{maxWidth: '100%', height: 'auto'}}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setAddConfirmModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setAddConfirmModal(false);
                        handleAddData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default TransactionHistory;