import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../../others/Loading";
import {Button, Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import "../../../assets/css/propertyimages.css";

function PropertyImages() {
    const token = localStorage.getItem("token");
    const [isLoading, setLoading] = useState(true)
    const [images, setImages] = useState([]);
    const [showAddImageModal, setShowAddImageModal] = useState(false);

    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedPreviewImage, setSelectedPreviewImage] = useState(null);

    const {id} = useParams();
    const {property_name} = useParams();

    const [selectedImageFiles, setSelectedImageFiles] = useState([]);
    const [previewImageUrls, setPreviewImageUrls] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/property-images/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setImages(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [id, token])

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedImageFiles(files);

        // Create preview URLs for each selected file
        const urls = files.map((file) => URL.createObjectURL(file));
        setPreviewImageUrls(urls);
    };

    const handleAddImage = async () => {
        if (!selectedImageFiles) {
            return;
        }
        const formData = new FormData();
        selectedImageFiles.forEach((file, index) => {
            formData.append('image', file);
        });
        axios.post(`${process.env.REACT_APP_API_URL}/add/property-images/${id}`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data',
            }
        })
            .then((response) => {
                const uploadedImages = response.data.image_urls.map(item => item.image_data); // Extracting image URLs
                setImages(prevImages => [...prevImages, ...uploadedImages]);
                setSelectedImageFiles(null);
                setPreviewImageUrls(null)
                setShowAddImageModal(false)
                toast.success('Images added successfully.');
            })
            .catch((error) => {
                console.error('Error uploading images:', error);
                toast.error('An error occurred while adding the image.');
            });
    };


    const handleImageClick = (image) => {
        setSelectedPreviewImage(image);
        setShowImageModal(true);
    };

    function handleDeleteImage(id) {
        setShowImageModal(false); // Hide the modal first
        setSelectedPreviewImage(null); // Reset the selected image

        fetch(`${process.env.REACT_APP_API_URL}/delete/property-image/${id}`, {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.ok) {
                    const deletedData = images.filter(item => item.id !== id);
                    setImages(deletedData);
                    toast.success('Image removed successfully.');
                } else {
                    throw new Error('Failed to delete image');
                }
            })
            .catch(error => {
                console.error(error);
                toast.error('An error occurred while deleting data.');
            });
    }


    if (isLoading) {
        return (<Loading/>);
    }

    return (<>
        <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Manage Property Images
            of {property_name}</h3>
        <div className="image-container">
            {images && images.length > 0 ? (<div className="image-gallery">
                {images.map((image) => (<img
                    key={image.id}
                    src={`${process.env.REACT_APP_API_URL}/uploaded_img/${image.image}`}
                    alt="Loading..."
                    className={`property-image`}
                    onClick={() => handleImageClick(image)}
                />))}
            </div>) : (<h3 className='text-center'>No uploaded images. Upload your first image.</h3>)}
            <div className="image-controls d-flex justify-content-end mt-2">
                <button className='btn btn-primary btn-sm mx-2' onClick={() => setShowAddImageModal(true)}>Add
                    Image
                </button>
            </div>
        </div>

        <Modal show={showAddImageModal} onHide={() => {
            setShowAddImageModal(false);
            setSelectedImageFiles(null);
            setPreviewImageUrls(null); // Clear the preview URL when closing the modal
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Add Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input className="form-control" type="file" accept=".jpeg, .jpg, .png" required multiple
                       onChange={handleFileChange}/>
                {previewImageUrls && previewImageUrls.length > 0 ? (
                    <div className="mt-3">
                        {previewImageUrls.map((imageUrl, index) => (
                            <div key={index} style={{display: 'inline-block', margin: '5px'}}>
                                <img
                                    src={imageUrl}
                                    alt={`Preview ${index}`}
                                    style={{maxWidth: '100px', maxHeight: '100px'}}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div>
                        No images selected
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleAddImage} disabled={!selectedImageFiles}>
                    Add Image
                </Button>
                <Button variant="secondary" onClick={() => {
                    setShowAddImageModal(false);
                    setSelectedImageFiles(null);
                    setPreviewImageUrls(null);
                }}>
                    Cancel
                </Button>

            </Modal.Footer>
        </Modal>

        <Modal show={showImageModal} onHide={() => setShowImageModal(false)}>
            {selectedPreviewImage && ( // Only render if selectedPreviewImage is not null
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Preview Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <img
                            src={`${process.env.REACT_APP_API_URL}/uploaded_img/${selectedPreviewImage.image}`}
                            alt="Preview"
                            className="modal-image"
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => handleDeleteImage(selectedPreviewImage.id)}>
                            Delete Image
                        </Button>
                        <Button variant="secondary" onClick={() => setShowImageModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </>)}
        </Modal>


    </>)
}

export default PropertyImages