import {CategoryScale, Chart, LinearScale, LineController, LineElement, PointElement, Title} from 'chart.js';
import axios from "axios";
import {toast} from "react-toastify";
import {useCallback, useEffect, useState} from "react";
import {Line} from 'react-chartjs-2';
import Loading from "../others/Loading";
import {useNavigate} from "react-router-dom";

function Dashboard() {
    Chart.register(LineController, LinearScale, Title, CategoryScale, PointElement, LineElement);
    const token = localStorage.getItem("token");

    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.role;
    }

    const userRole = getUserRole(token);

    function getStaffRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.staff_role;
    }

    const staffRole = getStaffRole(token);

    function getUserId(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.id;
    }

    const userId = getUserId(token);

    function getAffiliatedId(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.affiliated_id;
    }

    const [isLoading, setLoading] = useState(true)

    const affiliated_id = getAffiliatedId(token);

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const affiliatedLink = `${baseUrl}/${affiliated_id}`;

    const [isCopied, setIsCopied] = useState(false);

    const getMonthName = useCallback((monthNumber) => {
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December',];
        return monthNames[monthNumber - 1];
    }, []);

    const [annual_units, setAnnualUnits] = useState('');
    const [monthly_units, setMonthlyUnits] = useState('');
    const [weekly_units, setWeeklyUnits] = useState('');

    const [annual_units_peso, setAnnualUnitsPeso] = useState('0.00');
    const [monthly_units_peso, setMonthlyUnitsPeso] = useState('0.00');
    const [weekly_units_peso, setWeeklyUnitsPeso] = useState('0.00');

    const [topSalesperson, setTopSalesperson] = useState([]);
    const [topSalesmanager, setTopSalesmanager] = useState([]);
    const [monthlyDataGraph, setMonthlyDataGraph] = useState([]);
    const [monthlyDataGraphPeso, setMonthlyDataGraphPeso] = useState([]);
    const [yearlyDataGraph, setYearlyDataGraph] = useState([]);
    const [dashboardFilter, setDashboardFilter] = useState('Monthly')
    const navigate = useNavigate();

    // Function to copy text to clipboard using Clipboard API
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(affiliatedLink);
            setIsCopied(true);
            toast.success("Link copied to clipboard");
        } catch (error) {
            console.error("Unable to copy to clipboard: ", error);
            toast.error("Unable to copy to clipboard");
        }
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/dashboard?filter=${dashboardFilter}&userRole=${userRole}&userId=${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setAnnualUnits(response.data.annual_unit)
                setMonthlyUnits(response.data.monthly_unit)
                setWeeklyUnits(response.data.weekly_unit)
                setAnnualUnitsPeso(response.data.annual_unit_peso)
                setMonthlyUnitsPeso(response.data.monthly_unit_peso)
                setWeeklyUnitsPeso(response.data.weekly_unit_peso)
                setTopSalesperson(response.data.top_salesperson)
                setTopSalesmanager(response.data.top_salesmanager)
                setMonthlyDataGraph(response.data.graph_data)
                setMonthlyDataGraphPeso(response.data.graph_data_peso)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [token, dashboardFilter, userRole, userId])

    console.log(monthlyDataGraphPeso)

    const data = {
        datasets: [{
            label: 'Units Sold', data: monthlyDataGraph.map((item) => item.units_sold), // Extract units sold
            fill: true, borderColor: 'rgba(75,192,192,1)', backgroundColor: 'rgba(75,192,192,0.4)',
        },],
    };

    const data2 = {
        datasets: [{
            label: 'Units Sold', data: monthlyDataGraphPeso.map((item) => item.total_tcp), // Extract units sold
            fill: true, borderColor: 'rgba(75,192,192,1)', backgroundColor: 'rgba(75,192,192,0.4)',
        },],
    };

    if (dashboardFilter === "Monthly") {
        data.labels = monthlyDataGraph.map((item) => getMonthName(item.month));
        data2.labels = monthlyDataGraphPeso.map((item) => getMonthName(item.month));
    } else {
        data.labels = monthlyDataGraph.map((item) => item.year);
        data2.labels = monthlyDataGraphPeso.map((item) => item.year);
    }


    const options = {
        scales: {
            y: {
                precision: 0
            },
        },
    };


    if (dashboardFilter === "Monthly") {
        options.scales.x = {
            type: 'category', labels: data.labels,
        };
    }

    function handleExtrationTool() {
        navigate(`/portal/data-extraction-tool`);
    }

    function handleSiteTrippingSchedule() {
        navigate(`/portal/site-tripping-schedule`);
    }

    function handleProspectedClients() {
        navigate(`/portal/submited-forms`);
    }

    function handleExpenseLedger() {
        navigate(`/portal/expense-ledger`);
    }


    if (isLoading) {
        return (<Loading/>);
    }

    return (<>
        <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Dashboard</h3>
        <div className='mb-3 mx-4'>
            <div className="row mb-3 mx-4">
                <div className="col">
                    <div className="card shadow border-start-success py-2">
                        <div className="card-body">
                            <div className="row align-items-center no-gutters">
                                <div className="col me-2">
                                    <div className="text-uppercase text-primary fw-semibold mb-2">
                                        <span>Annual Sales (in units)</span>
                                    </div>
                                    <div className="text-dark fw-bold h5 mb-0">
                                        <span>{annual_units}</span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-coins fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card shadow border-start-success py-2">
                        <div className="card-body">
                            <div className="row align-items-center no-gutters">
                                <div className="col me-2">
                                    <div className="text-uppercase text-primary fw-semibold mb-2">
                                        <span>Monthly Sales (in units)</span>
                                    </div>
                                    <div className="text-dark fw-bold h5 mb-0">
                                        <span>{monthly_units}</span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-coins fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card shadow border-start-success py-2">
                        <div className="card-body">
                            <div className="row align-items-center no-gutters">
                                <div className="col me-2">
                                    <div className="text-uppercase text-primary fw-semibold mb-2">
                                        <span>Weekly Sales (in units)</span>
                                    </div>
                                    <div className="text-dark fw-bold h5 mb-0">
                                        <span>{weekly_units}</span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-coins fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {userRole === 1 || userRole === 2 ? (
                <div className="row mb-3 mx-4">
                    <div className="col">
                        <div className="card shadow border-start-success py-2">
                            <div className="card-body">
                                <div className="row align-items-center no-gutters">
                                    <div className="col me-2">
                                        <div className="text-uppercase text-primary fw-semibold mb-2">
                                            <span>Annual Sales (in peso)</span>
                                        </div>
                                        <div className="text-dark fw-bold h5 mb-0">
                                            <span>₱ {annual_units_peso}</span>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card shadow border-start-success py-2">
                            <div className="card-body">
                                <div className="row align-items-center no-gutters">
                                    <div className="col me-2">
                                        <div className="text-uppercase text-primary fw-semibold mb-2">
                                            <span>Monthly Sales (in peso)</span>
                                        </div>
                                        <div className="text-dark fw-bold h5 mb-0">
                                            <span>₱ {monthly_units_peso}</span>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card shadow border-start-success py-2">
                            <div className="card-body">
                                <div className="row align-items-center no-gutters">
                                    <div className="col me-2">
                                        <div className="text-uppercase text-primary fw-semibold mb-2">
                                            <span>Weekly Sales (in peso)</span>
                                        </div>
                                        <div className="text-dark fw-bold h5 mb-0">
                                            <span>₱ {weekly_units_peso}</span>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            <br/>
            <div>
                <select className="form-select"
                        value={dashboardFilter}
                        onChange={(e) => setDashboardFilter(e.target.value)}
                        required>
                    <option value="Monthly">Monthly</option>
                    <option value="Annual">Annual</option>
                </select>
            </div>
            {userRole === 1 || staffRole === 1 ? (<>
                <button
                    className="btn btn-warning btn-sm mt-3 me-3 " onClick={() => handleExtrationTool()}
                >
                    Data Extractor Tool
                </button>
                <button
                    className="btn btn-warning btn-sm mt-3 me-3" onClick={() => handleSiteTrippingSchedule()}
                >
                    Site Tripping Schedules
                </button>
                <button
                    className="btn btn-warning btn-sm mt-3 me-3" onClick={() => handleProspectedClients()}
                >
                    Website Leads
                </button>
                <button
                    className="btn btn-warning btn-sm mt-3 me-3" onClick={() => handleExpenseLedger()}
                >
                    Expense Ledger
                </button>
            </>) : null}
            <br/>
            <div className="row mb-3 mx-4">
                <div className="col-md-4 mx-auto">
                    <h2 className="text-center mb-4 ">Top 10 Realty ({dashboardFilter})</h2>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Total Sale</th>
                            </tr>
                            </thead>
                            <tbody>
                            {topSalesmanager.map((data, index) => (<tr key={data.id}>
                                <td>{index + 1}</td>
                                <td>{data.first_name} {data.last_name} {data.suffix}</td>
                                <td>{data.transaction_count}</td>
                            </tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <div className='row'>
                <div className='col-xl'>
                    <h2 className="text-center mb-4">{dashboardFilter} Sales (in Units) in Graph</h2>
                    <div>
                        <Line data={data} options={options}/>
                    </div>
                </div>
                {userRole === 1 || userRole === 2 ? (
                    <div className='col-xl'>
                        <h2 className="text-center mb-4">{dashboardFilter} Sales (in Peso) in Graph</h2>
                        <div>
                            <Line data={data2} options={options}/>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>

    </>)
}

export default Dashboard