import {useEffect} from "react";

function DesignViewData({data}) {
    useEffect(() => {
        if (document.querySelectorAll('[data-bss-baguettebox]').length > 0) {
            window.baguetteBox.run('[data-bss-baguettebox]', {animation: 'slideIn'});
        }
    }, [data]);

    const filteredAmenitiesInterior = data.design_amenities.filter(
        amenity => amenity.designamenitiestype.id === 1
    );
    const filteredAmenitiesArea = data.design_amenities.filter(
        amenity => amenity.designamenitiestype.id === 2
    );
    const filteredAmenitiesExterior = data.design_amenities.filter(
        amenity => amenity.designamenitiestype.id === 3
    );

    let embeddedUrl = '';
    if (data.yt_video && data.yt_video.trim() !== '') {
        const videoId = data.yt_video.split('v=')[1];
        const ampersandPosition = videoId.indexOf('&');
        if (ampersandPosition !== -1) {
            embeddedUrl = `https://www.youtube.com/embed/${videoId.substring(0, ampersandPosition)}`;
        } else {
            embeddedUrl = `https://www.youtube.com/embed/${videoId}`;
        }
    }
    return (
        <>
            <section data-aos="fade-up" style={{background: '#ffffff', paddingTop: '50px', paddingBottom: '100px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12" style={{textAlign: 'center'}}>
                            <figure className="media">
                                <div data-oembed-url={embeddedUrl}>
                                    <div style={{position: 'relative', paddingBottom: '56.2493%', height: 0}}>
                                        <iframe
                                            src={embeddedUrl}
                                            style={{
                                                position: 'absolute',
                                                width: '100%',
                                                height: '100%',
                                                top: 0,
                                                left: 0
                                            }}
                                            frameBorder="0"
                                            allow="autoplay; encrypted-media"
                                            allowFullScreen=""
                                        ></iframe>
                                    </div>
                                </div>
                            </figure>
                        </div>
                        <div className="col-xl-12" style={{textAlign: 'center'}}>
                            <h1 style={{paddingTop: '15px', color: '#805b43', paddingBottom: '10px'}}>{data.title}</h1>
                        </div>
                        <div className="col-xl-12">
                            <hr/>
                        </div>
                        <div className="col text-start" style={{textAlign: 'center'}}>
                            <h2 className="text-start" style={{color: '#805b43'}}>Description</h2>
                            <div className="col" style={{fontFamily: 'system-ui'}}
                                 dangerouslySetInnerHTML={{__html: data.content}}></div>
                        </div>
                        <div className="col-xl-12">
                            <hr/>
                        </div>
                        <div className="col text-start" style={{textAlign: 'center'}}>
                            <h2 className="text-center" style={{color: '#805b43'}}>Design amenities</h2>
                            <div className="row">
                                <div className="col-xl-6 text-start" style={{textAlign: 'left'}}>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-sm">
                                            <caption style={{textAlign: 'center', textDecoration: 'underline'}}
                                                     className="caption-top">AREA &amp; LOT
                                            </caption>
                                            <tbody>
                                            {filteredAmenitiesArea.map((amenity, index) => (
                                                <tr key={amenity.id}>
                                                    <td> {amenity.name}</td>
                                                    <td style={{fontFamily: 'system-ui'}}
                                                        className='text-end'>{amenity.description}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-xl-6" style={{textAlign: 'left'}}>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-sm">
                                            <caption style={{textAlign: 'center', textDecoration: 'underline'}}
                                                     className="caption-top">INTERIOR
                                            </caption>
                                            <tbody>
                                            {filteredAmenitiesInterior.map((amenity, index) => (
                                                <tr key={amenity.id}>
                                                    <td>{amenity.name}</td>
                                                    <td style={{fontFamily: 'system-ui'}}
                                                        className='text-end'>{amenity.description}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-sm">
                                            <caption style={{
                                                textAlign: 'center',
                                                textDecoration: 'underline',
                                                fontWeight: 'bold'
                                            }} className="caption-top"><span
                                                style={{fontWeight: 'normal !important'}}>EXTERIOR</span></caption>
                                            <tbody>
                                            {filteredAmenitiesExterior.map((amenity, index) => (
                                                <tr key={amenity.id}>
                                                    <td>{amenity.name}</td>
                                                    <td style={{fontFamily: 'system-ui'}}
                                                        className='text-end'>{amenity.description}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <hr/>
                        </div>
                        <div className="col text-start" style={{textAlign: 'center'}}>
                            <h2 className="text-center" style={{color: '#805b43'}}>Design Gallery image</h2>
                            <section className="photo-gallery py-4 py-xl-5">
                                <div className="container">
                                    <div className="row gx-2 gy-2 row-cols-1 row-cols-md-2 row-cols-xl-3 photos"
                                         data-bss-baguettebox="">
                                        {data.design_image && data.design_image.length > 0 ? (
                                            <>
                                                {data.design_image.map((image) => (
                                                    <div className="col item" key={image.id}><a
                                                        href={`${process.env.REACT_APP_API_URL}/uploaded_img/${image.image}`}><img
                                                        className="img-fluid"
                                                        src={`${process.env.REACT_APP_API_URL}/uploaded_img/${image.image}`}/></a>
                                                    </div>
                                                ))}
                                            </>

                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DesignViewData;