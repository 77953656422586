import '../../assets/css/mainlayout.min.css'
import UserRoleContext from "../others/UserRoleContext";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import Loading from "../others/Loading";
import {Helmet} from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";


function Body({element, allowedRoles}) {
    const token = localStorage.getItem("token");
    const authenticated = Boolean(token);
    const exp = token ? JSON.parse(atob(token.split(".")[1])).exp : 0;
    const isExpired = exp && exp * 1000 < Date.now();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true)


    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.role;
    }

    const userRole = getUserRole(token);


    useEffect(() => {
        if (isExpired) {
            localStorage.removeItem("token");
            navigate("/");
            if (isExpired) {
                toast.error("Your session has expired. Please log in again.");
            }
        }
    }, [authenticated, isExpired, navigate]);

    useEffect(() => {
        if ((allowedRoles && !allowedRoles.includes(userRole)) && !authenticated) {
            navigate("/");
            toast.error(
                "You cannot access this page."
            );
        } else {
            setLoading(false)
        }

    }, [authenticated, allowedRoles, userRole, navigate]);

    useEffect(() => {
        if (allowedRoles && !allowedRoles.includes(userRole)) {
            navigate("/portal/dashboard");
            toast.error(
                "Role not permitted."
            );
        } else {
            setLoading(false)
        }

    }, [allowedRoles, userRole, navigate]);

    if (isLoading) {
        return (
            <Loading/>
        );
    }


    return (
        <>
            <UserRoleContext.Provider value={userRole}>
                <div id="wrapper">
                    <Header/>
                    <div className="d-flex flex-column" id="content-wrapper">
                        <div id="content">
                            <Helmet>
                                <meta name="robots" content="noindex, nofollow"/>
                            </Helmet>
                            {element}
                        </div>
                        <Footer/>
                    </div>
                </div>
            </UserRoleContext.Provider>
        </>

    )
}

export default Body