import {useCallback, useEffect, useState} from "react";
import axios from "axios";
import Loading from "../others/Loading";
import ReactPaginate from "react-paginate";
import {Line} from "react-chartjs-2";
import {CategoryScale, Chart, LinearScale, LineController, LineElement, PointElement, Title} from "chart.js";

function WebsiteLeads() {
    Chart.register(LineController, LinearScale, Title, CategoryScale, PointElement, LineElement);

    const [monthlyDataGraph, setMonthlyDataGraph] = useState([]);

    const token = localStorage.getItem("token");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState([])

    const pageCount = Math.ceil(total / pageSize);
    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/siteleads?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pageNumber, pageSize, searchTerm, token])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/siteleads/graph`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setMonthlyDataGraph(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [token])

    const getMonthName = useCallback((monthNumber) => {
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December',];
        return monthNames[monthNumber - 1];
    }, []);

    const data_graph = {
        labels: monthlyDataGraph.map((item) => getMonthName(item.month)), datasets: [{
            label: 'Lead Count',
            data: monthlyDataGraph.map((item) => item.count_lead),
            fill: true,
            borderColor: 'rgba(75,192,192,1)',
            backgroundColor: 'rgba(75,192,192,0.4)',
        },],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true, precision: 0, ticks: {
                    stepSize: 1
                }
            },
        },
    };

    console.log(monthlyDataGraph);

    if (isLoading) {
        return (<Loading/>);
    }

    return (<>
        <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Website Leads</h3>
        <div className="card shadow border-primary mb-3 mx-4">
            <div className="card-body">
                <div className="row g-3">
                    <div className='col-md-11'>
                        <input type="text" className="form-control" placeholder="Search Name!"
                               aria-label="Search"
                               aria-describedby="basic-addon2" value={searchTerm}
                               onChange={e => setSearchTerm(e.target.value)}/>
                    </div>
                    <div className='col-md'>
                        <select className="form-control" value={pageSize} onChange={e => {
                            setPageSize(Number(e.target.value));
                            setPageNumber(0); // Reset the page number when the page size changes
                        }}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                </div>

                <div className="table-responsive table mt-2" id="dataTable" role="grid"
                     aria-describedby="dataTable_info">
                    <table className="table my-0" id="dataTable">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Message</th>
                            <th>Property Code</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody className='table-group-divider'>
                        {data.length === 0 ? (<tr>
                            <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                        </tr>) : (data.map((data) => (<tr key={data.id}>
                            <td>{data.name}</td>
                            <td>{data.phone}</td>
                            <td>{data.email}</td>
                            <td>{data.message}</td>
                            <td>{data.property_code}</td>
                            <td>{data.created_date}</td>
                        </tr>)))}
                        </tbody>
                    </table>
                </div>
                <ReactPaginate
                    pageCount={pageCount}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    containerClassName="pagination justify-content-center mt-3"
                    activeClassName="active"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    breakLinkClassName="page-link"
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    breakClassName="page-item"
                    disabledClassName="disabled"
                />
            </div>


        </div>
        <section>
            <h2 className="text-center mb-4">Leads in Graph (Monthly)</h2>
            <Line data={data_graph} options={options}/>
        </section>
    </>)
}

export default WebsiteLeads