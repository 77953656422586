import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../others/Loading";
import ReactPaginate from "react-paginate";
import {Button, Modal} from "react-bootstrap";
import {NumericFormat} from "react-number-format";
import {toast} from "react-toastify";
import {Typeahead} from "react-bootstrap-typeahead";
import {useNavigate, useParams} from "react-router-dom";

function PropertyListing() {
    const token = localStorage.getItem("token");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true)

    const {id} = useParams();
    const {project_name} = useParams();

    const [data, setData] = useState([])
    const [formData, setFormData] = useState({});

    const [showAddModal, setAddModal] = useState(false);
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);
    const [isModifying, setModifying] = useState(false);

    const [selectedPropertyOwner, setSelectedPropertyOwner] = useState([]);
    const [propertyOwners, setPropertyOwners] = useState([]);
    const [isAsyncLoading, setAsyncLoading] = useState(false);

    const [selectedPropertyType, setSelectedPropertyType] = useState([]);
    const [propertyType, setPropertyType] = useState([]);

    const [selectedPropertyOwnerType, setSelectedPropertyOwnerType] = useState([]);
    const [propertyOwnerType, setPropertyOwnerType] = useState([]);

    const [formData2, setFormData2] = useState({});
    const [showAddOwnerModal, setAddOwnerModal] = useState(false);
    const [showAddConfrimOwnerModal, setAddConfirmOwnerModal] = useState(false);
    const [isModifying2, setModifying2] = useState(false);

    const [formData3, setFormData3] = useState({});
    const [showAddPropertyTypeModal, setAddPropertyTypeModal] = useState(false);
    const [showAddConfrimPropertyTypeModal, setAddConfirmPropertyTypeModal] = useState(false);
    const [isModifying3, setModifying3] = useState(false);

    const [update_id, setUpdateId] = useState('')
    const [showEditModal, setEditModal] = useState(false);
    const [showEditConfrimModal, setEditConfirmModal] = useState(false);


    const navigate = useNavigate();


    const pageCount = Math.ceil(total / pageSize);
    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.role;
    }

    const userRole = getUserRole(token);

    function getUserId(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.id;
    }

    const userId = getUserId(token);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/propertylisting/${id}?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pageNumber, pageSize, searchTerm, token, id])

    const searchPropertyOwner = async (query) => {
        setAsyncLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/property-owners/typeahead?search=${query}&limit=10`, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            const data = response.data;
            setPropertyOwners(data.map((data) => ({
                labelKey: `${data.name}`, id: data.id
            })));
        } catch (error) {
            console.error('Error fetching Property Owners', error);
        }
        setAsyncLoading(false);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/property-type/typeahead`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                const data = response.data;
                setPropertyType(data.map((data) => ({
                    labelKey: `${data.name}`, id: data.id
                })))
            })
            .catch(error => {
                console.log(error)
            })
    }, [token])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/property-owner-type/typeahead`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                const data = response.data;
                setPropertyOwnerType(data.map((data) => ({
                    labelKey: `${data.name}`, id: data.id
                })))
            })
            .catch(error => {
                console.log(error)
            })
    }, [token])

    function confirmAddData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            property_owner_name: project_name,
            property_owner_id: id,
            property_type: selectedPropertyType[0].labelKey,
            property_type_id: selectedPropertyType[0].id,
            property_name: formData.get("property_name"),
            address: formData.get("address"),
            price: formData.get("price"),
        };
        setFormData(data);
        setAddConfirmModal(true);
    }

    function handleAddData() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/propertylisting`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const newData = response.data.new_data;
                    setData(prevData => [newData, ...prevData]);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setModifying(false)
                setAddModal(false)
            })
    }


    function confirmAddDataPropertyType(event) {
        event.preventDefault()
        setModifying3(true)

        const formData = new FormData(event.target);

        const data = {
            property_type_name: formData.get("property_type_name"),
            property_type_description: formData.get("property_type_description"),
        };
        setFormData3(data);
        setAddConfirmPropertyTypeModal(true);
    }

    function handleAddDataPropertyType() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/propertytype`, formData3, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const newData = response.data.new_data;
                    setPropertyType(prevData => [newData, ...prevData]);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setModifying3(false)
                setAddPropertyTypeModal(false)
                setAddModal(true)
            })
    }

    function confirmEditData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            property_owner_name: project_name,
            property_owner_id: id,
            property_type: selectedPropertyType[0].labelKey,
            property_type_id: selectedPropertyType[0].id,
            property_name: formData.get("property_name"),
            address: formData.get("address"),
            price: formData.get("price"),
            status: formData.get("status"),
        };
        setFormData(data);
        setEditConfirmModal(true);
    }

    function handleEditData() {
        axios.put(`${process.env.REACT_APP_API_URL}/update/property/${update_id}`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const updatedData = response.data.updated_data;
                    const updatedIndex = data.findIndex(item => item.id === updatedData.id);
                    const updatedData2 = [...data];
                    updatedData2[updatedIndex] = updatedData;
                    setData(updatedData2);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setEditModal(false)
                setUpdateId('')
            })
    }

    function handleViewProperty(id) {
        navigate(`/portal/property-view/${id}`);
    }

    function handlePropertyDocs(id, property_name) {
        const encodedPropertyName = encodeURIComponent(property_name);
        navigate(`/portal/property-documents/${id}/${encodedPropertyName}`);
    }

    function handleBlockLot(id, property_name, price) {
        const encodedPropertyName = encodeURIComponent(property_name);
        navigate(`/portal/property-block-lot/${id}/${encodedPropertyName}/${price}`);
    }

    if (isLoading) {
        return (<Loading/>);
    }

    return (<>
        <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Property Listings of
            Project {project_name}</h3>
        <div className="card shadow border-primary mb-3 mx-4">
            <div className="card-header">
                <p className="text-primary m-0 fw-bold d-inline">Property Information</p>
                {userRole === 1 || userRole === 2 ? (
                    <button className="btn btn-primary text-end float-end btn-sm" onClick={() => {
                        setAddModal(true)
                    }}>Add New Property
                    </button>) : null}
            </div>
            <div className="card-body">
                <div className="row g-3">
                    <div className='col-md-11'>
                        <input type="text" className="form-control" placeholder="Search Property Name!"
                               aria-label="Search"
                               aria-describedby="basic-addon2" value={searchTerm}
                               onChange={e => setSearchTerm(e.target.value)}/>
                    </div>
                    <div className='col-md'>
                        <select className="form-control" value={pageSize} onChange={e => {
                            setPageSize(Number(e.target.value));
                            setPageNumber(0); // Reset the page number when the page size changes
                        }}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                </div>

                <div className="table-responsive table mt-2" id="dataTable" role="grid"
                     aria-describedby="dataTable_info">
                    <table className="table my-0" id="dataTable">
                        <thead>
                        <tr>
                            <th>Property Name</th>
                            <th>Address</th>
                            <th>Price</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody className='table-group-divider'>
                        {data.length === 0 ? (<tr>
                            <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                        </tr>) : (data.map((data) => (<tr key={data.id}>
                            <td>{data.name}</td>
                            <td>{data.address}</td>
                            <td>{data.price}</td>
                            <td>{data.propertytype.name}</td>
                            <td>{data.status_name}</td>

                            <td>
                                <button className="btn btn-primary btn-sm mx-1"
                                        onClick={() => handleViewProperty(data.id)}>
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                </button>
                                {userRole === 1 || userRole === 2 ? (<>
                                    <button className="btn btn-primary btn-sm mx-1"
                                            onClick={() => handlePropertyDocs(data.id, data.name)}>
                                        <i className="fa fa-file" aria-hidden="true"></i>
                                    </button>
                                    <button className="btn btn-primary btn-sm mx-1"
                                            onClick={() => handleBlockLot(data.id,data.name, data.price)}>
                                        <i className="fa fa-th" aria-hidden="true"></i>
                                    </button>
                                    <button className="btn btn-warning btn-sm mx-1" onClick={() => {
                                        setUpdateId(data.id)
                                        const selectedPropertyType = [{
                                            labelKey: `${data.propertytype.name}`, id: data.propertytype.id
                                        }];
                                        setFormData({
                                            property_name: data.name,
                                            price: data.price,
                                            address: data.address,
                                            status: data.status,
                                        });
                                        setSelectedPropertyType(selectedPropertyType)
                                        setEditModal(true)
                                    }}><i className='fas fa-edit'></i></button>
                                </>) : null}
                            </td>

                        </tr>)))}
                        </tbody>
                    </table>
                </div>
                <ReactPaginate
                    pageCount={pageCount}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    containerClassName="pagination justify-content-center mt-3"
                    activeClassName="active"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    breakLinkClassName="page-link"
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    breakClassName="page-item"
                    disabledClassName="disabled"
                />
            </div>
        </div>

        <Modal
            size="lg"
            show={showAddModal}
            onHide={() => setAddModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Add New Property
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <button className="btn btn-primary btn-sm" onClick={() => {
                    setAddPropertyTypeModal(true)
                    setAddModal(false)
                }}>Add New Property Type
                </button>
                <hr></hr>
                <form onSubmit={confirmAddData}>
                    <label className="form-label">Property Type</label>
                    <Typeahead
                        id="id"
                        labelKey="labelKey"
                        options={propertyType}
                        onChange={setSelectedPropertyType}
                        placeholder="Select Property Type"
                        selected={selectedPropertyType}
                        inputProps={{required: true}}
                    />
                    <label className="form-label">Property Name</label>
                    <input className="form-control" type="text" name="property_name" id="property_name"
                           placeholder="Enter Property Name"
                           required/>
                    <label className="form-label">Price</label>
                    <NumericFormat
                        className="form-control"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        name="price" id="price"
                        required
                    />
                    <label className="form-label">Address</label>
                    <textarea className="form-control" name="address" id="address"
                              placeholder="Enter Address" required
                    />
                    <div className="align-content-end">
                        <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                        >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

        <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
            <Modal.Header>
                <Modal.Title>Confirm Property Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><strong>Property Type:</strong> {formData.property_type}</p>
                <p><strong>Property Name:</strong> {formData.property_name}</p>
                <p><strong>Address:</strong> {formData.address}</p>
                <p><strong>Price:</strong> {formData.price}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setAddConfirmModal(false);
                    setModifying(false);
                }}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    setAddConfirmModal(false);
                    handleAddData();
                }}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal
            size="lg"
            show={showAddPropertyTypeModal}
            onHide={() => {
                setAddPropertyTypeModal(false)
                setAddModal(true)
            }}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Add New Property Type
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={confirmAddDataPropertyType}>
                    <label className="form-label">Property Type Name</label>
                    <input className="form-control" type="text" name="property_type_name" id="property_type_name"
                           placeholder="Enter Property Type Name"
                           required/>
                    <label className="form-label">Property Type Description</label>
                    <textarea className="form-control" name="property_type_description"
                              id="property_type_description"
                              placeholder="Enter Property Type Description" required
                    />
                    <div className="align-content-end">
                        <button className="btn btn-primary float-end mt-3" disabled={isModifying3}
                        >{isModifying3 ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

        <Modal show={showAddConfrimPropertyTypeModal} onHide={() => setAddConfirmPropertyTypeModal(false)}
               backdrop='static'>
            <Modal.Header>
                <Modal.Title>Confirm Property Type Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <strong>Property Type Name:</strong> {formData3.property_type_name}
                </p>
                <p><strong>Property Type Description:</strong> {formData3.property_type_description}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setAddConfirmPropertyTypeModal(false);
                    setModifying3(false);
                }}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    setAddConfirmPropertyTypeModal(false);
                    handleAddDataPropertyType();
                }}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal
            size="lg"
            show={showEditModal}
            onHide={() => setEditModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Edit Property Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={confirmEditData}>
                    <label className="form-label">Property Type</label>
                    <Typeahead
                        id="id"
                        labelKey="labelKey"
                        options={propertyType}
                        onChange={setSelectedPropertyType}
                        placeholder="Select Property Type"
                        selected={selectedPropertyType}
                        required
                    />
                    <label className="form-label">Property Name</label>
                    <input className="form-control" type="text" name="property_name" id="property_name"
                           placeholder="Enter Property Name"
                           value={formData.property_name}
                           onChange={(e) => setFormData({...formData, property_name: e.target.value})}
                           required/>
                    <label className="form-label">Price</label>
                    <NumericFormat
                        className="form-control"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        name="price" id="price"
                        value={formData.price}
                        onChange={(e) => setFormData({...formData, price: e.target.value})}
                        required
                    />
                    <label className="form-label">Address</label>
                    <textarea className="form-control" name="address" id="address"
                              placeholder="Enter Address" required
                              value={formData.address}
                              onChange={(e) => setFormData({...formData, address: e.target.value})}
                    />
                    <label className="form-label">Status</label>
                    <select className="form-select" aria-label="Default select example" name="status"
                            id="status"
                            value={formData.status}
                            onChange={(e) => setFormData({...formData, status: e.target.value})}
                            required>
                        <option value="0">Sold</option>
                        <option value="1">Active</option>
                        <option value="2">Cancelled</option>
                    </select>
                    <div className="align-content-end">
                        <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                        >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Update"}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

        <Modal show={showEditConfrimModal} onHide={() => setEditConfirmModal(false)} backdrop='static'>
            <Modal.Header>
                <Modal.Title>Confirm Edited Property Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><strong>Property Type:</strong> {formData.property_type}</p>
                <p><strong>Property Name:</strong> {formData.property_name}</p>
                <p><strong>Address:</strong> {formData.address}</p>
                <p><strong>Price:</strong> {formData.price}</p>
                <p><strong>Status:</strong> {formData.status}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setEditConfirmModal(false);
                    setModifying(false);
                }}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    setEditConfirmModal(false);
                    handleEditData();
                }}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>

    </>)
}

export default PropertyListing