import {useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import Loading from "../../others/Loading";
import ReactToPrint from "react-to-print";

function PrintLoi() {
    const {id} = useParams();
    const token = localStorage.getItem("token");
    const printContentRef = useRef()
    const [data, setData] = useState([])
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/transaction-loi/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [id, token])

    const PrintContent = React.forwardRef((_, ref) => (<div ref={ref}>
        <div className='container' style={{fontFamily: 'Times New Roman'}}>
            <p style={{
                paddingLeft: '11pt',
                textIndent: '0pt',
                textAlign: 'left'
            }}>Date: <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </u>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br/></p>
            <p style={{paddingLeft: '9pt', textIndent: '0pt', textAlign: 'center', backgroundColor: '#F1F1F1'}}><span
                className="h5">                                  LETTER OF INTENT                  </span>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br/></p>
            <p style={{paddingLeft: '11pt', textIndent: '0pt', textAlign: 'left'}}>To: ROHE PROPERTY DEVELOPMENT
                CORPORATION;</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br/></p>
            <p style={{
                paddingLeft: '11pt',
                textIndent: '0pt',
                textAlign: 'left'
            }}>I, <u>{data.buyer.name}</u> are
                accomplishing your standard “Intent to Buy” form in our sincerest intention to avail and purchase a
                subdivision lot / house &amp; lot under the terms and conditions described hereunder:</p>
            <div className='row'>
                <div className='col-sm'>
                    <p className="s2" style={{paddingLeft: '11pt', textIndent: '0pt', textAlign: 'justify'}}>PROJECT
                        NAME
                        : <u>{data.propertyowner.name} </u></p>
                    <p className="s2" style={{paddingLeft: '11pt', textIndent: '0pt', textAlign: 'justify'}}>LOCATION
                        : <u>{data.propertyblocklot.location} </u></p>
                    <p className="s2" style={{paddingLeft: '11pt', textIndent: '0pt', textAlign: 'justify'}}>MODEL
                        : <u>{data.propertyblocklot.model} </u></p>
                    <p className="s2" style={{paddingLeft: '11pt', textIndent: '0pt', textAlign: 'justify'}}>TOTAL
                        CONTRACT
                        PRICE
                        : <u>₱ {data.propertyblocklot.price} </u></p>
                    <p className="s2" style={{paddingLeft: '11pt', textIndent: '0pt', textAlign: 'justify'}}>RESERVATION
                        FEE
                        : <u>₱ {data.propertyblocklot.reservation_fee} </u></p>
                    <p className="s2" style={{paddingLeft: '11pt', textIndent: '0pt', textAlign: 'justify'}}>DOWNPAYMENT
                        : <u>₱ {data.propertyblocklot.downpayment} </u></p>
                    <p className="s2" style={{paddingLeft: '11pt', textIndent: '0pt', textAlign: 'justify'}}>LOANABLE
                        AMOUNT
                        : <u>₱ {data.propertyblocklot.loanable_amount} </u></p>
                </div>
                <div className='col-sm'>
                    <p className="s2" style={{paddingLeft: '11pt', textIndent: '0pt', textAlign: 'justify'}}>LOCATION
                        : <u>{data.propertyblocklot.location} </u></p>
                    <p className="s2" style={{paddingLeft: '11pt', textIndent: '0pt', textAlign: 'justify'}}>TOTAL AREA
                        : <u>{data.propertyblocklot.total_area} </u></p>
                    <p className="s2" style={{paddingLeft: '11pt', textIndent: '0pt', textAlign: 'justify'}}>BLOCK NO.
                        : <u>{data.propertyblocklot.block_no} </u></p>
                    <p className="s2" style={{paddingLeft: '11pt', textIndent: '0pt', textAlign: 'justify'}}>LOT NO.
                        : <u>{data.propertyblocklot.lot_no} </u></p>
                    <p className="s2" style={{paddingLeft: '11pt', textIndent: '0pt', textAlign: 'justify'}}>MONTHLY
                        EQUITY
                        : <u>{data.propertyblocklot.monthly_equity} </u></p>
                    <p className="s2" style={{paddingLeft: '11pt', textIndent: '0pt', textAlign: 'justify'}}>EQUITY TERM
                        : <u>{data.propertyblocklot.equity_term} </u></p>
                </div>
            </div>

            <p style={{paddingTop: '4pt', paddingLeft: '11pt', textIndent: '0pt', textAlign: 'justify'}}>Kindly consider
                my intention a <b>subdivision lot / house &amp; lot </b>under the above terms and conditions.</p>
            <p style={{paddingLeft: '11pt', textIndent: '0pt', textAlign: 'justify'}}>I understand that you reserve the
                right to accept or deny reservations. In event of your acceptance, and upon my down payment and delivery
                of the postdated checks to cover the balance of the price, I agree to execute your standard <b>Contract
                    to Sell </b>which I have read and fully understood and agreed to.</p>
            <p style={{paddingLeft: '11pt', textIndent: '0pt', textAlign: 'justify'}}>I understand further that the
                reservation made will automatically be cancelled, and the reservation fee and all my other payments
                shall be forfeited in your favor, should I fail to pay the down payment and/or perform any of the above
                conditions within the period stated. This <b>Intent to Buy </b>serves as an agreement with ROHE PROPERTY
                DEVELOPMENT CORPORATION and/or its nominee Contractors- Developers.</p>
            <p style={{paddingLeft: '11pt', textIndent: '0pt', textAlign: 'justify'}}>Very truly yours,</p>
            <p style={{paddingLeft: '14pt', textIndent: '0pt', lineHeight: '1pt', textAlign: 'left'}}/>
            <p style={{paddingLeft: '11pt', textIndent: '0pt', lineHeight: '0pt',textAlign: 'left'}}>________________________</p>
            <p style={{paddingLeft: '11pt', textIndent: '0pt', lineHeight: '0pt', textAlign: 'left'}}>Signature Over
                Printed Name</p>
            <p style={{paddingLeft: '11pt', textIndent: '15pt', lineHeight: '0pt',textAlign: 'left',}}>Buyer / Representative</p>
            <br/>
            <table style={{borderCollapse: 'collapse', marginLeft: '5.425pt'}} cellSpacing="0">
                <tr style={{height: '59pt'}}>
                    <td style={{
                        width: '193pt',
                        borderTopStyle: 'solid',
                        borderTopWidth: '1pt',
                        borderLeftStyle: 'solid',
                        borderLeftWidth: '1pt',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1pt',
                        borderRightStyle: 'solid',
                        borderRightWidth: '1pt'
                    }}>
                        <p className="s6" style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '12pt',
                            textAlign: 'left'
                        }}>Endorsed By:</p>
                        <p style={{textIndent: '0pt', textAlign: 'left'}}><br/></p>
                        <p style={{paddingLeft: '5pt', textIndent: '0pt', lineHeight: '1pt', textAlign: 'left'}}/>
                        <p className="s6"
                           style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Date &amp; Signature Over
                            Printed Name</p>
                    </td>
                    <td style={{
                        width: '195pt',
                        borderTopStyle: 'solid',
                        borderTopWidth: '1pt',
                        borderLeftStyle: 'solid',
                        borderLeftWidth: '1pt',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1pt',
                        borderRightStyle: 'solid',
                        borderRightWidth: '1pt'
                    }}>
                        <p className="s6" style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '12pt',
                            textAlign: 'left'
                        }}>Evaluated By:</p>
                        <p style={{textIndent: '0pt', textAlign: 'left'}}><br/></p>
                        <p style={{paddingLeft: '5pt', textIndent: '0pt', lineHeight: '1pt', textAlign: 'left'}}/>
                        <p className="s6"
                           style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Date &amp; Signature Over
                            Printed Name</p>
                    </td>
                    <td style={{
                        width: '162pt',
                        borderTopStyle: 'solid',
                        borderTopWidth: '1pt',
                        borderLeftStyle: 'solid',
                        borderLeftWidth: '1pt',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1pt',
                        borderRightStyle: 'solid',
                        borderRightWidth: '1pt'
                    }}>
                        <p className="s6" style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '12pt',
                            textAlign: 'left'
                        }}>Approved:</p>
                        <p style={{textIndent: '0pt', textAlign: 'left'}}><br/></p>
                        <p style={{paddingLeft: '5pt', textIndent: '0pt', lineHeight: '1pt', textAlign: 'left'}}/>
                        <p className="s6"
                           style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Date &amp; Signature Over
                            Printed</p>
                    </td>
                </tr>
            </table>
        </div>
    </div>));

    if (isLoading) {
        return (<Loading/>);
    }

    return (
        <>
            <div className='m-4 text-center'>
                <ReactToPrint
                    trigger={() => <button className='btn btn-primary'>Print</button>}
                    content={() => printContentRef.current}
                />
            </div>


            <PrintContent ref={printContentRef}/>
        </>
    )
}

export default PrintLoi;