import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../../others/Loading";
import ReactPaginate from "react-paginate";
import {Button, Modal} from "react-bootstrap";
import {NumericFormat} from "react-number-format";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";

function PayrollDetails() {
    const token = localStorage.getItem("token");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true)

    const [paymentMethod, setPaymentMethod] = useState('CASH');

    const [data, setData] = useState([])

    const [agentDataArray, setAgentDataArray] = useState([])

    const navigate = useNavigate();

    const {id} = useParams();
    const {property_name} = useParams();

    const [showAddModal, setAddModal] = useState(false);
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);
    const [isModifying, setModifying] = useState(false);

    const pageCount = Math.ceil(total / pageSize);

    const [reloadData, setReloadData] = useState(false);
    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/payroll-details/${id}?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
                setReloadData(false);
            })
    }, [pageNumber, pageSize, searchTerm, token, reloadData, id])

    function confirmAddData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);
        const agentDataArray = [];

        // Iterate through the data and extract the required fields
        data.forEach((agent, index) => {
            const id = formData.get(`id_${index}`);
            const payroll_id = formData.get(`payroll_id_${index}`);
            const agent_name = formData.get(`agent_name_${index}`);
            const amount = formData.get(`amount_${index}`);
            const total_amount = formData.get(`total_amount_${index}`);
            const remarks = formData.get('remarks')
            const method_of_payment = formData.get('method_of_payment')
            const cheque_no = formData.get('cheque_no')
            const pay_to = formData.get('pay_to')
            agentDataArray.push({id, payroll_id, agent_name, amount, total_amount, remarks, method_of_payment, cheque_no, pay_to});
        });

        setAgentDataArray(agentDataArray);
        setAddConfirmModal(true);
    }

    function handleAddData() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/payroll_details`, agentDataArray, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    setReloadData(true);
                    const agentDataUrl = encodeURIComponent(JSON.stringify(response.data.vouchers));
                    window.open(`${window.location.origin}/portal/print-voucher/${agentDataUrl}`, "_blank");
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setModifying(false)
                setAddModal(false)
            })
    }

    function handlePayrollHistory(id, agent_last_name, agent_first_name, agent_suffix) {
        const agent_name = encodeURIComponent(`${agent_first_name}`);
        navigate(`/portal/payroll-history/${id}/${agent_name}`);
    }


    if (isLoading) {
        return (<Loading/>);
    }

    return (<>
        <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Commission Details
            of {property_name}</h3>
        <div className="card shadow border-primary mb-3 mx-4">
            <div className="card-header">
                <p className="text-primary m-0 fw-bold d-inline">Commission Details Information</p>
                <button className="btn btn-primary text-end float-end btn-sm" onClick={() => {
                    setAddModal(true)
                }}>Disburse Commission
                </button>
            </div>
            <div className="card-body">
                <div className="row g-3">
                    <div className='col-md-11'>
                        <input type="text" className="form-control" placeholder="Search Realty Name!"
                               aria-label="Search"
                               aria-describedby="basic-addon2" value={searchTerm}
                               onChange={e => setSearchTerm(e.target.value)}/>
                    </div>
                    <div className='col-md'>
                        <select className="form-control" value={pageSize} onChange={e => {
                            setPageSize(Number(e.target.value));
                            setPageNumber(0); // Reset the page number when the page size changes
                        }}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                </div>

                <div className="table-responsive table mt-2" id="dataTable" role="grid"
                     aria-describedby="dataTable_info">
                    <table className="table my-0" id="dataTable">
                        <thead>
                        <tr>
                            <th>Realty's Name</th>
                            <th>Commission</th>
                            <th>Disbursed Commission</th>
                            <th>Remaining Commission</th>
                            <th>Last date of Disbursement</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody className='table-group-divider'>
                        {data.length === 0 ? (<tr>
                            <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                        </tr>) : (data.map((data) => (<tr key={data.id}>
                            <td>{data.user.first_name}</td>
                            <td>{data.commission}</td>
                            <td>{data.total_amount}</td>
                            <td>{data.total_disbursed}</td>
                            <td>{data.most_recent_history_created_date}</td>
                            <td>
                                <button className="btn btn-primary btn-sm mx-1"
                                        onClick={() => handlePayrollHistory(data.id, data.user.last_name, data.user.first_name, data.user.suffix)}>
                                    <i className="fa fa-history" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>)))}
                        </tbody>
                    </table>
                </div>
                <ReactPaginate
                    pageCount={pageCount}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    containerClassName="pagination justify-content-center mt-3"
                    activeClassName="active"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    breakLinkClassName="page-link"
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    breakClassName="page-item"
                    disabledClassName="disabled"
                />
            </div>
        </div>

        <Modal
            size="lg"
            show={showAddModal}
            onHide={() => setAddModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Disburse Commission
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={confirmAddData}>
                    <label className="form-label">Method of Payment</label>
                    <select className="form-control"
                            value={paymentMethod}
                            onChange={handlePaymentMethodChange}
                            id='method_of_payment'
                            name='method_of_payment'
                    >
                        <option value="CASH">CASH</option>
                        <option value="CHEQUE">CHEQUE</option>
                    </select>
                    {paymentMethod === 'CHEQUE' && (
                        <div>
                            <label className="form-label">Cheque Number</label>
                            <input
                                className="form-control"
                                type='text'
                                name='cheque_no'
                                id='cheque_no'
                                required
                            />
                        </div>
                    )}
                    <label className="form-label">Pay to</label>
                    <input
                        className="form-control"
                        type='text'
                        name='pay_to'
                        id='pay_to'
                        required
                    />
                    <label className="form-label">Remarks (Do not use the '%' symbol)</label>
                    <textarea className="form-control" name='remarks'
                              id='remarks'
                              required/>
                    {data.map((data, index) => (<>
                        <label className="form-label">Realty #{index + 1}</label>
                        <input className="form-control" type="text" name={`id_${index}`}
                               id={`id_${index}`}
                               required readOnly hidden value={`${data.id}`}/>
                        <input className="form-control" type="text" name={`payroll_id_${index}`}
                               id={`payroll_id_${index}`}
                               required readOnly hidden value={`${data.payroll.id}`}/>
                        <input className="form-control" type="text" name={`agent_name_${index}`}
                               id={`agent_name_${index}`}
                               required readOnly
                               value={`${data.user.first_name}`}/>
                        <label className="form-label">Total Commission Amount</label>
                        <NumericFormat
                            className="form-control"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            name={`total_amount_${index}`}
                            id={`total_amount_${index}`}
                            required
                        />
                        <label className="form-label">Net Total Commission Amount</label>
                        <NumericFormat
                            className="form-control"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            name={`amount_${index}`}
                            id={`amount_${index}`}
                            required
                        />
                    </>))}
                    <div className="align-content-end">
                        <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                        >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Disburse"}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

        <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
            <Modal.Header>
                <Modal.Title>Confirm Disbursements</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {agentDataArray.map((agentData, index) => (<div key={index}>
                    <p><strong>Agent Name #{index + 1}:</strong> {agentData.agent_name}</p>
                    <p><strong>Total Commission Amount:</strong> {agentData.total_amount}</p>
                    <p><strong>Net Total Commission Amount:</strong> {agentData.amount}</p>
                    <p><strong>Remarks:</strong> {agentData.remarks}</p>
                    <p><strong>Method Of Payment:</strong> {agentData.method_of_payment}</p>
                     {paymentMethod === 'CHEQUE' && (
                    <p><strong>Cheque Number:</strong> {agentData.cheque_no}</p>
                         )}
                    <p><strong>Pay To:</strong> {agentData.pay_to}</p>

                    <hr/>
                </div>))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setAddConfirmModal(false);
                    setModifying(false);
                }}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    setAddConfirmModal(false);
                    handleAddData();
                }}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>

    </>)
}

export default PayrollDetails