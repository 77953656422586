import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../others/Loading";
import ReactPaginate from "react-paginate";
import {Button, Modal} from "react-bootstrap";
import {PatternFormat} from "react-number-format";
import {toast} from "react-toastify";

function ManageStaff() {
    const token = localStorage.getItem("token");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true)
    const [staffs, setStaff] = useState([])
    const [formData, setFormData] = useState({});
    const [showAddModal, setAddModal] = useState(false);
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);
    const [isModifying, setModifying] = useState(false);

    const [update_id, setUpdateId] = useState('')
    const [showEditModal, setEditModal] = useState(false);
    const [showEditConfrimModal, setEditConfirmModal] = useState(false);

    const [deleteDataId, setDeleteDataId] = useState(null);
    const [deleteDataName, setDeleteDataName] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const roleNames = {
        1: "SENIOR SECRETARY", 2: "FINANCE", 3: "DOCS",
    };

    const pageCount = Math.ceil(total / pageSize);
    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/staff?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setStaff(response.data.data)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pageNumber, pageSize, searchTerm, token])

    function confirmAddStaff(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            first_name: formData.get("first_name"),
            middle_name: formData.get("middle_name"),
            last_name: formData.get("last_name"),
            suffix: formData.get("suffix"),
            cnum: formData.get("cnum"),
            email: formData.get("email"),
            role: formData.get("role"),
        };
        setFormData(data);
        setAddConfirmModal(true);
    }

    function handleAddStaff() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/staff`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const newData = response.data.new_data;
                    setStaff(prevData => [newData, ...prevData]);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setModifying(false)
                setAddModal(false)
            })
    }

    function confirmEditData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            id: formData.get('id'),
            first_name: formData.get("first_name"),
            middle_name: formData.get("middle_name"),
            last_name: formData.get("last_name"),
            suffix: formData.get("suffix"),
            phone: formData.get("phone"),
        };
        setFormData(data);
        setEditConfirmModal(true);
    }

    function handleEditData() {
        axios.put(`${process.env.REACT_APP_API_URL}/update/staff/${update_id}`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const updatedData = response.data.updated_data;
                    const updatedIndex = staffs.findIndex(item => item.id === updatedData.id);
                    const updatedStaff = [...staffs];
                    updatedStaff[updatedIndex] = updatedData;
                    setStaff(updatedStaff);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setEditModal(false)
                setUpdateId('')
            })
    }

    function confirmDeleteData(id, first_name, middle_name, last_name, suffix) {
        setDeleteDataId(id);
        setDeleteDataName(`${last_name}, ${first_name} ${middle_name} ${suffix}`);
        setShowDeleteModal(true);
    }

    function handleDeleteData(id) {
        fetch(`${process.env.REACT_APP_API_URL}/delete/staff/${id}`, {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                const updatedStaff = staffs.filter(item => item.id !== id);
                setStaff(updatedStaff);
                toast.success('Staff removed successfully.');
            })
            .catch(error => {
                console.error(error);
                toast.error('An error occurred while deleting data.');
            });
    }

    if (isLoading) {
        return (<Loading/>);
    }

    return (<>
            <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Manage Staff</h3>
            <div className="card shadow border-primary mb-3 mx-4">
                <div className="card-header">
                    <p className="text-primary m-0 fw-bold d-inline">Staff Information</p>
                    <button className="btn btn-primary text-end float-end btn-sm" onClick={() => {
                        setAddModal(true)
                    }}>Add New Staff
                    </button>
                </div>
                <div className="card-body">
                    <div className="row g-3">
                        <div className='col-md-11'>
                            <input type="text" className="form-control"
                                   placeholder="Search Last Name or Email or Phone number!"
                                   aria-label="Search"
                                   aria-describedby="basic-addon2" value={searchTerm}
                                   onChange={e => setSearchTerm(e.target.value)}/>
                        </div>
                        <div className='col-md'>
                            <select className="form-control" value={pageSize} onChange={e => {
                                setPageSize(Number(e.target.value));
                                setPageNumber(0); // Reset the page number when the page size changes
                            }}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                    </div>

                    <div className="table-responsive table mt-2" id="dataTable" role="grid"
                         aria-describedby="dataTable_info">
                        <table className="table my-0" id="dataTable">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Contact Number</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody className='table-group-divider'>
                            {staffs.length === 0 ? (<tr>
                                    <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                                </tr>) : (staffs.map((staff) => (<tr key={staff.id}>
                                    <td>{staff.user.last_name}, {staff.user.first_name} {staff.user.middle_name} {staff.user.suffix}</td>
                                    <td>{staff.phone}</td>
                                    <td>{staff.user.email}</td>
                                    <td>{staff.role_name}</td>
                                    <td>
                                        <button className="btn btn-warning btn-sm me-2" onClick={() => {
                                            setUpdateId(staff.id)
                                            setFormData({
                                                first_name: staff.user.first_name,
                                                middle_name: staff.user.middle_name,
                                                last_name: staff.user.last_name,
                                                suffix: staff.user.suffix,
                                                phone: staff.phone,
                                            });
                                            setEditModal(true)
                                        }}><i className='fas fa-edit'></i></button>
                                        <button className="btn btn-danger btn-sm"
                                                onClick={() => confirmDeleteData(staff.id, staff.user.first_name, staff.user.middle_name, staff.user.last_name, staff.user.suffix)}>
                                            <i
                                                className='fas fa-trash-alt'></i></button>
                                    </td>
                                </tr>)))}
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        containerClassName="pagination justify-content-center mt-3"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        breakClassName="page-item"
                        disabledClassName="disabled"
                    />
                </div>
            </div>

            <Modal
                size="lg"
                show={showAddModal}
                onHide={() => setAddModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add New Staff
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddStaff}>
                        <label className="form-label">First Name</label>
                        <input className="form-control" type="text" name="first_name" id="first_name"
                               placeholder="Enter First Name"
                               required/>
                        <label className="form-label">Middle Name</label>
                        <input className="form-control" type="text" name="middle_name" id="middle_name"
                               placeholder="Enter Middle Name"
                               required/>
                        <label className="form-label">Last Name</label>
                        <input className="form-control" type="text" name="last_name" id="last_name"
                               placeholder="Enter Last Name"
                               required/>
                        <label className="form-label">Suffix (optional)</label>
                        <input className="form-control" type="text" name="suffix" id="suffix"
                               placeholder="Enter Suffix (eg. Sr / Jr)"
                        />
                        <label className="form-label">Contact Number</label>
                        <PatternFormat className="form-control" name="cnum" id="cnum"
                                       format="#### ### ####"
                                       allowEmptyFormatting mask="_"
                                       required/>
                        <label className="form-label">Email</label>
                        <input className="form-control" type="email" name="email" id="email"
                               required/>
                        <label className="form-label">Role</label>
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            name="role"
                            id="role"
                            required>
                            <option
                                defaultValue
                            >Select
                                Role
                            </option>
                            <option
                                value="1">Senior Secretary
                            </option>
                            <option
                                value="2">Finance
                            </option>
                            <option
                                value="3">Docs
                            </option>
                        </select>
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Staff Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Name:</strong> {formData.last_name}, {formData.first_name} {formData.middle_name} {formData.suffix}
                    </p>
                    <p><strong>Contact Number:</strong> {formData.cnum}</p>
                    <p><strong>Email:</strong> {formData.email}</p>
                    <p><strong>Role:</strong> {roleNames[formData.role]}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setAddConfirmModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setAddConfirmModal(false);
                        handleAddStaff();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="lg"
                show={showEditModal}
                onHide={() => setEditModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Edit Staff Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmEditData}>
                        <input className="form-control" type="number" name="id" id="id"
                               value={formData.id}
                               required readOnly hidden/>
                        <label className="form-label">First Name</label>
                        <input className="form-control" type="text" name="first_name" id="first_name"
                               value={formData.first_name}
                               onChange={(e) => setFormData({...formData, first_name: e.target.value})}
                               required/>
                        <label className="form-label">Middle Initial (optional)</label>
                        <input className="form-control" type="text" name="middle_name" id="middle_name"
                               value={formData.middle_name}
                               onChange={(e) => setFormData({...formData, middle_name: e.target.value})}
                        />
                        <label className="form-label">Last Name</label>
                        <input className="form-control" type="text" name="last_name" id="last_name"
                               value={formData.last_name}
                               onChange={(e) => setFormData({...formData, last_name: e.target.value})}
                               required/>
                        <label className="form-label">Suffix (optional)</label>
                        <input className="form-control" type="text" name="suffix" id="suffix"
                               value={formData.suffix}
                               onChange={(e) => setFormData({...formData, suffix: e.target.value})}
                        />
                        <label className="form-label">Phone Number</label>
                        <PatternFormat className="form-control" name="phone" id="phone"
                                       value={formData.phone}
                                       onChange={(e) => setFormData({...formData, phone: e.target.value})}
                                       format="#### ### ####"
                                       allowEmptyFormatting mask="_"/>
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Update"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showEditConfrimModal} onHide={() => setEditConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Staff Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>First Name:</strong> {formData.first_name}</p>
                    <p><strong>Middle Name:</strong> {formData.middle_name}</p>
                    <p><strong>Last Name:</strong> {formData.last_name}</p>
                    <p><strong>Suffix:</strong> {formData.suffix}</p>
                    <p><strong>Phone Number:</strong> {formData.phone}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setEditConfirmModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setEditConfirmModal(false);
                        handleEditData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Delete Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete {deleteDataName}?</p>
                    <p>Removing {deleteDataName} will also remove all the data related to the user.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => {
                        handleDeleteData(deleteDataId);
                        setShowDeleteModal(false);
                    }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

        </>)
}

export default ManageStaff