import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../../others/Loading";
import ReactPaginate from "react-paginate";
import {Button, Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import {Typeahead} from "react-bootstrap-typeahead";

function PropertyAmenities() {
    const token = localStorage.getItem("token");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [formData, setFormData] = useState({});
    const [showAddModal, setAddModal] = useState(false);
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);
    const [isModifying, setModifying] = useState(false);

    const {id} = useParams();
    const {property_name} = useParams();

    const [selectedAmenityType, setSelectedAmenityType] = useState([]);
    const [amenityTypes, setAmenityTypes] = useState([]);

    const [deleteDataId, setDeleteDataId] = useState(null);
    const [deleteDataName, setDeleteDataName] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const pageCount = Math.ceil(total / pageSize);
    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/property-amenities/${id}?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pageNumber, pageSize, searchTerm, token, id])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/amenity-type/typeahead`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                const data = response.data;
                setAmenityTypes(data.map((data) => ({
                    labelKey: `${data.name}`, id: data.id
                })))
            })
            .catch(error => {
                console.log(error)
            })
    }, [token])

    function confirmAddData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            amenity_name: formData.get("amenity_name"),
            amenity_description: formData.get("amenity_description"),
            amenity_type: selectedAmenityType[0].id,
            amenity_type_name: selectedAmenityType[0].labelKey,
        };
        setFormData(data);
        setAddConfirmModal(true);
    }

    function handleAddData() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/amenity/${id}`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const newData = response.data.new_data;
                    setData(prevData => [newData, ...prevData]);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setModifying(false)
                setAddModal(false)
            })
    }

    function confirmDeleteData(id, name) {
        setDeleteDataId(id);
        setDeleteDataName(name);
        setShowDeleteModal(true);
    }

    function handleDeleteData(id) {
        fetch(`${process.env.REACT_APP_API_URL}/delete/amenity/${id}`, {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                const updatedData = data.filter(item => item.id !== id);
                setData(updatedData);
                toast.success('Amenity removed successfully.');
            })
            .catch(error => {
                console.error(error);
                toast.error('An error occurred while deleting data.');
            });
    }

    if (isLoading) {
        return (<Loading/>);
    }

    return (<>
        <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Manage Property Amenities
            of {property_name}</h3>
        <div className="card shadow border-primary mb-3 mx-4">
            <div className="card-header">
                <p className="text-primary m-0 fw-bold d-inline">Property Amenities Information</p>
                <button className="btn btn-primary text-end float-end btn-sm" onClick={() => {
                    setAddModal(true)
                }}>Add New Amenity
                </button>
            </div>
            <div className="card-body">
                <div className="row g-3">
                    <div className='col-md-11'>
                        <input type="text" className="form-control" placeholder="Search Amenity Name!"
                               aria-label="Search"
                               aria-describedby="basic-addon2" value={searchTerm}
                               onChange={e => setSearchTerm(e.target.value)}/>
                    </div>
                    <div className='col-md'>
                        <select className="form-control" value={pageSize} onChange={e => {
                            setPageSize(Number(e.target.value));
                            setPageNumber(0); // Reset the page number when the page size changes
                        }}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                </div>

                <div className="table-responsive table mt-2" id="dataTable" role="grid"
                     aria-describedby="dataTable_info">
                    <table className="table my-0" id="dataTable">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Type</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody className='table-group-divider'>
                        {data.length === 0 ? (<tr>
                            <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                        </tr>) : (data.map((data) => (<tr key={data.id}>
                            <td>{data.name}</td>
                            <td>{data.description}</td>
                            <td>{data.propertyamenitiestype.name}</td>
                            <td>
                                <button className="btn btn-danger btn-sm"
                                        onClick={() => confirmDeleteData(data.id, data.name)}>
                                    <i
                                        className='fas fa-trash-alt'></i></button>
                            </td>
                        </tr>)))}
                        </tbody>
                    </table>
                </div>
                <ReactPaginate
                    pageCount={pageCount}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    containerClassName="pagination justify-content-center mt-3"
                    activeClassName="active"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    breakLinkClassName="page-link"
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    breakClassName="page-item"
                    disabledClassName="disabled"
                />
            </div>
        </div>

        <Modal
            size="lg"
            show={showAddModal}
            onHide={() => setAddModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Add New Amenity
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={confirmAddData}>
                    <label className="form-label">Amenity Name</label>
                    <input className="form-control" type="text" name="amenity_name" id="amenity_name"
                           placeholder="Enter Amenity Name"
                           required/>
                    <label className="form-label">Description</label>
                    <textarea className="form-control" name="amenity_description" id="amenity_description"
                              placeholder="Enter Amenity Description" required
                    />
                    <label className="form-label">Amenity Type</label>
                    <Typeahead
                        id="id"
                        labelKey="labelKey"
                        options={amenityTypes}
                        onChange={setSelectedAmenityType}
                        placeholder="Select Amenity Type"
                        selected={selectedAmenityType}
                        required
                    />
                    <div className="align-content-end">
                        <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                        >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

        <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
            <Modal.Header>
                <Modal.Title>Confirm Amenity Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><strong>Amenity Name:</strong> {formData.amenity_name}</p>
                <p><strong>Description:</strong> {formData.amenity_description}</p>
                <p><strong>Type:</strong> {formData.amenity_type_name}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setAddConfirmModal(false);
                    setModifying(false);
                }}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    setAddConfirmModal(false);
                    handleAddData();
                }}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} backdrop='static'>
            <Modal.Header>
                <Modal.Title>Delete Amenity</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete {deleteDataName}?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => {
                    handleDeleteData(deleteDataId);
                    setShowDeleteModal(false);
                }}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>

    </>)
}

export default PropertyAmenities