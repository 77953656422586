import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import {Button, Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import Loading from "../others/Loading";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import Editor from 'ckeditor5-custom-build/build/ckeditor'

function Blogs() {
    const token = localStorage.getItem("token");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true)

    const [data, setData] = useState([])
    const [formData, setFormData] = useState({});

    const [showAddModal, setAddModal] = useState(false);
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);
    const [isModifying, setModifying] = useState(false);

    const [ckEditorContent, setCkEditorContent] = useState('');

    const navigate = useNavigate();

    const [fileUrl, setFileUrl] = useState('');


    const pageCount = Math.ceil(total / pageSize);

    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.role;
    }

    const userRole = getUserRole(token);

    function getUserId(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.id;
    }

    const userId = getUserId(token);

    const handleEditorChange = (event, editor) => {
        const newContent = editor.getData();
        setCkEditorContent(newContent);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/blogs?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                setData(response.data.data)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pageNumber, pageSize, searchTerm, token])

    function confirmAddData(event) {
        event.preventDefault()
        setModifying(true)

        const image = event.target.elements.image.files[0];
        const imageFileName = image.name;

        // Check if the file extension is valid (PDF or image)
        const fileExtension = imageFileName.toLowerCase().split('.').pop();
        if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
            setFormData({
                title: event.target.elements.title.value,
                content: ckEditorContent,
                image: image,
                image_name: imageFileName,
            });
            const fileUrl = URL.createObjectURL(image);
            setFileUrl(fileUrl);

            setAddConfirmModal(true);
        } else {
            // Display an error toast for invalid file type
            toast.error('Invalid file type. Only image files are allowed.');
            setModifying(false);
        }
    }

    function handleAddData() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/blog`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const newData = response.data.new_data;
                    setData(prevData => [newData, ...prevData]);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setModifying(false)
                setAddModal(false)
                setCkEditorContent('')
            })
    }

    function handleBlogView(id) {
        navigate(`/portal/blog-view/${id}`);
    }

    if (isLoading) {
        return (<Loading/>);
    }

    return (
        <>
            <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Blogs</h3>
            <div className="card shadow border-primary mb-3 mx-4">
                <div className="card-header">
                    <p className="text-primary m-0 fw-bold d-inline">Blogs Information</p>
                    {userRole === 1 || userRole === 2 ? (
                        <button className="btn btn-primary text-end float-end btn-sm" onClick={() => {
                            setAddModal(true)
                        }}>Add New Blog
                        </button>) : null}
                </div>
                <div className="card-body">
                    <div className="row g-3">
                        <div className='col-md-11'>
                            <input type="text" className="form-control" placeholder="Search Blog Title!"
                                   aria-label="Search"
                                   aria-describedby="basic-addon2" value={searchTerm}
                                   onChange={e => setSearchTerm(e.target.value)}/>
                        </div>
                        <div className='col-md'>
                            <select className="form-control" value={pageSize} onChange={e => {
                                setPageSize(Number(e.target.value));
                                setPageNumber(0); // Reset the page number when the page size changes
                            }}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                    </div>

                    <div className="table-responsive table mt-2" id="dataTable" role="grid"
                         aria-describedby="dataTable_info">
                        <table className="table my-0" id="dataTable">
                            <thead>
                            <tr>
                                <th>Featured Image</th>
                                <th>Title</th>
                                <th>Summary</th>
                                <th>Author</th>
                                <th>Status</th>
                                <th>Published Date</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody className='table-group-divider'>
                            {data.length === 0 ? (<tr>
                                <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                            </tr>) : (data.map((data) => (<tr key={data.id}>
                                <td><img src={`${process.env.REACT_APP_API_URL}/uploaded_img/${data.featured_image}`}
                                         className='rounded-1 img-fluid img-thumbnail'
                                         alt="Thumbnail" style={{
                                    width: '50px',
                                    height: '50px',
                                }}/></td>
                                <td>{data.title}</td>
                                <td>{data.summary}</td>
                                <td>{data.user.first_name} {data.user.last_name}</td>
                                <td>{data.status_name}</td>
                                <td>{data.created_date}</td>
                                <td>
                                    <button className="btn btn-primary btn-sm mx-1"
                                            onClick={() => handleBlogView(data.id)}>
                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>)))}
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        containerClassName="pagination justify-content-center mt-3"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        breakClassName="page-item"
                        disabledClassName="disabled"
                    />
                </div>
            </div>

            <Modal
                size="lg"
                show={showAddModal}
                onHide={() => setAddModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add New Blog
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddData} encType="multipart/form-data">
                        <label className="form-label">Title</label>
                        <input className="form-control" type="text" name="title" id="title"
                               placeholder="Enter Blog Title"
                               required/>
                        <label className="form-label">Content</label>
                        <CKEditor
                            editor={Editor}
                            data={ckEditorContent}// Use the content from the state
                            onReady={editor => {
                                // You can store the "editor" and use it when needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={handleEditorChange} // Use your custom function to handle content change
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                        />
                        <label className="form-label">Featured Image</label>
                        <input className="form-control" type="file" name="image" id="image" required/>
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Blog Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Title:</strong> {formData.title}</p>
                    <p><strong>Featured Image:</strong></p>
                    <img src={fileUrl} alt="featured_image" style={{maxWidth: '100%', height: 'auto'}}/>
                    <p><strong>Content:</strong></p>
                    <div className="col" style={{fontFamily: 'system-ui'}}
                         dangerouslySetInnerHTML={{__html: formData.content}}></div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setAddConfirmModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setAddConfirmModal(false);
                        handleAddData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Blogs;