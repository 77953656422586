import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../others/Loading";
import ReactPaginate from "react-paginate";
import {Button, Modal} from "react-bootstrap";
import {NumericFormat} from "react-number-format";
import {toast} from "react-toastify";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {useNavigate} from "react-router-dom";

function Transaction() {
    const token = localStorage.getItem("token");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [formData, setFormData] = useState({});
    const [showAddModal, setAddModal] = useState(false);
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);
    const [isModifying, setModifying] = useState(false);

    const [selectedProperty, setSelectedProperty] = useState([]);
    const [properties, setProperties] = useState([]);
    const [isAsyncLoading, setAsyncLoading] = useState(false);

    const [selectedBuyer, setSelectedBuyer] = useState([]);
    const [buyers, setBuyers] = useState([]);
    const [isAsyncLoading2, setAsyncLoading2] = useState(false);

    const [selectedAgent, setSelectedAgent] = useState([]);
    const [agents, setAgents] = useState([]);
    const [isAsyncLoading3, setAsyncLoading3] = useState(false);

    const [selectedProject, setSelectedProject] = useState([]);
    const [projects, setProjects] = useState([]);
    const [isAsyncLoading4, setAsyncLoading4] = useState(false);

    const [selectedBlockLot, setSelectedBlockLot] = useState([]);
    const [blocklots, setBlockLots] = useState([]);
    const [isAsyncLoading5, setAsyncLoading5] = useState(false);

    const [commissionType, setCommissionType] = useState('');

    const [opFeeState, setOpFeeState] = useState(0);
    const [totalComState, setTotalComState] = useState(0);
    const [undividedCommission, setUndividedCommission] = useState(0);
    const [totalUndividedCommission, setTotalUndividedCommission] = useState(0);
    const [opFeeMinus, setOpFeeMinus] = useState(0);

    const [showAddImageModal, setShowAddImageModal] = useState(false);
    const [previewImageUrl, setPreviewImageUrl] = useState(null);
    const [history_id, setHistoryId] = useState([])

    const [selectedImageFile, setSelectedImageFile] = useState(null);

    const [update_id, setUpdateId] = useState('')
    const [showEditModal, setEditModal] = useState(false);
    const [showEditConfrimModal, setEditConfirmModal] = useState(false);

    const statusNames = {
        0: "Cancelled", 1: "Transacted" , 2: "Completed"
    };


    const navigate = useNavigate();


    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.role;
    }

    const userRole = getUserRole(token);

    function getUserId(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.id;
    }

    const userId = getUserId(token);


    const handleOpFeeChange = (event) => {
        const newOpFee = parseFloat(event.target.value || 0);
        setOpFeeState(newOpFee);
    };

// Event handler for total commission input change
    const handleTotalComChange = (values) => {
        const newTotalCom = parseFloat(values.value || 0);
        setTotalComState(newTotalCom);
    };

    useEffect(() => {
        const initialUndivided = totalComState - ((opFeeState / 100) * totalComState);
        const OpFeeMinus = (opFeeState / 100) * totalComState
        setUndividedCommission(initialUndivided);
        setTotalUndividedCommission(initialUndivided)
        setOpFeeMinus(OpFeeMinus)
    }, [opFeeState, totalComState]);

    const handleCommissionChange = (event, index) => {
        const newAgentFields = [...agentFields];
        const commissionValue = event.target.value.replace(/,/g, ''); // Remove commas
        const commission = parseFloat(commissionValue || 0);

        // Get the updated sum of all agent commissions
        const updatedSumOfCommissions = newAgentFields.reduce((sum, agent, i) => {
            if (i === index) {
                return sum + commission; // Add the updated commission
            }
            return sum + agent.commission;
        }, 0);

        // Calculate the new undivided commission based on the updated sum
        const newUndividedCommission = totalComState * (1 - opFeeState / 100) - updatedSumOfCommissions;

        // Update the agent's commission in the array
        newAgentFields[index] = {...newAgentFields[index], commission};
        setAgentFields(newAgentFields);

        // Update the undivided commission
        setUndividedCommission(newUndividedCommission);
    };


    const [agentFields, setAgentFields] = useState([{
        agent_name: "", // Initialize with empty agent name
        agent_id: "", commission: "",
    },]);
    const [formData2, setFormData2] = useState({});
    const [showAddBuyerModal, setAddBuyerModal] = useState(false);
    const [showAddConfrimBuyerModal, setAddConfirmBuyerModal] = useState(false);
    const [isModifying2, setModifying2] = useState(false);

    const [useIncludeWithholdingTax, setIncludeWithholdingTax] = useState(false);

    const handleAddAgent = () => {
        setAgentFields([...agentFields, {agent_name: "", agent_id: "", commission: ""}]);
    };

    const [selectedAgentIds, setSelectedAgentIds] = useState([]);

    const handleAgentChange = (selected, index) => {
        const updatedAgentFields = [...agentFields];
        updatedAgentFields[index].agent_name = selected.length > 0 ? selected[0].labelKey : "";
        updatedAgentFields[index].agent_id = selected.length > 0 ? selected[0].id : "";
        setAgentFields(updatedAgentFields);

        // Update selectedAgentIds based on selected agents
        const updatedSelectedAgentIds = [...selectedAgentIds];
        if (selected.length > 0) {
            updatedSelectedAgentIds[index] = selected[0].id;
        } else {
            updatedSelectedAgentIds[index] = ""; // Reset the ID when agent is unselected
        }
        setSelectedAgentIds(updatedSelectedAgentIds);
    };


    const handleRemoveAgent = (index) => {
        const updatedFields = agentFields.filter((_, i) => i !== index);
        setAgentFields(updatedFields);
    };

    const handleCheckboxChange = (e) => {
        setIncludeWithholdingTax(e.target.checked);
    };


    const pageCount = Math.ceil(total / pageSize);
    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/transaction?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}&userRole=${userRole}&userId=${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pageNumber, pageSize, searchTerm, token, userId, userRole])

    const searchProperty = async (query) => {
        setAsyncLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/property/typeahead/${selectedProject[0].id}?search=${query}&limit=10`, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            const data = response.data;
            setProperties(data.map((data) => ({
                labelKey: `${data.name}`,
                id: data.id,
            })));
        } catch (error) {
            console.error('Error fetching Properties:', error);
        }
        setAsyncLoading(false);
    };

    const searchProjects = async (query) => {
        setAsyncLoading4(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/projects/typeahead?search=${query}&limit=10`, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            const data = response.data;
            setProjects(data.map((data) => ({
                labelKey: `${data.name}`,
                id: data.id,
            })));
        } catch (error) {
            console.error('Error fetching Projects:', error);
        }
        setAsyncLoading4(false);
    };

    const searchBlockLot = async (query) => {
        setAsyncLoading5(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/block-lot/typeahead/${selectedProperty[0].id}?search=${query}&limit=10`, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            const data = response.data;
            setBlockLots(data.map((data) => ({
                labelKey: `${data.name}`,
                id: data.id,
                price: data.price,
            })));
        } catch (error) {
            console.error('Error fetching Projects:', error);
        }
        setAsyncLoading5(false);
    };


    const searchBuyer = async (query) => {
        setAsyncLoading2(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/buyer/typeahead?search=${query}&limit=10`, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            const data = response.data;
            setBuyers(data.map((data) => ({
                labelKey: `${data.name}`, id: data.id
            })));
        } catch (error) {
            console.error('Error fetching Buyer:', error);
        }
        setAsyncLoading2(false);
    };

    const searchAgents = async (query) => {
        setAsyncLoading3(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/agents/typeahead?search=${query}&limit=10`, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            const data = response.data;
            setAgents(data.map((data) => ({
                labelKey: `${data.labelKey}`, id: data.id
            })));
        } catch (error) {
            console.error('Error fetching Agents:', error);
        }
        setAsyncLoading3(false);
    };

    function confirmAddData(event) {
        event.preventDefault();
        setModifying(true);

        const isUndividedZero = undividedCommission === 0;

        if (!isUndividedZero) {
            // Show an error message to the user indicating that the undivided commission should be 0
            // You can use a state variable to store and display this error message
            toast.error("Undivided commission should be 0 before proceeding.");
            setModifying(false)
            return
        }

        const formData = new FormData(event.target);
        const commissionType = formData.get("commission_type");
        if (commissionType === "manual") {
            const data = {
                project_name: selectedProject[0].labelKey,
                project_id: selectedProject[0].id,
                property_name: selectedProperty[0].labelKey,
                property_id: selectedProperty[0].id,
                block_lot_name: selectedBlockLot[0].labelKey,
                block_lot_id: selectedBlockLot[0].id,
                tcp: selectedBlockLot[0].price,
                property_owner_type: selectedProperty[0].owner_type_name,
                buyer_name: selectedBuyer[0].labelKey,
                buyer_id: selectedBuyer[0].id,
                commission_type: commissionType,
                transaction_date: formData.get("transaction_date"),
                total_commission: totalUndividedCommission,
                include_withholding_tax: useIncludeWithholdingTax,
                total_incentives: formData.get('total_incentives')
            };
            data.agentFields = agentFields.map((agent, index) => ({
                agent_name: agent.agent_name, agent_id: agent.agent_id, commission: agent.commission,
            }));
            setFormData(data);
            setAddConfirmModal(true);
        } else {
            if (selectedProperty.length > 0) {
                const data = {
                    project_name: selectedProject[0].labelKey,
                    project_id: selectedProject[0].id,
                    property_name: selectedProperty[0].labelKey,
                    property_id: selectedProperty[0].id,
                    block_lot_name: selectedBlockLot[0].labelKey,
                    block_lot_id: selectedBlockLot[0].id,
                    tcp: selectedBlockLot[0].price,
                    buyer_name: selectedBuyer[0].labelKey,
                    buyer_id: selectedBuyer[0].id,
                    commission_type: commissionType,
                    agent_name: selectedAgent[0].labelKey,
                    agent_id: selectedAgent[0].id,
                    transaction_date: formData.get("transaction_date"),
                    include_withholding_tax: useIncludeWithholdingTax,
                    total_incentives: formData.get('total_incentives')
                };
                setFormData(data);
                setAddConfirmModal(true);
            }
        }
    }

    function handleAddData() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/transaction`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const newData = response.data.new_data;
                    setData(prevData => [newData, ...prevData]);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setModifying(false)
                setAddModal(false)
            })
    }

    function confirmAddDataBuyer(event) {
        event.preventDefault()
        setModifying2(true)

        const formData = new FormData(event.target);

        const data = {
            name: formData.get("name"),
            age: formData.get("age"),
            birth_date: formData.get("birth_date"),
            spouse_name: formData.get("spouse_name"),
            spouse_age: formData.get("spouse_age"),
            spouse_birth_date: formData.get("spouse_birth_date"),
            residential_address: formData.get("residential_address"),
            provincial_address: formData.get("provincial_address"),
            contact_number: formData.get("contact_number"),
            telephone_number: formData.get("telephone_number"),
            email: formData.get("email"),
            facebook: formData.get("facebook"),
            civil_status: formData.get("civil_status"),
            residence_cert_number: formData.get("residence_cert_number"),
            nationality: formData.get("nationality"),
            organization: formData.get("organization"),
            valid_id: formData.get("valid_id"),
            valid_id_number: formData.get("valid_id_number"),
            occupation: formData.get("occupation"),
            type_of_business: formData.get("type_of_business"),
            company_name: formData.get("company_name"),
            business_name: formData.get("business_name"),
            company_address: formData.get("company_address"),
            business_address: formData.get("business_address"),
            monthly_income: formData.get("monthly_income"),
            tin_number: formData.get("tin_number"),
        };
        setFormData2(data);
        setAddConfirmBuyerModal(true);
    }

    function handleAddDataBuyer() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/buyer`, formData2, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setModifying2(false)
                setAddBuyerModal(false)
                setAddModal(true)
            })
    }

        function confirmEditData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
           status: formData.get("status"),
        };
        setFormData(data);
        setEditConfirmModal(true);
    }

    function handleEditData() {
        axios.put(`${process.env.REACT_APP_API_URL}/update/transaction/${update_id}`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const updatedData = response.data.updated_data;
                    const updatedIndex = data.findIndex(item => item.id === updatedData.id);
                    const updatedData2 = [...data];
                    updatedData2[updatedIndex] = updatedData;
                    setData(updatedData2);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setEditModal(false)
                setUpdateId('')
            })
    }


    const handleAddImage = async () => {
        if (!selectedImageFile) {
            return;
        }

        const formData = new FormData();
        formData.append("image", selectedImageFile);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/add/transaction-loi-signature/${history_id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data',
                }
            });

            setSelectedImageFile(null);
            setShowAddImageModal(false)
            toast.success('BUYER LOI with signature added successfully.');
        } catch (error) {
            console.error('Error adding image:', error);
            toast.error('An error occurred while adding the image.');
        }
    };

    function handleAddPayrollHistoryVoucher(id) {
        setHistoryId(id)
        setShowAddImageModal(true)
    }

    function handlePrintLoi(id) {
        navigate(`/portal/print-transaction-loi/${id}`);
    }

    function openVoucher(fileName) {
        // Construct the URL of the image based on its filename
        const imageUrl = `${process.env.REACT_APP_API_URL}/uploaded_img/${fileName}`;

        // Open the image in a new tab
        window.open(imageUrl, '_blank');
    }

    function handleTransactionHistory(id, project_name, property_name, blocklot_name) {
        const property_name_encoded = encodeURIComponent(`${project_name} - ${property_name} - ${blocklot_name} `);
        navigate(`/portal/transaction-history/${id}/${property_name_encoded}`);
    }

    if (isLoading) {
        return (<Loading/>);
    }

    return (<>
        <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Transactions</h3>
        <div className="card shadow border-primary mb-3 mx-4">
            <div className="card-header">
                <p className="text-primary m-0 fw-bold d-inline">Transaction Information</p>
                {userRole === 1 || userRole === 2 ? (
                    <button className="btn btn-primary text-end float-end btn-sm" onClick={() => {
                        setAddModal(true)
                    }}>Add New Transaction
                    </button>) : null}
            </div>
            <div className="card-body">
                <div className="row g-3">
                    <div className='col-md-11'>
                        <input type="text" className="form-control"
                               placeholder="Search Property Name or Buyers Name or Realty Name!"
                               aria-label="Search"
                               aria-describedby="basic-addon2" value={searchTerm}
                               onChange={e => setSearchTerm(e.target.value)}/>
                    </div>
                    <div className='col-md'>
                        <select className="form-control" value={pageSize} onChange={e => {
                            setPageSize(Number(e.target.value));
                            setPageNumber(0); // Reset the page number when the page size changes
                        }}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                </div>

                <div className="table-responsive table mt-2" id="dataTable" role="grid"
                     aria-describedby="dataTable_info">
                    <table className="table my-0" id="dataTable">
                        <thead>
                        <tr>
                            <th>Project Name</th>
                            <th>Property Name</th>
                            <th>Block and Lot</th>
                            {userRole === 1 || userRole === 2 ? (
                                <th>TCP</th>
                            ) : null}
                            <th>Buyer's Name</th>
                            <th>Realty Name</th>
                            <th>Status</th>
                            <th>Transaction Date</th>
                            {userRole === 1 || userRole === 2 ? (
                                <>
                                    <th>Paid</th>
                                    <th>Remaining Balance</th>
                                    <th>Last date of Payment</th>
                                </>
                            ) : null}
                            {userRole === 1 || userRole === 2 ? (<th>Action</th>) : null}
                        </tr>
                        </thead>
                        <tbody className='table-group-divider'>
                        {data.length === 0 ? (<tr>
                            <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                        </tr>) : (data.map((data) => (<tr key={data.id}>
                            <td>{data.propertyowner.name}</td>
                            <td>{data.property.name}</td>
                            <td>{data.propertyblocklot.name}</td>
                            {userRole === 1 || userRole === 2 ? (
                                <td>{data.tcp}</td>
                            ) : null}
                            <td>{data.buyer.name}</td>
                            <td>{data.user.first_name}</td>
                            <td>{data.status_name}</td>
                            <td>{data.transaction_date}</td>
                            {userRole === 1 || userRole === 2 ? (
                                <>
                                    <td>{data.total_payment}</td>
                                    <td>{data.remaining_payment}</td>
                                    <td>{data.most_recent_history_created_date}</td>
                                </>
                            ) : null}

                            <td>
                                {userRole === 1 || userRole === 2 ? (<td>
                                    <button className="btn btn-primary btn-sm mx-1"
                                            onClick={() => handleTransactionHistory(data.id, data.propertyowner.name, data.property.name, data.propertyblocklot.name)}>
                                        <i className="fa fa-history" aria-hidden="true"></i>
                                    </button>
                                    <button className="btn btn-danger btn-sm mx-1"
                                            onClick={() => handleAddPayrollHistoryVoucher(data.id)}>
                                        <i className="fa fa-camera" aria-hidden="true"></i>
                                    </button>
                                    {data.loi ? (<button
                                        className="btn btn-primary btn-sm mx-1"
                                        onClick={() => openVoucher(data.loi)}
                                    >
                                        <i className="fa fa-file" aria-hidden="true"></i>
                                    </button>) : (<button className="btn btn-primary btn-sm mx-1" disabled>
                                        <i className="fa fa-file" aria-hidden="true"></i>
                                    </button>)}
                                    <button className="btn btn-warning btn-sm mx-1"
                                            onClick={() => handlePrintLoi(data.id)}>
                                        <i className="fa fa-print" aria-hidden="true"></i>
                                    </button>
                                    <button className="btn btn-warning btn-sm mx-1" onClick={() => {
                                        setUpdateId(data.id)
                                        setFormData({
                                            status: data.status,
                                        });
                                        setEditModal(true)
                                    }}><i className='fas fa-edit'></i></button>
                                </td>) : null}
                            </td>
                        </tr>)))}
                        </tbody>
                    </table>
                </div>
                <ReactPaginate
                    pageCount={pageCount}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    containerClassName="pagination justify-content-center mt-3"
                    activeClassName="active"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    breakLinkClassName="page-link"
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    breakClassName="page-item"
                    disabledClassName="disabled"
                />
            </div>
        </div>

        <Modal
            size="lg"
            show={showAddModal}
            onHide={() => setAddModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Add New Transaction
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <button className="btn btn-primary btn-sm me-2" onClick={() => {
                    setAddBuyerModal(true)
                    setAddModal(false)
                }}>Add Buyer
                </button>
                {commissionType === 'manual' && (
                    <button className="btn btn-primary btn-sm " onClick={handleAddAgent}>Add Agent
                    </button>)}

                <hr></hr>
                <form onSubmit={confirmAddData}>
                    <label className="form-label">Project Name</label>
                    <AsyncTypeahead
                        id="id"
                        labelKey="labelKey"
                        isLoading={isAsyncLoading4}
                        onSearch={searchProjects}
                        options={projects}
                        onChange={setSelectedProject}
                        placeholder="Type Project Name"
                        selected={selectedProject}
                        inputProps={{required: true}}
                    />
                    <label className="form-label">Property Name</label>
                    <AsyncTypeahead
                        id="id"
                        labelKey="labelKey"
                        isLoading={isAsyncLoading}
                        onSearch={searchProperty}
                        options={properties}
                        onChange={setSelectedProperty}
                        placeholder="Type Property Name"
                        selected={selectedProperty}
                        inputProps={{required: true}}
                    />
                    <label className="form-label">Block And Lot</label>
                    <AsyncTypeahead
                        id="id"
                        labelKey="labelKey"
                        isLoading={isAsyncLoading5}
                        onSearch={searchBlockLot}
                        options={blocklots}
                        onChange={setSelectedBlockLot}
                        placeholder="Type Block and Lot"
                        selected={selectedBlockLot}
                        inputProps={{required: true}}
                    />
                    <label className="form-label">Total Lot Contract Price</label>
                    <NumericFormat
                        value={selectedBlockLot.length > 0 ? selectedBlockLot[0].price : ''}
                        className="form-control"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        name="tcp" id="tcp"
                        required
                        readOnly
                    />
                    <label className="form-label">Buyer Name</label>
                    <AsyncTypeahead
                        id="id"
                        labelKey="labelKey"
                        isLoading={isAsyncLoading2}
                        onSearch={searchBuyer}
                        options={buyers}
                        onChange={setSelectedBuyer}
                        placeholder="Type Buyer Name"
                        selected={selectedBuyer}
                        inputProps={{required: true}}
                    />
                    <label className="form-label">Transaction Date</label>
                    <input className="form-control" type="date" name="transaction_date" id="transaction_date"
                           required/>
                    <div className="input-group mt-3 mb-3">
                        <div className="input-group-text">
                            <input
                                className="form-check-input mt-0"
                                type="checkbox"
                                value=""
                                onChange={handleCheckboxChange}
                                aria-label="Checkbox for following text input"
                            />
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            aria-label="Text input with checkbox"
                            value="Do you want to include Incentives?"
                            readOnly
                        />
                    </div>
                    {useIncludeWithholdingTax && (
                        <div>
                            <label className="form-label">Total Incentives</label>
                            <NumericFormat
                                className="form-control"
                                thousandSeparator={true}
                                allowNegative={false}
                                decimalScale={2}
                                name="total_incentives" id="total_incentives"
                                required
                            />
                        </div>
                    )}
                    <label className="form-label">Commission Type</label>
                    <select className="form-select" aria-label="Default select example" name="commission_type"
                            id="commission_type"
                            value={commissionType}
                            onChange={(e) => setCommissionType(e.target.value)}
                            required
                            disabled={selectedProperty.length === 0}
                    >
                        <option defaultValue>Select Commission Type</option>
                        <option value="manual">Manual</option>
                        <option value="upcad">UPCAD</option>
                        <option value="open market">Open Market</option>
                    </select>

                    {(commissionType === 'upcad' || commissionType === 'open market') && selectedProperty.length > 0 && (<>
                        <label className="form-label">Realty Name</label>
                        <AsyncTypeahead
                            id="id"
                            labelKey="labelKey"
                            isLoading={isAsyncLoading3}
                            onSearch={searchAgents}
                            options={agents}
                            onChange={setSelectedAgent}
                            placeholder="Type Realty Name"
                            selected={selectedAgent}
                            inputProps={{required: true}}
                        />
                    </>)}

                    {commissionType === 'manual' && (<div>
                        <label className="form-label">Total Realty Commission (eg. 50,000)</label>
                        <NumericFormat
                            className="form-control"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            name={`total_commission`}
                            id={`total_commission`}
                            required
                            onValueChange={handleTotalComChange}
                        />
                        <label className="form-label">Undivided Realty Commission (eg. 10,000)</label>
                        <NumericFormat
                            className="form-control"
                            thousandSeparator={true}
                            allowNegative={true}
                            decimalScale={2}
                            name={`undivided_commission`}
                            id={`undivided_commission`}
                            value={undividedCommission}
                            readOnly
                        />
                        {agentFields.map((agent, index) => (<div key={index}>
                            <button className="btn btn-danger btn-sm float-end"
                                    onClick={() => handleRemoveAgent(index)}>
                                <i className='fa fa-trash'></i>
                            </button>
                            <label className="form-label">Realty Name #{index + 1}</label>
                            <AsyncTypeahead
                                id={`id`}
                                labelKey={`labelKey`}
                                isLoading={isAsyncLoading3}
                                onSearch={searchAgents}
                                options={agents.filter(agentOption => !selectedAgentIds.includes(agentOption.id))}
                                onChange={(selected) => handleAgentChange(selected, index)}
                                placeholder="Type Realty Name"
                                selected={agent.agent_name ? [{
                                    labelKey: agent.agent_name, id: agent.agent_id
                                }] : []}
                                inputProps={{required: true}}
                            />
                            <label className="form-label">Commission (eg. 10,000)</label>
                            <NumericFormat
                                className="form-control"
                                thousandSeparator={true}
                                allowNegative={false}
                                decimalScale={2}
                                name={`commission_${index}`}
                                id={`commission_${index}`}
                                value={agent.commission}
                                onChange={(event) => handleCommissionChange(event, index)}
                                required
                            />
                        </div>))}
                    </div>)}
                    <div className="align-content-end">
                        <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                        >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

        <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
            <Modal.Header>
                <Modal.Title>Confirm Transaction Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Project Name: {formData.project_name}</p>
                <p>Property Name: {formData.property_name}</p>
                <p>Block and Lot Name: {formData.block_lot_name}</p>
                <p>Total Contract Price: {formData.tcp}</p>
                <p>Buyer Name: {formData.buyer_name}</p>
                <p>Commission Type: {formData.commission_type}</p>
                <p>Transaction Date: {formData.transaction_date}</p>
                {useIncludeWithholdingTax && (
                    <p>Total Incentives: {formData.total_incentives}</p>
                )}
                {formData.commission_type === 'manual' ? (<>
                    <p>Total Commission: {formData.total_commission}</p>
                    {formData.agentFields.map((agent, index) => (<div key={index}>
                        <p>Realty Name #{index + 1}: {agent.agent_name}</p>
                        <p>Agent Commission: {agent.commission}</p>
                        <hr></hr>
                    </div>))}
                </>) : (<>
                    <p>Realty Name: {formData.agent_name}</p>
                </>)}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setAddConfirmModal(false);
                    setModifying(false);
                }}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    setAddConfirmModal(false);
                    handleAddData();
                }}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal
            size="lg"
            show={showAddBuyerModal}
            onHide={() => {
                setAddBuyerModal(false)
                setAddModal(true)
            }}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Add New Buyer
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={confirmAddDataBuyer}>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Name</label>
                            <input className="form-control" type="text" name="name" id="name"
                                   placeholder="Enter Name"
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Age</label>
                            <input className="form-control" type="number" name="age" id="age"
                                   placeholder="Enter Age"
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Birth Date</label>
                            <input className="form-control" type="date" name="birth_date" id="birth_date"
                                   placeholder="Enter Birth Date"
                                   required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Spouse Name</label>
                            <input className="form-control" type="text" name="spouse_name" id="spouse_name"
                                   placeholder="Enter Spouse Name"
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Age</label>
                            <input className="form-control" type="number" name="spouse_age" id="spouse_age"
                                   placeholder="Enter Spouse Age"
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Birth Date</label>
                            <input className="form-control" type="date" name="spouse_birth_date"
                                   id="spouse_birth_date"
                                   placeholder="Enter Spouse Birth Date"
                                   required/>
                        </div>
                    </div>
                    <label className="form-label">Residential Address</label>
                    <textarea className="form-control" name="residential_address" id="residential_address"
                              placeholder="Enter Residential Address" required
                    />
                    <label className="form-label">Provincial Address</label>
                    <textarea className="form-control" name="provincial_address" id="provincial_address"
                              placeholder="Enter Provincial Address" required
                    />
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Contact No.</label>
                            <input className="form-control" type="text" name="contact_number" id="contact_number"
                                   placeholder="Enter Contact number"
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Telephone No.</label>
                            <input className="form-control" type="text" name="telephone_number"
                                   id="telephone_number"
                                   placeholder="Enter Telephone Number"
                                   required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Email Address</label>
                            <input className="form-control" type="text" name="email" id="email"
                                   placeholder="Enter Email Address"
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Facebook Acct.</label>
                            <input className="form-control" type="text" name="facebook"
                                   id="facebook"
                                   placeholder="Enter Facebook Account"
                                   required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Civil Status</label>
                            <input className="form-control" type="text" name="civil_status" id="civil_status"
                                   placeholder="Enter Civil Status"
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Residence Cert No.</label>
                            <input className="form-control" type="text" name="residence_cert_number"
                                   id="residence_cert_number"
                                   placeholder="Enter Residence Cert No."
                                   required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Nationality</label>
                            <input className="form-control" type="text" name="nationality" id="nationality"
                                   placeholder="Enter Nationality"
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Assn./Org. Membership</label>
                            <input className="form-control" type="text" name="organization"
                                   id="organization"
                                   placeholder="Enter Assn./Org. Membership"
                                   required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Valid ID</label>
                            <input className="form-control" type="text" name="valid_id" id="valid_id"
                                   placeholder="Enter Valid ID"
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Valid ID No.</label>
                            <input className="form-control" type="text" name="valid_id_number"
                                   id="valid_id_number"
                                   placeholder="Enter Valid ID Number"
                                   required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Occupation</label>
                            <input className="form-control" type="text" name="occupation" id="occupation"
                                   placeholder="Enter Occupation"
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Type of Business</label>
                            <input className="form-control" type="text" name="type_of_business"
                                   id="type_of_business"
                                   placeholder="Enter Type of Business"
                                   required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Company Name</label>
                            <input className="form-control" type="text" name="company_name" id="company_name"
                                   placeholder="Enter Company Name"
                                   required/>
                        </div>
                        <div className='col'>
                            <label className="form-label">Business Name</label>
                            <input className="form-control" type="text" name="business_name"
                                   id="business_name"
                                   placeholder="Enter Type of Business Name"
                                   required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Company Address</label>
                            <textarea className="form-control" name="company_address" id="company_address"
                                      placeholder="Enter Company Address" required
                            />
                        </div>
                        <div className='col'>
                            <label className="form-label">Business Address</label>
                            <textarea className="form-control" name="business_address" id="business_address"
                                      placeholder="Enter Business Address" required
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className="form-label">Monthly Income</label>
                            <NumericFormat
                                className="form-control"
                                thousandSeparator={true}
                                allowNegative={false}
                                decimalScale={2}
                                name="monthly_income" id="monthly_income"
                                required
                            />
                        </div>
                        <div className='col'>
                            <label className="form-label">T.I.N. No</label>
                            <input className="form-control" name="tin_number" id="tin_number"
                                   placeholder="Enter T.I.N. Number" required
                            />
                        </div>
                    </div>
                    <div className="align-content-end">
                        <button className="btn btn-primary float-end mt-3" disabled={isModifying2}
                        >{isModifying2 ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

        <Modal show={showAddConfrimBuyerModal} onHide={() => setAddConfirmBuyerModal(false)} backdrop='static'>
            <Modal.Header>
                <Modal.Title>Confirm Buyer Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col'>
                        <p><strong>Name:</strong> {formData2.name}</p>
                    </div>
                    <div className='col'>
                        <p><strong>Age:</strong> {formData2.age}</p>
                    </div>
                    <div className='col'>
                        <p><strong>Birth Date:</strong> {formData2.birth_date}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <p><strong>Spouse Name:</strong> {formData2.spouse_name}</p>
                    </div>
                    <div className='col'>
                        <p><strong>Spouse Age:</strong> {formData2.spouse_age}</p>
                    </div>
                    <div className='col'>
                        <p><strong>Spouse Birth Date:</strong> {formData2.spouse_birth_date}</p>
                    </div>
                </div>
                <p><strong>Residential Address:</strong> {formData2.residential_address}</p>
                <p><strong>Provincial Address:</strong> {formData2.provincial_address}</p>
                <div className='row'>
                    <div className='col'>
                        <p><strong>Contact No.:</strong> {formData2.contact_number}</p>
                    </div>
                    <div className='col'>
                        <p><strong>Telephone No.:</strong> {formData2.telephone_number}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <p><strong>Email Address:</strong> {formData2.email}</p>
                    </div>
                    <div className='col'>
                        <p><strong>Facebook Acct.:</strong> {formData2.facebook}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <p><strong>Civil Status:</strong> {formData2.civil_status}</p>
                    </div>
                    <div className='col'>
                        <p><strong>Residence Cert No.:</strong> {formData2.residence_cert_number}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <p><strong>Nationality:</strong> {formData2.nationality}</p>
                    </div>
                    <div className='col'>
                        <p><strong>Assn./Org. Membership:</strong> {formData2.organization}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <p><strong>Valid ID:</strong> {formData2.valid_id}</p>
                    </div>
                    <div className='col'>
                        <p><strong>Valid ID No.:</strong> {formData2.valid_id_number}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <p><strong>Occupation:</strong> {formData2.occupation}</p>
                    </div>
                    <div className='col'>
                        <p><strong>Type of Business:</strong> {formData2.type_of_business}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <p><strong>Company Name:</strong> {formData2.company_name}</p>
                    </div>
                    <div className='col'>
                        <p><strong>Business Name:</strong> {formData2.business_name}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <p><strong>Company Address:</strong> {formData2.company_address}</p>
                    </div>
                    <div className='col'>
                        <p><strong>Business Address:</strong> {formData2.business_address}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <p><strong>Monthly Income:</strong> {formData2.monthly_income}</p>
                    </div>
                    <div className='col'>
                        <p><strong>T.I.N. No.:</strong> {formData2.tin_number}</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setAddConfirmBuyerModal(false);
                    setModifying2(false);
                }}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    setAddConfirmBuyerModal(false);
                    handleAddDataBuyer();
                }}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showAddImageModal} onHide={() => {
            setShowAddImageModal(false);
            setSelectedImageFile(null);
            setPreviewImageUrl(null); // Clear the preview URL when closing the modal
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Add Transaction LOI with Signature</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input className="form-control" type="file" accept=".jpeg, .jpg, .png" required onChange={(e) => {
                    setSelectedImageFile(e.target.files[0]);
                    setPreviewImageUrl(URL.createObjectURL(e.target.files[0])); // Set the preview URL
                }}/>
                {selectedImageFile && (<div className='mt-3'>
                    <img src={previewImageUrl} style={{maxWidth: '100%'}}/>
                </div>)}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleAddImage} disabled={!selectedImageFile}>
                    Add Image
                </Button>
                <Button variant="secondary" onClick={() => {
                    setShowAddImageModal(false);
                    setSelectedImageFile(null);
                    setPreviewImageUrl(null);
                }}>
                    Cancel
                </Button>

            </Modal.Footer>
        </Modal>

                     <Modal
            size="lg"
            show={showEditModal}
            onHide={() => setEditModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Edit Transaction Status
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={confirmEditData}>
                    <select className="form-select" aria-label="Default select example" name="status"
                            id="status"
                            value={formData.status}
                            onChange={(e) => setFormData({...formData, status: e.target.value})}
                            required>
                        <option value="0">Cancelled</option>
                        <option value="1">Transacted</option>
                        <option value="2">Completed</option>
                    </select>
                    <div className="align-content-end">
                        <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                        >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Update"}
                        </button>
                    </div>
                </form>
            </Modal.Body>
             </Modal>

            <Modal show={showEditConfrimModal} onHide={() => setEditConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Transaction Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Transaction Status:</strong> {statusNames[formData.status]}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setEditConfirmModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setEditConfirmModal(false);
                        handleEditData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

    </>)
}

export default Transaction