import './App.css';
import {ToastContainer} from "react-toastify";
import AdminLogin from "./components/pages/AdminLogin";
import {Route, Routes} from "react-router-dom";
import Initializer from "./components/others/Initializer";
import axios from "axios";
import {useEffect, useState} from "react";
import RealtyNameContext from "./components/others/RealtyNameContext";
import Body from "./components/layouts/Body";
import Dashboard from "./components/pages/Dashboard";
import NotFound from "./components/others/NotFound";
import Profile from "./components/pages/singlePages/Profile";
import PrintVoucherHistory from "./components/pages/printPages/PrintVoucherHistory";
import PrintVoucher from "./components/pages/printPages/PrintVoucher";
import PayrollHistory from "./components/pages/singlePages/PayrollHistory";
import PayrollDetails from "./components/pages/singlePages/PayrollDetails";
import Payroll from "./components/pages/Payroll";
import Transaction from "./components/pages/Transaction";
import PropertyDocs from "./components/pages/singlePages/PropertyDocs";
import PropertyAmenities from "./components/pages/singlePages/PropertyAmenities";
import PropertyImages from "./components/pages/singlePages/PropertyImages";
import PropertyView from "./components/pages/singlePages/PropertyView";
import PropertyListing from "./components/pages/PropertyListing";
import ManageSalesPerson from "./components/pages/ManageSalesPerson";
import SalesmanagerTeam from "./components/pages/singlePages/SalesmanagerTeam";
import ManageSalesManager from "./components/pages/ManageSalesManager";
import ManageStaff from "./components/pages/ManageStaff";
import WebsiteLeads from "./components/pages/WebsiteLeads";
import ScheduleCalendar from "./components/pages/ScheduleCalendar";
import SiteTripping from "./components/pages/SiteTripping";
import DataExtraction from "./components/pages/DataExtraction";
import Projects from "./components/pages/Projects";
import BlockLot from "./components/pages/singlePages/BlockLot";
import Blogs from "./components/pages/Blogs";
import BlogView from "./components/pages/singlePages/BlogView";
import Designs from "./components/pages/Designs";
import DesignView from "./components/pages/singlePages/DesignView";
import DesignAmenities from "./components/pages/singlePages/DesignAmenities";
import DesignImages from "./components/pages/singlePages/DesignImages";
import Incentives from "./components/pages/Incentives";
import IncentivesDetails from "./components/pages/singlePages/IncentivesDetails";
import IncentivesHistory from "./components/pages/singlePages/IncentivesHistory";
import TransactionHistory from "./components/pages/singlePages/TransactionHistory";
import PrintIncentivesVoucherHistory from "./components/pages/printPages/PrintIncentivesVoucherHistory";
import PrintVoucherIncentives from "./components/pages/printPages/PrintVoucherIncentives";
import PrintLoi from "./components/pages/printPages/PrintLoi";
import ExpenseLedger from "./components/pages/ExpenseLedger";
import Buyers from "./components/pages/Buyers";
import PrintBuyer from "./components/pages/printPages/PrintBuyer";


function App() {
    const [realtyName, setRealtyName] = useState('')

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/realty`)
            .then((response) => {
                setRealtyName(response.data.name);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const updateRealtyName = (newName) => {
        setRealtyName(newName);
    };
    return (<>
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
        <RealtyNameContext.Provider value={{realtyName, updateRealtyName}}>
            <Routes>
                <Route path='/setup' element={<Initializer/>}/>
                <Route path='/' element={<AdminLogin/>}/>
                <Route path='/portal/dashboard'
                       element={<Body element={<Dashboard/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/data-extraction-tool'
                       element={<Body element={<DataExtraction/>} allowedRoles={[1, 2]}/>}/>
                <Route path='/portal/expense-ledger'
                       element={<Body element={<ExpenseLedger/>} allowedRoles={[1, 2]}/>}/>
                <Route path='/portal/site-tripping-schedule'
                       element={<Body element={<SiteTripping/>} allowedRoles={[1, 2]}/>}/>
                <Route path='/portal/site-tripping-schedule/calendar'
                       element={<Body element={<ScheduleCalendar/>} allowedRoles={[1, 2]}/>}/>
                <Route path='/portal/submited-forms'
                       element={<Body element={<WebsiteLeads/>} allowedRoles={[1, 2]}/>}/>
                <Route path='/portal/manage-team/staff'
                       element={<Body element={<ManageStaff/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/manage-team/salesmanager'
                       element={<Body element={<ManageSalesManager/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/salesmanager-team/:id/:salesmanager_name'
                       element={<Body element={<SalesmanagerTeam/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/manage-team/salesperson'
                       element={<Body element={<ManageSalesPerson/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/designs'
                       element={<Body element={<Designs/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/design-view/:id'
                       element={<Body element={<DesignView/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/projects'
                       element={<Body element={<Projects/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/blogs'
                       element={<Body element={<Blogs/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/blog-view/:id'
                       element={<Body element={<BlogView/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/property-listings/:id/:project_name'
                       element={<Body element={<PropertyListing/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/property-block-lot/:id/:property_name/:price'
                       element={<Body element={<BlockLot/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/property-view/:id'
                       element={<Body element={<PropertyView/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/property-images/:id/:property_name'
                       element={<Body element={<PropertyImages/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/design-images/:id/:design_title'
                       element={<Body element={<DesignImages/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/property-amenities/:id/:property_name'
                       element={<Body element={<PropertyAmenities/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/design-amenities/:id/:design_title'
                       element={<Body element={<DesignAmenities/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/property-documents/:id/:property_name'
                       element={<Body element={<PropertyDocs/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/transactions'
                       element={<Body element={<Transaction/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/payroll'
                       element={<Body element={<Payroll/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/payroll-details/:id/:property_name'
                       element={<Body element={<PayrollDetails/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/payroll-history/:id/:agent_name'
                       element={<Body element={<PayrollHistory/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/print-voucher/:agentDataUrl'
                       element={<Body element={<PrintVoucher/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/print-voucher-history/:id'
                       element={<Body element={<PrintVoucherHistory/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/user-profile/:id'
                       element={<Body element={<Profile/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/incentives'
                       element={<Body element={<Incentives/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/incentives-details/:id/:property_name'
                       element={<Body element={<IncentivesDetails/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/incentives-history/:id/:agent_name'
                       element={<Body element={<IncentivesHistory/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/print-voucher-incentives/:agentDataUrl'
                       element={<Body element={<PrintVoucherIncentives/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/print-incentives-history/:id'
                       element={<Body element={<PrintIncentivesVoucherHistory/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/transaction-history/:id/:property_name'
                       element={<Body element={<TransactionHistory/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/print-transaction-loi/:id'
                       element={<Body element={<PrintLoi/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/buyers'
                       element={<Body element={<Buyers/>} allowedRoles={[1, 2]}/>}/>
                   <Route path='/portal/print-buyer/:id'
                       element={<Body element={<PrintBuyer/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </RealtyNameContext.Provider>
    </>);
}

export default App;
