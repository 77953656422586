import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../../others/Loading";
import {Button, Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import "../../../assets/css/propertyview.css";
import "../../../assets/css/propertyimages.css";
import Lightbox from "../../others/LightBox";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {GoogleMap, MarkerF, useLoadScript} from "@react-google-maps/api";
import PropertyViewData from "../../others/PropertyViewData";

function PropertyView() {
    const token = localStorage.getItem("token");
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    });
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [formData, setFormData] = useState({});
    const [isModifying, setModifying] = useState(false);

    const [showLightbox, setShowLightbox] = useState(false);
    const [lightboxImage, setLightboxImage] = useState("");

    const [update_id, setUpdateId] = useState('')
    const [showEditModal, setEditModal] = useState(false);
    const [showEditConfrimModal, setEditConfirmModal] = useState(false);

    const [ckEditorContent, setCkEditorContent] = useState('');

    const {id} = useParams();

    const navigate = useNavigate();

    const statusNames = {
        0: "Not Published", 1: "Publish",
    };

    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.role;
    }

    const userRole = getUserRole(token);

    function getUserId(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.id;
    }

    const userId = getUserId(token);

    const [markerPosition, setMarkerPosition] = useState({lat: 7.088947601671433, lng: 125.59915325237534});

    const handleMarkerDragEnd = (e) => {
        const newLat = e.latLng.lat();
        const newLng = e.latLng.lng();
        setMarkerPosition({lat: newLat, lng: newLng});
        setFormData({...formData, g_map_location: `${newLat}, ${newLng}`});
    };

    const handleEditorChange = (event, editor) => {
        const newContent = editor.getData();
        setCkEditorContent(newContent);
    };


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/property/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [id, token])

    function handlePropertyImage(id, property_name) {
        const encodedPropertyName = encodeURIComponent(property_name);
        navigate(`/portal/property-images/${id}/${encodedPropertyName}`);
    }

    function handlePropertyAmenities(id, property_name) {
        const encodedPropertyName = encodeURIComponent(property_name);
        navigate(`/portal/property-amenities/${id}/${encodedPropertyName}`);
    }


    const handleEnlargeGalleryImage = (imageSrc) => {
        setLightboxImage(`${process.env.REACT_APP_API_URL}/uploaded_img/${imageSrc}`);
        setShowLightbox(true);
    };

    const closeLightbox = () => {
        setShowLightbox(false);
    };

    function confirmEditData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            published_title: formData.get("published_title"),
            published_location: formData.get("published_location"),
            g_map_location: formData.get("g_map_location"),
            yt_video: formData.get("yt_video"),
            description: ckEditorContent,
            published_status: formData.get("published_status"),

        };
        setFormData(data);
        setEditConfirmModal(true);
    }

    function handleEditData() {
        axios.put(`${process.env.REACT_APP_API_URL}/update/property-view/${update_id}`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.status === 400) {
                    toast.error(response.data.message)
                } else {
                    const updatedData = response.data.updated_data;
                    setData(updatedData);
                    toast.success(response.data.message)
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setEditModal(false)
                setUpdateId('')
            })
    }

    if (isLoading) {
        return (<Loading/>);
    }

    return (<>
        <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Publish Property Settings
            of {data.name}</h3>
        {userRole === 1 || userRole === 2 ? (<div className="card shadow border-primary mb-5 mx-4">
            <div className="card-header">
                <p className="text-primary m-0 fw-bold d-inline">Property Information</p>
            </div>
            <div className="card-body">
                <div className="table-responsive table mt-2" id="dataTable" role="grid"
                     aria-describedby="dataTable_info">
                    <table className="table my-0" id="dataTable">
                        <thead>
                        <tr>
                            <th>Property Title</th>
                            <th>Location</th>
                            <th>Description</th>
                            <th>Youtube Link</th>
                            <th>Publish Status</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody className='table-group-divider'>
                        <tr>
                            <td> {data.published_title ? `${data.published_title}` : 'NOT SET'}</td>
                            <td>{data.published_location ? `${data.published_location}` : 'NOT SET'}</td>
                            <td>
                                {data.description ? data.description.length > 25 ? `${data.description.slice(0, 25)}...` : data.description : 'NOT SET'}
                            </td>
                            <td>{data.yt_video ? `${data.yt_video}` : 'NOT SET'}</td>
                            <td>{data.publish_status_name}</td>
                            <td>
                                <button className="btn btn-primary btn-sm mx-1"
                                        onClick={() => handlePropertyImage(data.id, data.name)}>
                                    <i className="fa fa-camera" aria-hidden="true"></i>
                                </button>
                                <button className="btn btn-primary btn-sm mx-1"
                                        onClick={() => handlePropertyAmenities(data.id, data.name)}>
                                    <i className="fa fa-home" aria-hidden="true"></i>
                                </button>
                                <button className="btn btn-warning btn-sm mx-1" onClick={() => {
                                    setUpdateId(data.id)
                                    setFormData({
                                        published_title: data.published_title,
                                        published_location: data.published_location,
                                        published_status: data.publish_status,
                                        yt_video: data.yt_video,
                                        g_map_location: data.g_map_location,
                                    });
                                    setCkEditorContent(`${data.description}`)
                                    setEditModal(true)
                                }}><i className='fas fa-edit'></i></button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>) : null}

        <PropertyViewData data={data}/>

        <Modal
            size="lg"
            show={showEditModal}
            onHide={() => setEditModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Edit Property Publishing Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={confirmEditData}>
                    <label className="form-label">Title</label>
                    <input className="form-control" type="text" name="published_title" id="published_title"
                           value={formData.published_title}
                           onChange={(e) => setFormData({...formData, published_title: e.target.value})}
                           required/>
                    <label className="form-label">Location</label>
                    <input className="form-control" type="text" name="published_location" id="published_location"
                           value={formData.published_location}
                           onChange={(e) => setFormData({...formData, published_location: e.target.value})}
                           required
                    /><label className="form-label">Google Map Latitude & Longitude</label>
                    <input className="form-control mb-2" type="text" name="g_map_location" id="g_map_location"
                           value={formData.g_map_location}
                           readOnly
                    />
                    {!isLoaded ? (<h1>Loading...</h1>) : (
                        <GoogleMap
                            center={markerPosition}
                            zoom={11}
                            mapContainerStyle={{width: '100%', height: '400px'}}
                        >
                            <MarkerF
                                position={markerPosition}
                                draggable={true}
                                onDragEnd={handleMarkerDragEnd}
                            />
                        </GoogleMap>
                    )}
                    <label className="form-label">Description</label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={ckEditorContent} // Use the content from the state
                        onReady={editor => {
                            // You can store the "editor" and use it when needed.
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={handleEditorChange} // Use your custom function to handle content change
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}
                    />
                    <label className="form-label">Youtube Link</label>
                    <input className="form-control" type="text" name="yt_video" id="yt_video"
                           value={formData.yt_video}
                           onChange={(e) => setFormData({...formData, yt_video: e.target.value})}
                    />
                    <label className="form-label">Publish Status</label>
                    <select className="form-select" aria-label="Default select example" name="published_status"
                            id="published_status"
                            value={formData.published_status}
                            onChange={(e) => setFormData({...formData, published_status: e.target.value})}
                            required>
                        <option value="0">Not Published</option>
                        <option value="1">Publish</option>
                    </select>
                    <div className="align-content-end">
                        <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                        >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Update"}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

        <Modal show={showEditConfrimModal} onHide={() => setEditConfirmModal(false)} backdrop='static'>
            <Modal.Header>
                <Modal.Title>Confirm Property Publishing Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><strong>Title:</strong> {formData.published_title}</p>
                <p><strong>Location:</strong> {formData.published_location}</p>
                <p><strong>Google Map Latitude & Longitude:</strong> {formData.g_map_location}</p>
                <p><strong>Youtube Link:</strong> {formData.yt_video}</p>
                <p><strong>Publish Status:</strong> {statusNames[formData.published_status]}</p>
                <p><strong>Description:</strong></p>
                <div className="col" style={{fontFamily: 'system-ui'}}
                     dangerouslySetInnerHTML={{__html: formData.description}}></div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setEditConfirmModal(false);
                    setModifying(false);
                }}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    setEditConfirmModal(false);
                    handleEditData();
                }}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>


        {
            showLightbox && (<Lightbox imageUrl={lightboxImage} onClose={closeLightbox}/>)
        }
    </>)
}

export default PropertyView